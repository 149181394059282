import { handleCommitResult } from '../../../../../../../../@Api/Entity/Commit/commitEntity';
import uuid from '../../../../../../../../@Util/Id/uuid';
import { loadModuleDirectly } from '../../../../../../../../@Util/DependencyInjection/index';
import { ApiControllerStore } from '../../../../../../../../@Api/Controller/ApiControllerStore';
import { Entity } from '../../../../../../../../@Api/Model/Implementation/Entity';

export function sendEmail(emailEntity: Entity)
{
    return loadModuleDirectly(ApiControllerStore)
        .entityBespokeEmailController
        .sendEmail(emailEntity.id)
        .then(
            result =>
                handleCommitResult(
                    emailEntity,
                    {
                        descriptor: {},
                        id: uuid(),
                        events: [],
                        files: new Map(),
                    },
                    {
                        isDeferred: false,
                        isForced: false,
                        isDebounced: false,
                        isBulkMode: false,
                        isDebugMode: false,
                        isAutoCommit: false,
                    },
                    result
                )
        );
}