import React from 'react';
import { Entity } from '../../../../../../../../../../../@Api/Model/Implementation/Entity';
import { observer } from 'mobx-react-lite';
import Task from './Activity/Task/Task';
import Email from './Activity/Email/Email';
import Offer from './Activity/Offer/Offer';
import Invoice from './Activity/Invoice/Invoice';
import Project from './Activity/Project/Project';
import SalesOpportunity from './Activity/SalesOpportunity/SalesOpportunity';
import useTypes from '../../../../../../../../Type/Api/useTypes';
import { default as EntityItem } from '../../../../../../../../Item/Item';
import Base from './Base/Base';
import Subscription from './Activity/Subscription/Subscription';
import Document from './Activity/Document/Document';
import Note from './Note/Note';
import Attachment from './Attachment/Attachment';
import { LabelPosition } from '../../../../../../../../../../../@Future/Component/Generic/Input/Input/Input';
import Appointment from './Activity/Appointment/Appointment';
import { CommitContext } from '../../../../../../../../../../../@Api/Entity/Commit/Context/CommitContext';
import { SpotlerCampaignResult } from './Activity/SpotlerCampaignResult/SpotlerCampaignResult';
import { ApsisCampaignResult } from './Activity/ApsisCampaignResult/ApsisCampaignResult';
import { ApsisCampaign } from './Activity/ApsisCampaign/ApsisCampaign';
import ActivitySigningContent from './Activity/Signing/ActivitySigningContent';
import { ApsisFormResult } from "./Activity/ApsisFormResult/ApsisFormResult";

export interface ContentProps
{
    entity: Entity;
    commitContext: CommitContext;
    compact: boolean;
    labelPosition: LabelPosition;
    inlineLabel: boolean;
    hasNotes: boolean;
    hasAttachments: boolean;
    showNotes: boolean;
    showAttachments: boolean;
    toggleNotes: () => void;
    toggleAttachments: () => void;
    closeable?: boolean;
    isLayoutInEditMode?: boolean;
    onChangeLayoutEditMode?: (isLayoutInEditMode: boolean) => void;
    optionsPrefix?: React.ReactNode;
}

export const Content: React.FC<ContentProps> = observer(
    (
        props
    ) =>
    {
        const types = useTypes();

        if (props.entity.entityType.isA(types.Activity.Type))
        {
            if (props.entity.entityType.isA(types.Activity.Task.Type))
            {
                return <Task
                    {...props}
                />;
            }
            else if (props.entity.entityType.isA(types.Activity.Appointment.Type))
            {
                return <Appointment
                    {...props}
                />;
            }
            else if (props.entity.entityType.isA(types.Activity.Email.Type))
            {
                return <Email
                    {...props}
                />;
            }
            else if (props.entity.entityType.isA(types.Activity.Offer.Type))
            {
                return <Offer
                    {...props}
                />;
            }
            else if (props.entity.entityType.isA(types.Activity.Invoice.Type))
            {
                return <Invoice
                    {...props}
                />;
            }
            else if (props.entity.entityType.isA(types.Activity.Project.Type))
            {
                return <Project
                    {...props}
                />;
            }
            else if (props.entity.entityType.isA(types.Activity.SalesOpportunity.Type))
            {
                return <SalesOpportunity
                    {...props}
                />;
            }
            else if (props.entity.entityType.isA(types.Activity.Subscription.Type))
            {
                return <Subscription
                    {...props}
                />;
            }
            else if (props.entity.entityType.isA(types.Activity.Document.Type))
            {
                return <Document
                    {...props}
                />;
            }
            else if (props.entity.entityType.isA(types.Activity.DigitalSigning.Type))
            {
                return <ActivitySigningContent
                    {...props}
                />;
            }
            else if (props.entity.entityType.isA(types.Activity.ApsisCampaign.Type))
            {
                return <ApsisCampaign
                    {...props}
                />
            }
            else if (props.entity.entityType.isA(types.Activity.ApsisCampaignResult.Type))
            {
                return <ApsisCampaignResult
                    {...props}
                />
            }
            else if (props.entity.entityType.isA(types.Activity.ApsisFormResult.Type))
            {
                return <ApsisFormResult
                    {...props}
                />
            }
            else if (props.entity.entityType.isA(types.Activity.SpotlerCampaignResult.Type))
            {
                return <SpotlerCampaignResult
                    {...props}
                />;
            }
            else
            {
                return <Base
                    {...props}
                />;
            }
        }
        else if (props.entity.entityType.isA(types.Note.Type))
        {
            return <Note
                {...props}
            />;
        }
        else if (props.entity.entityType.isA(types.Attachment.Type))
        {
            return <Attachment
                {...props}
            />;
        }
        else
        {
            return <EntityItem
                entity={props.entity}
            />;
        }
    }
);