import { useContext } from 'react';
import useAsyncResult, { AsyncResult } from '../../../../../@Util/Async/useAsyncResult';
import { ApplicationPack } from '../../../ModuleManager/Model/ApplicationPack';
import CurrentUserContext from '../../../User/CurrentUserContext';
import performAction from '../../../../../@Api/Entity/performAction';

export function useAvailableApplicationPacks(): AsyncResult<ApplicationPack[]>
{
    const currentUserStore = useContext(CurrentUserContext);

    return useAsyncResult<ApplicationPack[]>(
        () =>
            performAction(
                currentUserStore.contractEntity,
                {
                    code: 'LicenseContract.GetApplicationPacks'
                })
                .then(
                    result =>
                        result.result
                ),
        [
            currentUserStore.contractEntity
        ]);
}
