import Room from '../Model/Room';
import { createContext } from 'react';
import Connection from '../Model/Connection';

export interface Multiplayer
{
    room: Room;
    connection: Connection;
    webSocket: WebSocket;
}

export default createContext<Multiplayer | undefined>(undefined);
