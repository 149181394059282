import React, { useCallback, useContext } from 'react';
import { observer } from 'mobx-react';
import Switch from '../../../@Future/Component/Generic/Input/Switch/Switch';
import LocalizedText from '../Localization/LocalizedText/LocalizedText';
import useSetting from '../Setting/Api/useSetting';
import { SettingSource } from '../Setting/SettingStore';
import { Setting } from '../../../@Api/Settings/Setting';
import useIsMobile from '../../../@Util/Responsiveness/useIsMobile';
import sendAnalyticsLogging, { EventTypes } from '../../../@Util/Analytics/sendAnalyticsLogging';
import RouterContext from "../../../@Service/Router/RouterContext";

export interface VersionSwitcherProps
{

}

export const VersionSwitcher: React.FC<VersionSwitcherProps> = observer(
    (

    ) =>
    {
        const routerStore = useContext(RouterContext);

        const isMobile = useIsMobile();

        const [ switchEnabled, toggleSwitch ] = useSetting<boolean>(SettingSource.User, Setting.RedirectToV2);

        const sendLoggingToAmplitude =
            useCallback(
                (value: boolean) =>
                {
                    sendAnalyticsLogging(value ? EventTypes.NewUIEnabled : EventTypes.NewUIDisabled);
                },
                [

                ]
            )

        const onToggleSwitch =
            useCallback(
                (value: boolean) =>
                {
                    toggleSwitch(value);

                    sendLoggingToAmplitude(value);

                    if (value)
                    {
                        routerStore.redirectToV2();
                    }
                    else
                    {
                        routerStore.redirectToV1();
                    }
                },
                [
                    toggleSwitch,
                    sendLoggingToAmplitude,
                    routerStore
                ]
            );

        return <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    backgroundColor: 'rgba(255, 255, 255, 0.2)',
                    borderRadius: '5px',
                    padding: '5px 12px 5px 12px',
                    marginLeft: isMobile ? '0' : '16px',
                }}>
                    <Switch
                        checked={switchEnabled}
                        onToggle={onToggleSwitch}
                    />
                    <span>
                        <LocalizedText
                            code="NewTribe"
                            value="Nieuwe Tribe"
                        />
                    </span>
                </div>;
    }
)