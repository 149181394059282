import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import ViewGroup from '../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import CurrentUserContext from '../../User/CurrentUserContext';
import Item from './Item/Item';
import styles from './Content.module.scss';
import { runInAction } from 'mobx';
import AuthenticationManagerContext from '../../../../@Service/Authentication/AuthenticationManagerContext';
import { ModuleManagerProps } from '../ModuleManager';
import CancelButton from '../../../../@Future/Component/Generic/Button/Variant/CancelButton/CancelButton';
import { DataObject } from '../../DataObject/Model/DataObject';
import useCurrentLicensePeriod from '../../License/Api/useCurrentLicensePeriod';
import Card from '../../../../@Future/Component/Generic/Card/Card';
import CardHeader from '../../../../@Future/Component/Generic/Label/Variant/CardHeader/CardHeader';
import CardInset from '../../../../@Future/Component/Generic/Card/CardInset';
import PrimaryButton from '../../../../@Future/Component/Generic/Button/Variant/PrimaryButton/PrimaryButton';
import areSetsEqual from '../../../../@Util/IsEqual/areSetsEqual';
import performAction from '../../../../@Api/Entity/performAction';
import LocalizerContext from '../../../../@Service/Localization/LocalizerContext';
import LocalizedText from '../../Localization/LocalizedText/LocalizedText';
import localizeText from '../../../../@Api/Localization/localizeText';
import { Pack } from '../../../../@Api/Pack/Pack';
import { ApplicationPack } from '../Model/ApplicationPack';
import { useAvailableApplicationPacks } from '../../License/Contract/Api/useAvailableApplicationPacks';
import { getApplicationPackPrice } from '../Api/getApplicationPackPrice';
import { useContractCurrency } from '../../License/Contract/Api/useContractCurrency';

export interface ModuleManagerContentProps extends ModuleManagerProps
{

}

const Content: React.FC<ModuleManagerContentProps> =
    props =>
    {
        const currentUserStore = useContext(CurrentUserContext);
        const [ availableApplicationPacks, isLoadingApplicationPacks ] = useAvailableApplicationPacks();
        const [ contractCurrency ] = useContractCurrency();

        const applicationPacks =
            useMemo(
                () =>
                    !isLoadingApplicationPacks
                        ? availableApplicationPacks.filter(
                            pack =>
                                pack.code !== Pack.ESignature
                                && pack.code !== Pack.Yuki
                                && pack.code !== Pack.Apsis
                                && pack.code !== Pack.Spotler
                        )
                        : undefined,
                [
                    availableApplicationPacks,
                    isLoadingApplicationPacks
                ]
            );
        const [ isEnvironmentLoading ] = useState(false);
        const [ initialSelectedPacks, setInitialSelectedPacks ] = useState(() => new Set<ApplicationPack>());
        const [ currentSelectedPacks, setCurrentSelectedPacks ] = useState(() => new Set<ApplicationPack>());

        useEffect(
            () =>
            {
                if (applicationPacks)
                {
                    const selectedPacks =
                        (applicationPacks || [])
                            .filter(
                                pack =>
                                    pack.isInstalled);

                    setInitialSelectedPacks(new Set(selectedPacks));
                    setCurrentSelectedPacks(new Set(selectedPacks));
                }
            },
            [
                applicationPacks,
                setInitialSelectedPacks,
                setCurrentSelectedPacks
            ]);

        const initialSelectedPackIds =
            useComputed(
                () =>
                    new Set(
                        Array.from(initialSelectedPacks)
                            .map(
                                pack =>
                                    pack.id)),
                [
                    initialSelectedPacks
                ]);

        const currentSelectedPackIds =
            useComputed(
                () =>
                    new Set(
                        Array.from(currentSelectedPacks)
                            .map(
                                pack =>
                                    pack.id)),
                [
                    currentSelectedPacks
                ]);

        const onTogglePack =
            useCallback(
                (pack: ApplicationPack) =>
                    runInAction(
                        () =>
                        {
                            if (currentSelectedPackIds.has(pack.id))
                            {
                                setCurrentSelectedPacks(
                                    new Set(
                                        Array.from(currentSelectedPacks)
                                            .filter(
                                                p =>
                                                    p !== pack)));
                            }
                            else
                            {
                                setCurrentSelectedPacks(
                                    new Set([
                                        ...Array.from(currentSelectedPacks),
                                        pack
                                    ]));
                            }
                        }),
                [
                    currentSelectedPackIds,
                    currentSelectedPacks,
                    setCurrentSelectedPacks
                ]);

        const packs =
            useMemo(
                () => [
                    ...(applicationPacks || [])
                ],
                [
                    applicationPacks
                ]);

        const authenticationManager = useContext(AuthenticationManagerContext);

        const onFinish =
            useCallback(
                () =>
                {
                    const wasDirty = !areSetsEqual(initialSelectedPackIds, currentSelectedPackIds);

                    return performAction(
                        currentUserStore.contractEntity,
                        {
                            code: 'LicenseContract.UpdateApplicationPacks',
                            name: localizeText('ModuleManager.ChangeModules', 'Modules aanpassen'),
                            parameters: {
                                packIds: Array.from(currentSelectedPackIds)
                            }
                        })
                        .then(
                            () =>
                            {
                                if (wasDirty)
                                {
                                    return authenticationManager.switchToUser(currentUserStore.currentUser, false);
                                }
                            })
                        .finally(
                            () =>
                            {
                                if (props.onClose)
                                {
                                    props.onClose();
                                }
                            });
                },
                [
                    initialSelectedPackIds,
                    currentSelectedPackIds,
                    currentUserStore,
                    authenticationManager,
                    props.onClose
                ]);

        const newNonFreePacks =
            useComputed(
                () =>
                    Array.from(currentSelectedPacks)
                        .filter(
                            pack =>
                                !initialSelectedPackIds.has(pack.id)
                                && pack.price > 0),
                [
                    currentSelectedPacks,
                    initialSelectedPackIds
                ]);

        const [ , licensePeriodEndDate ] = useCurrentLicensePeriod();

        const expansionYearlyPrice =
            useComputed(
                () =>
                {
                    const totalPrice =
                        newNonFreePacks
                            .filter(
                                pack =>
                                    pack.price > 0)
                            .map(
                                pack =>
                                    getApplicationPackPrice(pack, 'Yearly'))
                            .reduce((a, b) => a + b, 0);

                    return DataObject.constructFromTypeIdAndValue('Currency', totalPrice).toString();
                },
                [
                    newNonFreePacks
                ]);

        const localizer = useContext(LocalizerContext);

        if (!isEnvironmentLoading
            && applicationPacks
            && currentUserStore.contractEntity)
        {
            return <div
                className={styles.root}
            >
                <div
                    className={styles.content}
                >
                    <Card
                        inset
                    >
                        <ViewGroup
                            orientation="vertical"
                            spacing={10}
                        >
                            <ViewGroupItem>
                                <CardHeader>
                                    <LocalizedText
                                        code="ModuleManager.Title"
                                        value="Welkom! Met welke extra modules wil je gaan werken?"
                                    />
                                </CardHeader>
                            </ViewGroupItem>
                            {
                                packs && contractCurrency &&
                                    <ViewGroupItem>
                                        <div
                                            className={styles.itemContainer}
                                        >
                                            <ViewGroup
                                                orientation="vertical"
                                                spacing={10}
                                            >
                                                {
                                                    packs.map(
                                                        pack =>
                                                            <ViewGroupItem
                                                                key={pack.id}
                                                            >
                                                                <Item
                                                                    pack={pack}
                                                                    checked={currentSelectedPackIds.has(pack.id)}
                                                                    onToggle={onTogglePack}
                                                                    showPrice={props.showPrices}
                                                                    contractCurrency={contractCurrency}
                                                                />
                                                            </ViewGroupItem>)
                                                }
                                            </ViewGroup>
                                        </div>
                                    </ViewGroupItem>
                            }
                            <ViewGroupItem>
                                <CardInset>
                                    <ViewGroup
                                        orientation="vertical"
                                        spacing={5}
                                    >
                                        {
                                            props.showPrices && newNonFreePacks.length > 0 &&
                                                <ViewGroupItem>
                                                    <LocalizedText
                                                        code="ModuleManager.BuyModuleLicenses"
                                                        value="Je hebt nog geen licentie voor de volgende module(s): ${modules}. Als je op modules aanpassen klikt ontvang je van ons een factuur van ${price}, voor het gebruik van de extra modules tot aan ${date}. Betaling dient te worden voldaan binnen 7 dagen."
                                                        modules={newNonFreePacks.map(pack => pack.name).join(', ')}
                                                        price={expansionYearlyPrice}
                                                        date={localizer.formatDate(licensePeriodEndDate)}
                                                    />
                                                </ViewGroupItem>
                                        }
                                        <ViewGroupItem>
                                            <ViewGroup
                                                orientation="horizontal"
                                                spacing={15}
                                                alignment="center"
                                            >
                                                <ViewGroupItem
                                                    ratio={1}
                                                />
                                                {
                                                    props.onClose &&
                                                        <ViewGroupItem>
                                                            <CancelButton
                                                                onClick={props.onClose}
                                                            />
                                                        </ViewGroupItem>
                                                }
                                                <ViewGroupItem>
                                                    <PrimaryButton
                                                        label={
                                                            props.showPrices
                                                                ?
                                                                    <LocalizedText
                                                                        code="ModuleManager.ChangeModules"
                                                                        value="Modules aanpassen"
                                                                    />
                                                                :
                                                                    <LocalizedText
                                                                        code="ModuleManager.GetStarted"
                                                                        value="Aan de slag!"
                                                                    />
                                                        }
                                                        onClick={onFinish}
                                                    />
                                                </ViewGroupItem>
                                            </ViewGroup>
                                        </ViewGroupItem>
                                    </ViewGroup>
                                </CardInset>
                            </ViewGroupItem>
                        </ViewGroup>
                    </Card>
                </div>
            </div>;
        }
        else
        {
            return null;
        }
    };

export default observer(Content);
