import React from 'react';
import { Entity } from '../../../@Api/Model/Implementation/Entity';
import getTypes from '../../Domain/Entity/Type/Api/getTypes';
import useAsyncResult from '../../../@Util/Async/useAsyncResult';
import getConnectorActivationByCode from '../../../@Api/Entity/Bespoke/Connector/getConnectorActivationByCode';
import performAction from '../../../@Api/Entity/performAction';
import { GoogleMap } from './GoogleMap';
import { observer } from 'mobx-react';

interface Coordinate
{
    lat: number;
    lng: number;
    label: string;
    link: string;
    entity: Entity;
}

export interface GoogleMapWithAddressesProps
{
    relation: Entity;
    height?: number;
}

const GoogleMapWithAddresses: React.FC<GoogleMapWithAddressesProps> =
    props =>
    {
        const types = getTypes();
        const [isConnectorActive, isConnectorActiveLoading] =
            useAsyncResult(
                () =>
                    getConnectorActivationByCode('GoogleMaps')
                        .then(
                            activation =>
                                activation
                                    ? activation.getObjectValueByField(types.ConnectorActivation.Field.IsActivated)
                                    : false
                        ),
                [
                    types
                ]
            );

        const [
            coordinates, isLoading
        ] = useAsyncResult(
            async () =>
            {
                if (isConnectorActiveLoading || !isConnectorActive)
                {
                    return [];
                }

                const locationFields =
                    props.relation.entityType.bespoke
                        .getSummaryFields(props.relation)
                        .filter(field => field.id.startsWith('location') && field.entity);

                const coordinates: Coordinate[] = [];

                for (const locationField of locationFields)
                {
                    if (
                        !locationField.entity.hasValueForField(types.Address.Field.Latitude) ||
                        !locationField.entity.hasValueForField(types.Address.Field.Longitude)
                    )
                    {
                        if (
                            locationField.entity.hasValueForField(types.Address.Field.Street) &&  // No need to call geocoding without an address
                            locationField.entity.hasValueForField(types.Address.Field.City)
                        )
                        {
                            await performAction(
                                locationField.entity,
                                {
                                    code: 'GeocodeAddress.AutoGeocode',
                                }
                            )
                            .then(
                                () =>
                                {
                                    const lat = locationField.entity.getObjectValueByField(types.Address.Field.Latitude);
                                    const lng = locationField.entity.getObjectValueByField(types.Address.Field.Longitude);

                                    if (lat !== undefined && lng !== undefined)
                                    {
                                        coordinates.push(
                                            {
                                                lat,
                                                lng,
                                                label: locationField.title,
                                                link: locationField.link,
                                                entity: locationField.entity
                                            }
                                        );
                                    }
                                }
                            );
                        }
                    }
                    else
                    {
                        coordinates.push({
                            lat: locationField.entity.getObjectValueByField(types.Address.Field.Latitude),
                            lng: locationField.entity.getObjectValueByField(types.Address.Field.Longitude),
                            label: locationField.title,
                            link: locationField.link,
                            entity: locationField.entity
                        });
                    }
                }

                return coordinates;
            },
            [
                types,
                isConnectorActiveLoading,
                isConnectorActive,
                props.relation
            ]
        );

        if (isLoading || !isConnectorActive)
        {
            return null;
        }
        else
        {
            return <GoogleMap
                height={250}
                locations={coordinates}
                zoomLevel={12}
            />;
        }
    };

export default observer(GoogleMapWithAddresses);
