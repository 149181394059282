import { DataObjectComparatorOverload } from '../../../Model/DataObjectComparatorOverload';
import { Comparator } from '../../../Model/Comparator';
import { DataObjectOverloadType } from '../../../Model/DataObjectOverloadType';
import { DataObjectType } from '../../../Model/DataObjectType';
import { DataObject } from '../../../Model/DataObject';
import { LocalizedTextType } from '../../LocalizedText/LocalizedTextType';
import eq from 'lodash/eq';

const textTypeIds = new Set([
    'Text',
    'EmailAddress',
    'TelephoneNumber',
    'Url',
    'RichText',
    'LocalizedText'
]);

export class DataObjectTextComparatorOverload extends DataObjectComparatorOverload
{
    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    constructor(dataObjectType: DataObjectType,
                comparator: Comparator)
    {
        super(
            dataObjectType,
            comparator,
            DataObjectOverloadType.Lhs,
            type =>
                type !== undefined
                    && textTypeIds.has(type.id()),
            (value, relatedValue, isThisLhs) =>
            {
                const strValue = DataObjectTextComparatorOverload.getStringValueOrEmpty(value);
                const lowercaseStrValue = strValue.toLowerCase();
                const strRelatedValue = DataObjectTextComparatorOverload.getStringValueOrEmpty(relatedValue);
                const lowercaseStrRelatedValue = strRelatedValue.toLowerCase();

                switch (comparator)
                {
                    case Comparator.Equals:
                        return DataObjectTextComparatorOverload.compareEquals(
                            value,
                            relatedValue,
                            strValue,
                            strRelatedValue);

                    case Comparator.NotEquals:
                        return !DataObjectTextComparatorOverload.compareEquals(
                            value,
                            relatedValue,
                            strValue,
                            strRelatedValue);

                    case Comparator.Contains:
                        if (isThisLhs)
                        {
                            return strValue.indexOf(strRelatedValue) >= 0;
                        }
                        else
                        {
                            return strRelatedValue.indexOf(strValue) >= 0;
                        }

                    case Comparator.NotContains:
                        if (isThisLhs)
                        {
                            return strValue.indexOf(strRelatedValue) < 0;
                        }
                        else
                        {
                            return strRelatedValue.indexOf(strValue) < 0;
                        }
                    case Comparator.LessThan:
                        if (isThisLhs)
                        {
                            return lowercaseStrValue < lowercaseStrRelatedValue;
                        }
                        else
                        {
                            return lowercaseStrValue > lowercaseStrRelatedValue;
                        }

                    case Comparator.LessThanOrEqual:
                        if (isThisLhs)
                        {
                            return lowercaseStrValue <= lowercaseStrRelatedValue;
                        }
                        else
                        {
                            return lowercaseStrValue >= lowercaseStrRelatedValue;
                        }


                    case Comparator.GreaterThan:
                        if (isThisLhs)
                        {
                            return lowercaseStrValue > lowercaseStrRelatedValue;
                        }
                        else
                        {
                            return lowercaseStrValue < lowercaseStrRelatedValue;
                        }

                    case Comparator.GreaterThanOrEqual:
                        if (isThisLhs)
                        {
                            return lowercaseStrValue >= lowercaseStrRelatedValue;
                        }
                        else
                        {
                            return lowercaseStrValue <= lowercaseStrRelatedValue;
                        }

                    default:
                        throw new Error('Unsupported comparator');
                }
            });
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------

    private static getStringValueOrEmpty(dataObject?: DataObject)
    {
        return DataObjectTextComparatorOverload.getStringValue(dataObject) || '';
    }

    private static getStringValue(dataObject?: DataObject)
    {
        if (dataObject)
        {
            if (dataObject.specification.type instanceof LocalizedTextType)
            {
                return dataObject.specification.type.getTranslation(dataObject.value);
            }
            else
            {
                return dataObject.value;
            }
        }

        return undefined;
    }

    private static compareEquals(value: DataObject | undefined,
                                 relatedValue: DataObject | undefined,
                                 strValue: string,
                                 strRelatedValue: string)
    {
        if (DataObjectTextComparatorOverload.isLocalizedValueComparison(value, relatedValue))
        {
            return DataObjectTextComparatorOverload.areLocalizedValuesEqual(value, relatedValue);
        }
        else
        {
            return strValue === strRelatedValue;
        }
    }

    private static isLocalizedValueComparison(value?: DataObject,
                                              relatedValue?: DataObject)
    {
        return value?.type instanceof LocalizedTextType
            || relatedValue?.type instanceof LocalizedTextType;
    }

    private static areLocalizedValuesEqual(value?: DataObject,
                                           relatedValue?: DataObject)
    {
        return eq(value?.value, relatedValue?.value);
    }
}
