import React, { useCallback, useEffect, useMemo } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import ExternalEmail from '../../Model/ExternalEmail';
import ViewGroup from '../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import Card from '../../../../../../../../../@Future/Component/Generic/Card/Card';
import useTypes from '../../../../../../Type/Api/useTypes';
import { Entity } from '../../../../../../../../../@Api/Model/Implementation/Entity';
import Input from '../../../../../../Input/Input';
import SaveButton from '../../../../../../../../../@Future/Component/Generic/Button/Variant/SaveButton/SaveButton';
import getDatastoreByCode from '../../../../../../../../../@Api/Entity/Bespoke/Datastore/getDatastoreByCode';
import useRelationshipByAddressee from '../../Api/useRelationshipByAddressee';
import { EntityPath } from '../../../../../../Path/@Model/EntityPath';
import { emailType } from '../LinkExternalEmail';
import { getCurrentItemAttachments, storeEntityIdInItem } from '../../../../../../../../../OfficeAddin';
import SectionLabel from '../../../../../../../../../@Future/Component/Generic/Label/Variant/SectionLabel/SectionLabel';
import useRelationshipAddresseeAugmenter from '../../Api/useRelationshipAddresseeAugmenter';
import useRelationshipSelectorProps from '../../Api/useRelationshipSelectorProps';
import { FileValue } from '../../../../../../../DataObject/Type/File/FileValue';
import { default as GenericInput } from '../../../../../../../../../@Future/Component/Generic/Input/Input/Input';
import InputGroup from '../../../../../../../../../@Future/Component/Generic/Input/InputGroup/InputGroup';
import useRelatedEntity from '../../../../../../../../../@Api/Entity/Hooks/useRelatedEntity';
import useList from '../../../../../../../../../@Util/Collection/useList';
import Checkbox from '../../../../../../../../../@Future/Component/Generic/Input/Checkbox/Checkbox';
import useDividerGlue from '../../../../../../../../../@Future/Component/Generic/ViewGroup/Api/useDividerGlue';
import makeStyles from '@material-ui/core/styles/makeStyles';
import LocalizedText from '../../../../../../../Localization/LocalizedText/LocalizedText';
import { useNewCommitContext } from '../../../../../../../../../@Api/Entity/Commit/Context/Api/useNewCommitContext';
import { constructEntityOfType } from '../../../../../../../../../@Api/Entity/Commit/Context/Api/Compatibility/constructEntityOfType';
import { setValueByFieldInEntity } from '../../../../../../../../../@Api/Entity/Commit/Context/Api/Compatibility/setValueByFieldInEntity';
import { updateRelationship } from '../../../../../../../../../@Api/Entity/Commit/Context/Api/Compatibility/updateRelationship';
import commitEntity from '../../../../../../../../../@Api/Entity/Commit/commitEntity';

export interface CreateEmailProps
{
    externalEmail: ExternalEmail;
    onMailLinked: () => void;
}

const useStyles = makeStyles({
    root: {
        textOverflow: 'ellipsis',
        overflow: 'hidden'
    },
    checkbox:{
        flex: 'none'
    }
});

const CreateEmail: React.FC<CreateEmailProps> =
    props =>
    {
        const types = useTypes();
        const classes = useStyles();
        const commitContext = useNewCommitContext();
        const isIncomingEmail =
            useComputed(
                () =>
                {
                    if (props.externalEmail.sender)
                    {
                        return !props.externalEmail.sender.isMe;
                    }
                    else
                    {
                        return true;
                    }
                },
                [
                    props.externalEmail
                ]);

        const [ addresseeRelationship, isSearchingRelationship ] =
            useRelationshipByAddressee(
                isIncomingEmail
                    ? props.externalEmail.recipients[0]
                    : props.externalEmail.sender
            );

        const email =
            useMemo(
                () =>
                {
                    function getRecipient(
                        addressee: Entity,
                        emailAddress: string
                    )
                    {
                        const recipient =
                            constructEntityOfType(
                                types.Recipient.Email.Type,
                                commitContext
                            );

                        if (addressee)
                        {
                            updateRelationship(
                                recipient,
                                false,
                                types.Recipient.RelationshipDefinition.Addressee,
                                addressee,
                                commitContext
                            );
                        }

                        setValueByFieldInEntity(
                            recipient,
                            types.Recipient.Email.Field.EmailAddress,
                            emailAddress,
                            commitContext
                        );

                        return recipient
                    }

                    if (!isSearchingRelationship)
                    {
                        const email =
                            constructEntityOfType(
                                types.Activity.Email.Type,
                                commitContext
                            );

                        setValueByFieldInEntity(
                            email,
                            types.Activity.Field.Subject,
                            props.externalEmail.subject,
                            commitContext
                        );

                        setValueByFieldInEntity(
                            email,
                            types.Activity.Email.Field.HTML,
                            props.externalEmail.body,
                            commitContext
                        );

                        setValueByFieldInEntity(
                            email,
                            types.Activity.Email.Field.SendDate,
                            props.externalEmail.date,
                            commitContext
                        );

                        getDatastoreByCode(
                            types.Datastore.Phase.ActivityEmail.Type,
                            types.Activity.Email.State.Received
                        )
                        .then(
                            sentPhase =>
                                updateRelationship(
                                    email,
                                    false,
                                    types.Activity.Email.RelationshipDefinition.Phase,
                                    sentPhase,
                                    commitContext
                                )
                        );

                        const recipient =
                            getRecipient(
                                addresseeRelationship,
                                isIncomingEmail
                                    ? props.externalEmail.recipients[0].email
                                    : props.externalEmail.sender.email
                            );

                        updateRelationship(
                            email,
                            false,
                            isIncomingEmail
                                ? types.Activity.Email.RelationshipDefinition.To
                                : types.Activity.Email.RelationshipDefinition.From,
                            recipient,
                            commitContext
                        );

                        const externalId =
                            constructEntityOfType(
                                types.ExternalId.Type,
                                commitContext
                            );

                        setValueByFieldInEntity(
                            externalId,
                            types.ExternalId.Field.Service,
                            props.externalEmail.service,
                            commitContext
                        );

                        setValueByFieldInEntity(
                            externalId,
                            types.ExternalId.Field.Type,
                            emailType,
                            commitContext
                        );

                        setValueByFieldInEntity(
                            externalId,
                            types.ExternalId.Field.Id,
                            props.externalEmail.id,
                            commitContext
                        );

                        updateRelationship(
                            externalId,
                            true,
                            types.Entity.RelationshipDefinition.ExternalIds,
                            email,
                            commitContext
                        );

                        return email;
                    }
                    else
                    {
                        return undefined;
                    }
                },
                [
                    types,
                    commitContext,
                    props.externalEmail,
                    isIncomingEmail,
                    addresseeRelationship,
                    isSearchingRelationship
                ]
            );

        const addresseeToLookupRelationship =
            useMemo(
                () =>
                    props.externalEmail.sender?.isMe
                        ?
                            props.externalEmail.recipients
                                .find(
                                    recipient =>
                                        !recipient.isMe)
                        :
                            props.externalEmail.sender,
                [
                    props.externalEmail
                ]
            );
        const [ relationship, isLoadingRelationship ] = useRelationshipByAddressee(addresseeToLookupRelationship);

        useEffect(
            () =>
            {
                if (!isLoadingRelationship && !isSearchingRelationship)
                {
                    updateRelationship(
                        email,
                        true,
                        types.Relationship.RelationshipDefinition.Activities,
                        relationship,
                        commitContext
                    );

                    const recipientRelationshipDefinition =
                        isIncomingEmail
                            ? types.Activity.Email.RelationshipDefinition.From
                            : types.Activity.Email.RelationshipDefinition.To;

                    if (!relationship)
                    {
                        updateRelationship(
                            email,
                            false,
                            recipientRelationshipDefinition,
                            undefined,
                            commitContext
                        );
                    }
                    else
                    {
                        let from =
                            email.getRelatedEntityByDefinition(
                                false,
                                recipientRelationshipDefinition,
                                commitContext
                            );

                        if (!from)
                        {
                            from =
                                constructEntityOfType(
                                    types.Recipient.Email.Type,
                                    commitContext
                                );
                            updateRelationship(
                                email,
                                false,
                                recipientRelationshipDefinition,
                                from,
                                commitContext
                            );
                        }

                        updateRelationship(
                            from,
                            false,
                            types.Recipient.RelationshipDefinition.Addressee,
                            relationship,
                            commitContext
                        );

                        setValueByFieldInEntity(
                            from,
                            types.Recipient.Email.Field.EmailAddress,
                            isIncomingEmail
                                ? props.externalEmail.sender?.email
                                : props.externalEmail.recipients[0].email,
                            commitContext
                        );
                    }

                    if (props.externalEmail.recipients.length > 1)
                    {
                        props.externalEmail.recipients
                            .forEach(
                                (recipient) =>
                                {
                                    if (recipient.type === 'CC')
                                    {
                                        const ccRecipient =
                                            constructEntityOfType(
                                                types.Recipient.Email.Type,
                                                commitContext
                                            );

                                        updateRelationship(
                                            email,
                                            false,
                                            types.Activity.Email.RelationshipDefinition.CC,
                                            ccRecipient,
                                            commitContext
                                        );

                                        setValueByFieldInEntity(
                                            ccRecipient,
                                            types.Recipient.Email.Field.EmailAddress,
                                            recipient.email,
                                            commitContext
                                        );
                                    }
                                }
                            );
                    }
                }
            },
            [
                commitContext,
                isLoadingRelationship,
                relationship,
                email,
                types,
                props.externalEmail,
                isIncomingEmail,
                isSearchingRelationship
            ]
        );
        const isContactVisible =
            useComputed(
                () =>
                    email &&
                    !email.entityType.bespoke.hideFieldPath(
                        email,
                        EntityPath.fromEntity(email)
                            .joinTo(
                                types.Relationship.Person.Contact.RelationshipDefinition.Activities,
                                true)
                            .field()
                    ),
                [
                    email,
                    types
                ]
            );
        const onConstruct =
            useRelationshipAddresseeAugmenter(
                addresseeToLookupRelationship,
                commitContext,
                email
            );
        const relationshipSelectorProps =
            useRelationshipSelectorProps(
                relationship,
                isLoadingRelationship,
                addresseeToLookupRelationship
            );
        const parentActivity =
            useRelatedEntity(
                email,
                types.Activity.RelationshipDefinition.LinkedActivities,
                true,
                commitContext
            );
        const isParentActivityAProject =
            useComputed(
                () =>
                    parentActivity?.entityType.isA(types.Activity.Project.Type),
                [
                    parentActivity,
                    types
                ]);
        const [ selectedAttachments, addAttachment, removeAttachment ] =
            useList(
                props.externalEmail.attachments.map(
                    attachment =>
                        attachment.name
                ) || []
            );
        const downloadAndStoreAttachments =
            useCallback(
                async () =>
                {
                    const externalAttachments =
                        (props.externalEmail.attachments || [])
                            .filter(
                                attachment =>
                                    attachment.blob !== undefined);

                    if (props.externalEmail.areAttachmentsFetchedExternally)
                    {
                        externalAttachments.push(
                            ...await getCurrentItemAttachments()
                        );
                    }

                    for (const externalAttachment of externalAttachments)
                    {
                        if (selectedAttachments.indexOf(externalAttachment.name) >= 0)
                        {
                            const attachment =
                                constructEntityOfType(
                                    types.Attachment.Type,
                                    commitContext
                                );
                            setValueByFieldInEntity(
                                attachment,
                                types.Attachment.Field.File,
                                FileValue.fromBlob(
                                    externalAttachment.blob,
                                    externalAttachment.name
                                ),
                                commitContext
                            );
                            updateRelationship(
                                attachment,
                                true,
                                types.Entity.RelationshipDefinition.Attachments,
                                email,
                                commitContext
                            );
                        }
                    }
                },
                [
                    selectedAttachments,
                    props.externalEmail,
                    types,
                    email,
                    commitContext,
                ]);
        const save =
            useCallback(
                () =>
                    email &&
                    downloadAndStoreAttachments()
                        .then(
                            () =>
                                commitEntity(
                                    email,
                                    {
                                        context: commitContext,
                                    },
                                )
                        )
                        .then(
                            () =>
                                storeEntityIdInItem(email.uuid))
                        .then(
                            () =>
                            {
                                if (props.onMailLinked)
                                {
                                    props.onMailLinked();
                                }
                            }
                        ),
                [
                    downloadAndStoreAttachments,
                    email,
                    commitContext,
                ]
            );
        const dividerGlue = useDividerGlue();

        return <ViewGroup
            orientation="vertical"
            spacing={10}
        >
            <ViewGroupItem>
                <SectionLabel>
                    <LocalizedText
                        code="ExternalEmailRegister"
                        value="E-mail vastleggen"
                    />
                </SectionLabel>
            </ViewGroupItem>
            <ViewGroupItem>
                <Card
                    inset
                >
                    {
                        email &&
                        <ViewGroup
                            orientation="vertical"
                            spacing={10}
                        >
                            <ViewGroupItem>
                                <InputGroup>
                                    <Input
                                        entity={email}
                                        field={types.Relationship.RelationshipDefinition.Activities}
                                        parent
                                        labelPosition="left"
                                        doAutocommit={false}
                                        onConstruct={onConstruct}
                                        relationshipSelectboxProps={relationshipSelectorProps}
                                        autoFocus={!isLoadingRelationship && relationship === undefined}
                                        commitContext={commitContext}
                                    />
                                    {
                                        isContactVisible &&
                                        <Input
                                            entity={email}
                                            field={types.Relationship.Person.Contact.RelationshipDefinition.Activities}
                                            parent
                                            labelPosition="left"
                                            doAutocommit={false}
                                            onConstruct={onConstruct}
                                            commitContext={commitContext}
                                        />
                                    }
                                    <Input
                                        entity={email}
                                        field={types.Activity.RelationshipDefinition.LinkedActivities}
                                        parent
                                        labelPosition="left"
                                        doAutocommit={false}
                                        commitContext={commitContext}
                                    />
                                    {
                                        parentActivity && isParentActivityAProject &&
                                        <Input
                                            entity={email}
                                            field={types.Milestone.RelationshipDefinition.Activities}
                                            parent
                                            labelPosition="left"
                                            doAutocommit={false}
                                            commitContext={commitContext}
                                        />
                                    }
                                    <Input
                                        entity={email}
                                        field={types.Activity.Field.Subject}
                                        labelPosition="left"
                                        doAutocommit={false}
                                        commitContext={commitContext}
                                    />
                                    {
                                        props.externalEmail.attachments?.length > 0 &&
                                        <GenericInput
                                            label={
                                                <LocalizedText
                                                    code="Attachments"
                                                    value="Bijlagen"
                                                />
                                            }
                                            labelPosition="left"
                                        >
                                            <ViewGroup
                                                orientation="vertical"
                                                spacing={4}
                                                glue={dividerGlue}
                                            >
                                                {
                                                    props.externalEmail.attachments.map(
                                                        (attachment, idx) =>
                                                            <ViewGroupItem
                                                                key={'attachment_' + idx}
                                                            >
                                                                <ViewGroup
                                                                    orientation="horizontal"
                                                                    spacing={8}
                                                                >
                                                                    <ViewGroupItem
                                                                        className={classes.checkbox}
                                                                    >
                                                                        <Checkbox
                                                                            checked={selectedAttachments.indexOf(attachment.name) >= 0}
                                                                            onToggle={
                                                                                () =>
                                                                                    selectedAttachments.indexOf(attachment.name) >= 0
                                                                                        ? removeAttachment(attachment.name)
                                                                                        : addAttachment(attachment.name)
                                                                            }
                                                                        />
                                                                    </ViewGroupItem>
                                                                    <ViewGroupItem
                                                                        className={classes.root}
                                                                    >
                                                                        {attachment.name}
                                                                    </ViewGroupItem>
                                                                </ViewGroup>
                                                            </ViewGroupItem>
                                                    )
                                                }
                                            </ViewGroup>
                                        </GenericInput>
                                    }
                                </InputGroup>
                            </ViewGroupItem>
                            <ViewGroupItem>
                                <SaveButton
                                    fullWidth
                                    onClick={save}
                                    label={
                                        <LocalizedText
                                            code="ExternalEmailRegister"
                                            value="E-mail vastleggen"
                                        />
                                    }
                                />
                            </ViewGroupItem>
                        </ViewGroup>
                    }
                </Card>
            </ViewGroupItem>
        </ViewGroup>
    };

export default observer(CreateEmail);
