import Computation from '../Function/Computation/Computation';
import AutomationDependencyContext from '../AutomationDependencyContext';
import ConditionalComputation from '../Function/Computation/ConditionalComputation';
import MathematicalComputation from '../Function/Computation/MathematicalComputation';
import ValueFromEntityComputation from '../Function/Computation/ValueFromEntityComputation';
import Parameter from '../Parameter/Parameter';
import getPredicateFromDescriptor from './getPredicateFromDescriptor';
import getValueFromDescriptor from './getValueFromDescriptor';
import TextComputation from '../Function/Computation/TextComputation';
import QueryComputation from '../Function/Computation/QueryComputation';
import DynamicComputation from '../Function/Computation/DynamicComputation';
import FormattedDateComputation from '../Function/Computation/FormattedDateComputation';
import LinkComputation from '../Function/Computation/LinkComputation';
import FileAttachmentComputation from '../Function/Computation/FileAttachmentComputation';
import StartOfDayComputation from '../Function/Computation/StartOfDayComputation';
import RegExComputation from '../Function/Computation/RegExComputation';
import DocumentComputation from '../Function/Computation/DocumentComputation';
import EntityFromDataSourceValueComputation from '../Function/Computation/EntityFromDataSourceValueComputation';
import HtmlFromLayoutComputation from '../Function/Computation/HtmlFromLayoutComputation';
import LocalizedComputation from '../Function/Computation/LocalizedComputation';
import PdfComputation from '../Function/Computation/PdfComputation';
import MergedPdfComputation from '../Function/Computation/MergedPdfComputation';
import TruncatedDateComputation from '../Function/Computation/TruncatedDateComputation';
import RangeCollectionComputation from '../Function/Computation/RangeCollectionComputation';
import DataSourceValueByIdComputation from '../Function/Computation/DataSourceValueByIdComputation';
import FilteredCollectionComputation from '../Function/Computation/FilteredCollectionComputation';
import FirstElementOfCollectionComputation from '../Function/Computation/FirstElementOfCollectionComputation';
import DataSourceListQueryComputation from '../Function/Computation/DataSourceListQueryComputation';
import ValueFromDataSourceValueComputation from '../Function/Computation/ValueFromDataSourceValueComputation';
import ValueFromMapComputation from '../Function/Computation/ValueFromMapComputation';
import MapByComputation from '../Function/Computation/MapByComputation';
import MapWithValueComputation from '../Function/Computation/MapWithValueComputation';
import SizeOfCollectionComputation from '../Function/Computation/SizeOfCollectionComputation';
import LengthOfTextComputation from '../Function/Computation/LengthOfTextComputation';
import NumberFromTextComputation from '../Function/Computation/NumberFromTextComputation';
import IsDutchBsnValidComputation from '../Function/Computation/IsDutchBsnValidComputation';
import CapitalizedTextComputation from '../Function/Computation/CapitalizedTextComputation';
import CurrentDateComputation from '../Function/Computation/CurrentDateComputation';
import CurrentDateTimeComputation from '../Function/Computation/CurrentDateTimeComputation';
import DateWithTimeComputation from '../Function/Computation/DateWithTimeComputation';
import PeriodBetweenDatesComputation from '../Function/Computation/PeriodBetweenDatesComputation';
import QueryFileExportComputation from '../Function/Computation/QueryFileExportComputation';
import MappedCollectionComputation from '../Function/Computation/MappedCollectionComputation';
import LowercaseTextComputation from '../Function/Computation/LowercaseTextComputation';
import RoundedNumberComputation from '../Function/Computation/RoundedNumberComputation';
import SingletonCollectionComputation from '../Function/Computation/SingletonCollectionComputation';
import AggregateOfCollectionComputation from '../Function/Computation/AggregateOfCollectionComputation';
import DataSourceAggregateQueryComputation from '../Function/Computation/DataSourceAggregateQueryComputation';
import JoinedCollectionComputation from '../Function/Computation/JoinedCollectionComputation';
import LocalizedTextComputation from '../Function/Computation/LocalizedTextComputation';
import EntityTypeFromEntityTypeEntityComputation from '../Function/Computation/EntityTypeFromEntityTypeEntityComputation';
import NameFromFileComputation from '../Function/Computation/NameFromFileComputation';
import UrlEncodedTextComputation from '../Function/Computation/UrlEncodedTextComputation';
import AiPromptComputation from '../Function/Computation/AiPromptComputation';
import AiAudioTranscriptionComputation from '../Function/Computation/AiAudioTranscriptionComputation';

export default async function getComputationFromDescriptor(
    descriptor: any,
    dependencyContext: AutomationDependencyContext
): Promise<Computation<any, any>>
{
    const computation =
        await innerGetComputationFromDescriptor(
            descriptor,
            dependencyContext
        );

    return substituteComputationIfNecessary(
        computation,
        dependencyContext
    );
}

function substituteComputationIfNecessary(
    computation: Computation<any, any>,
    dependencyContext: AutomationDependencyContext
): Computation<any, any>
{
    if (dependencyContext.substitution === undefined)
    {
        return computation;
    }
    else
    {
        return dependencyContext.substitution(computation);
    }
}

function innerGetComputationFromDescriptor(
    descriptor: any,
    dependencyContext: AutomationDependencyContext
): Promise<Computation<any, any>>
{
    switch (descriptor.class)
    {
        case 'Computation':
            switch (descriptor.type)
            {
                case 'Conditional':
                    return ConditionalComputation.fromDescriptor(
                        descriptor,
                        dependencyContext
                    );

                case 'Mathematical':
                    return MathematicalComputation.fromDescriptor(
                        descriptor,
                        dependencyContext
                    );

                case 'Localized':
                    return LocalizedComputation.fromDescriptor(
                        descriptor,
                        dependencyContext
                    );

                case 'LocalizedText':
                    return LocalizedTextComputation.fromDescriptor(
                        descriptor,
                        dependencyContext
                    );

                case 'ValueFromEntity':
                    return ValueFromEntityComputation.fromDescriptor(
                        descriptor,
                        dependencyContext
                    );

                case 'Parameter':
                    return Parameter.fromDescriptor(
                        descriptor,
                        dependencyContext
                    );

                case 'Text':
                    return TextComputation.fromDescriptor(
                        descriptor,
                        dependencyContext
                    );

                case 'Link':
                    return LinkComputation.fromDescriptor(
                        descriptor,
                        dependencyContext
                    );

                case 'RangeCollection':
                    return RangeCollectionComputation.fromDescriptor(
                        descriptor,
                        dependencyContext
                    );

                case 'SingletonCollection':
                    return SingletonCollectionComputation.fromDescriptor(
                        descriptor,
                        dependencyContext
                    );

                case 'LengthOfText':
                    return LengthOfTextComputation.fromDescriptor(
                        descriptor,
                        dependencyContext
                    );

                case 'NumberFromText':
                    return NumberFromTextComputation.fromDescriptor(
                        descriptor,
                        dependencyContext
                    );

                case 'CapitalizedText':
                    return CapitalizedTextComputation.fromDescriptor(
                        descriptor,
                        dependencyContext
                    );

                case 'LowercaseText':
                    return LowercaseTextComputation.fromDescriptor(
                        descriptor,
                        dependencyContext
                    );

                case 'RegEx':
                    return RegExComputation.fromDescriptor(
                        descriptor,
                        dependencyContext
                    );

                case 'RoundedNumber':
                    return RoundedNumberComputation.fromDescriptor(
                        descriptor,
                        dependencyContext
                    );

                case 'FilteredCollection':
                    return FilteredCollectionComputation.fromDescriptor(
                        descriptor,
                        dependencyContext
                    );

                case 'MappedCollection':
                    return MappedCollectionComputation.fromDescriptor(
                        descriptor,
                        dependencyContext
                    );

                case 'JoinedCollection':
                    return JoinedCollectionComputation.fromDescriptor(
                        descriptor,
                        dependencyContext
                    );

                case 'FirstElementOfCollection':
                    return FirstElementOfCollectionComputation.fromDescriptor(
                        descriptor,
                        dependencyContext
                    );

                case 'SizeOfCollection':
                    return SizeOfCollectionComputation.fromDescriptor(
                        descriptor,
                        dependencyContext
                    );

                case 'AggregateOfCollection':
                    return AggregateOfCollectionComputation.fromDescriptor(
                        descriptor,
                        dependencyContext
                    );

                case 'ValueFromMap':
                    return ValueFromMapComputation.fromDescriptor(
                        descriptor,
                        dependencyContext
                    );

                case 'MapWithValue':
                    return MapWithValueComputation.fromDescriptor(
                        descriptor,
                        dependencyContext
                    );

                case 'MapBy':
                    return MapByComputation.fromDescriptor(
                        descriptor,
                        dependencyContext
                    );

                case 'StartOfDay':
                    return StartOfDayComputation.fromDescriptor(
                        descriptor,
                        dependencyContext
                    );

                case 'FormattedDate':
                    return FormattedDateComputation.fromDescriptor(
                        descriptor,
                        dependencyContext
                    );

                case 'TruncatedDate':
                    return TruncatedDateComputation.fromDescriptor(
                        descriptor,
                        dependencyContext
                    );

                case 'DateWithTime':
                    return DateWithTimeComputation.fromDescriptor(
                        descriptor,
                        dependencyContext
                    );

                case 'CurrentDate':
                    return CurrentDateComputation.fromDescriptor(
                        descriptor,
                        dependencyContext
                    );

                case 'CurrentDateTime':
                    return CurrentDateTimeComputation.fromDescriptor(
                        descriptor,
                        dependencyContext
                    );

                case 'PeriodBetweenDates':
                    return PeriodBetweenDatesComputation.fromDescriptor(
                        descriptor,
                        dependencyContext
                    );

                case 'FileAttachment':
                    return FileAttachmentComputation.fromDescriptor(
                        descriptor,
                        dependencyContext
                    );

                case 'Query':
                    return QueryComputation.fromDescriptor(
                        descriptor,
                        dependencyContext
                    );

                case 'QueryFileExport':
                    return QueryFileExportComputation.fromDescriptor(
                        descriptor,
                        dependencyContext
                    );

                case 'HtmlFromLayout':
                    return HtmlFromLayoutComputation.fromDescriptor(
                        descriptor,
                        dependencyContext
                    );

                case 'Pdf':
                    return PdfComputation.fromDescriptor(
                        descriptor,
                        dependencyContext
                    );

                case 'MergedPdf':
                    return MergedPdfComputation.fromDescriptor(
                        descriptor,
                        dependencyContext
                    );

                case 'Dynamic':
                    return DynamicComputation.fromDescriptor(
                        descriptor,
                        dependencyContext
                    );

                case 'Document':
                    return DocumentComputation.fromDescriptor(
                        descriptor,
                        dependencyContext
                    );

                case 'DataSourceValueById':
                    return DataSourceValueByIdComputation.fromDescriptor(
                        descriptor,
                        dependencyContext
                    );

                case 'DataSourceListQuery':
                    return DataSourceListQueryComputation.fromDescriptor(
                        descriptor,
                        dependencyContext
                    );

                case 'DataSourceAggregateQuery':
                    return DataSourceAggregateQueryComputation.fromDescriptor(
                        descriptor,
                        dependencyContext
                    );

                case 'ValueFromDataSourceValue':
                    return ValueFromDataSourceValueComputation.fromDescriptor(
                        descriptor,
                        dependencyContext
                    );

                case 'EntityFromDataSourceValue':
                    return EntityFromDataSourceValueComputation.fromDescriptor(
                        descriptor,
                        dependencyContext
                    );

                case 'EntityTypeFromEntityTypeEntity':
                    return EntityTypeFromEntityTypeEntityComputation.fromDescriptor(
                        descriptor,
                        dependencyContext
                    );

                case 'NameFromFile':
                    return NameFromFileComputation.fromDescriptor(
                        descriptor,
                        dependencyContext
                    );

                case 'IsDutchBsnValid':
                    return IsDutchBsnValidComputation.fromDescriptor(
                        descriptor,
                        dependencyContext
                    );

                case 'UrlEncodedText':
                    return UrlEncodedTextComputation.fromDescriptor(
                        descriptor,
                        dependencyContext
                    );

                case 'AiPrompt':
                    return AiPromptComputation.fromDescriptor(
                        descriptor,
                        dependencyContext
                    );

                case 'AiAudioTranscription':
                    return AiAudioTranscriptionComputation.fromDescriptor(
                        descriptor,
                        dependencyContext
                    );
            }

            break;

        case 'Predicate':
            return getPredicateFromDescriptor(
                descriptor,
                dependencyContext
            );

        case 'Value':
            return getValueFromDescriptor(
                descriptor,
                dependencyContext
            );
    }
}
