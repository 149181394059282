import React, { useCallback, useMemo, useState } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import { FileUploaderStore } from '../../../../../Generic/FileUploader/FileUploaderStore';
import { FileUploader } from '../../../../../Generic/FileUploader/FileUploader';
import Item from '../../../Bespoke/Attachment/Dialog/Item/Item';
import { ContentProps } from '../Content';
import useTypes from '../../../Type/Api/useTypes';
import { FileValue } from '../../../../DataObject/Type/File/FileValue';
import ViewGroup from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import CancelButton from '../../../../../../@Future/Component/Generic/Button/Variant/CancelButton/CancelButton';
import SaveButton from '../../../../../../@Future/Component/Generic/Button/Variant/SaveButton/SaveButton';
import useRelatedEntity from '../../../../../../@Api/Entity/Hooks/useRelatedEntity';
import Input from '../../../Input/Input';
import { validateAndCommitEntityOrShowError } from '../../../../../../@Api/Entity/Commit/validateAndCommitEntityOrShowError';
import { setValueByFieldInEntity } from '../../../../../../@Api/Entity/Commit/Context/Api/Compatibility/setValueByFieldInEntity';
import AttachmentDialog from '../../../Bespoke/Attachment/Dialog/AttachmentDialog';

export interface AttachmentProps extends ContentProps
{
}

const Attachment: React.FC<AttachmentProps> =
    props =>
    {
        const [ files, setFiles ] = useState<File[]>([]);
        const types = useTypes();

        const parentEntity =
            useRelatedEntity(
                props.entity,
                types.Entity.RelationshipDefinition.Attachments,
                true,
                props.commitContext
            );

        const uploadFiles =
            useCallback(
                (files: File[]) =>
                {
                    if (files.length === 1)
                    {
                        const firstFile = files.find(() => true);
                        setValueByFieldInEntity(
                            props.entity,
                            types.Attachment.Field.File,
                            FileValue.fromFile(firstFile),
                            props.commitContext
                        );
                    }

                    setFiles(files);
                },
                [
                    props,
                    setFiles,
                    types
                ]);

        const uploaderStore =
            useMemo(
                () =>
                    new FileUploaderStore(uploadFiles),
                [
                    uploadFiles
                ]);

        const save =
            useCallback(
                async () =>
                {
                    const [ isCommitted ] =
                        await validateAndCommitEntityOrShowError(
                            props.entity,
                            {
                                context: props.commitContext,
                            }
                        );

                    if (isCommitted)
                    {
                        return props.onSave(props.entity);
                    }
                },
                [
                    props
                ]);

        const isParentEntitySupportTicket =
            useComputed(
                () =>
                    parentEntity?.entityType.isA(types.Activity.SupportTicket.Type),
                [
                    parentEntity,
                    types
                ]);

        return <ViewGroup
            orientation="vertical"
            spacing={0}
        >
            {
                files.length === 1 &&
                    <ViewGroupItem>
                        <ViewGroup
                            orientation="vertical"
                            spacing={0}
                        >
                            <ViewGroupItem>
                                <Item
                                    attachment={props.entity}
                                    commitContext={props.commitContext}
                                />
                            </ViewGroupItem>
                            <ViewGroupItem>
                                <ViewGroup
                                    orientation="horizontal"
                                    spacing={15}
                                >
                                    <ViewGroupItem
                                        ratio={1}
                                    >
                                        {
                                            isParentEntitySupportTicket &&
                                                <Input
                                                    entity={props.entity}
                                                    labelPosition="left"
                                                    field={types.Attachment.Field.IsVisibleInSupportPortal}
                                                    doAutocommit={false}
                                                    commitContext={props.commitContext}
                                                />
                                        }
                                    </ViewGroupItem>
                                    <ViewGroupItem>
                                        <CancelButton
                                            onClick={props.onClose}
                                        />
                                    </ViewGroupItem>
                                    <ViewGroupItem>
                                        <SaveButton
                                            onClick={save}
                                        />
                                    </ViewGroupItem>
                                </ViewGroup>
                            </ViewGroupItem>
                        </ViewGroup>
                    </ViewGroupItem>
            }
            {
                files.length > 1 &&
                <AttachmentDialog
                    files={files}
                    entity={parentEntity}
                    onReset={props.onClose}
                />
            }
            {
                files.length === 0 &&
                    <ViewGroupItem>
                        <FileUploader
                            store={uploaderStore}
                        />
                    </ViewGroupItem>
            }
        </ViewGroup>;
    };

export default observer(Attachment);
