import { computed, observable } from 'mobx';
import { EntityTypeStore } from './EntityTypeStore';
import { EntityType } from '../../../../@Api/Model/Implementation/EntityType';
import { EntityField } from '../../../../@Api/Model/Implementation/EntityField';
import { EntityRelationshipDefinition } from '../../../../@Api/Model/Implementation/EntityRelationshipDefinition';
import { Entity } from '../../../../@Api/Model/Implementation/Entity';
import Decimal from 'decimal.js';
import getPhaseRelationshipDefinition from '../../../../@Api/Entity/Bespoke/Datastore/Phase/getPhaseRelationshipDefinition';
import { createTransactionalModel } from '../../../../@Util/TransactionalModelV2/index';
import { CommitContext } from '../../../../@Api/Entity/Commit/Context/CommitContext';
import { constructEntityOfType } from '../../../../@Api/Entity/Commit/Context/Api/Compatibility/constructEntityOfType';
import { setValueByFieldInEntity } from '../../../../@Api/Entity/Commit/Context/Api/Compatibility/setValueByFieldInEntity';
import { updateRelationship } from '../../../../@Api/Entity/Commit/Context/Api/Compatibility/updateRelationship';
import { getEmailAddressesByEntity } from '../../../../@Api/Entity/Bespoke/EmailAddress/getEmailAddressesByEntity';

export class BespokeEntityTypeStore
{
    // ------------------------ Dependencies ------------------------

    // ------------------------- Properties -------------------------

    @observable entityTypeStore: EntityTypeStore;

    @computed
    get types()
    {
        return {
            Entity:
            {
                Type: this.getType('Entity'),
                Field:
                {
                    Id: this.getField('Entity', 'static.entity.id'),
                    Uuid: this.getField('Entity', 'static.entity.uuid'),
                    Name: this.getField('Entity', 'static.entity.name'),
                    Description: this.getField('Entity', 'static.entity.description'),
                    SortIndex: this.getField('Entity', 'static.entity.sort_index'),
                    Type: this.getField('Entity', 'static.entity.type'),
                    CreationDate: this.getField('Entity', 'Entity.CreationDate'),
                    IsRoot: this.getField('Entity', 'Entity.IsRoot'),
                    IsActive: this.getField('Entity', 'Entity.IsActive'),
                    IsExclusion: this.getField('Entity', 'Entity.IsExclusion'),
                    FollowupStartDate: this.getField('Entity', 'Entity.Followup.Start'),
                    FollowupEndDate: this.getField('Entity', 'Entity.Followup.End'),
                    Deadline: this.getField('Entity', 'Entity.Deadline'),
                    IsClosed: this.getField('Entity', 'Entity.IsClosed'),
                    CloseDate: this.getField('Entity', 'Entity.CloseDate'),
                    NumberOfNotes: this.getField('Entity', 'Entity.NumberOfNotes'),
                    NumberOfAttachments: this.getField('Entity', 'Entity.NumberOfAttachments')
                },
                RelationshipDefinition:
                {
                    Overrides: this.getRelationshipDefinition('Entity:Overrides'),
                    Events: this.getRelationshipDefinition('Entity:Events'),
                    Labels: this.getRelationshipDefinition('Entities:Labels'),
                    Notes: this.getRelationshipDefinition('Entity:Notes'),
                    Attachments: this.getRelationshipDefinition('Entity:Attachments'),
                    PhaseReference: this.getRelationshipDefinition('Entity:PhaseReference'),
                    ClosePhase: this.getRelationshipDefinition('Entity:ClosePhase'),
                    WorkflowState: this.getRelationshipDefinition('Entity:WorkflowState'),
                    CloseWorkflowState: this.getRelationshipDefinition('Entity:CloseWorkflowState'),
                    Notifications: this.getRelationshipDefinition('Entity:Notifications'),
                    ExternalIds: this.getRelationshipDefinition('Entity:ExternalIds')
                },
                Function:
                {
                    SetPhase: (entity: Entity, phase: Entity) =>
                    {
                        const phaseRelationshipDefinition = getPhaseRelationshipDefinition(entity.entityType);

                        if (phaseRelationshipDefinition)
                        {
                            return entity.updateRelationship(
                                false,
                                phaseRelationshipDefinition,
                                createTransactionalModel(phase));
                        }
                        else
                        {
                            return undefined;
                        }
                    }
                },
                Entity:
                {
                    MainFieldGroupId: 128 // 'Algemeen' field group
                }
            },
            Event:
            {
                Type: this.getType('Event'),
                RelationshipDefinition:
                {
                    User: this.getRelationshipDefinition('Event:Relationship.Person.Contact.Employee')
                },
                Read:
                {
                    Type: this.getType('Event.Read')
                }
            },
            Relation:
            {
                Type: this.getType('Relation'),
                Field:
                {
                    Picture: this.getField('Relation', 'Relation.Picture'),
                    YouTube: this.getField('Relation', 'Relation.YouTube'),
                    Instagram: this.getField('Relation', 'Relation.Instagram'),
                    Number: this.getField('Relation', 'Relation.Number'),
                    DebtorNumber: this.getField('Relation', 'Relation.DebtorNumber'),
                    CreditorNumber: this.getField('Relation', 'Relation.CreditorNumber')
                },
                Person:
                {
                    Type: this.getType('Relation.Person'),
                    RelationshipDefinition:
                    {
                        Language: this.getRelationshipDefinition('Relation.Person:Datastore.Language'),
                        Address: this.getRelationshipDefinition('Relation.Person:Address')
                    },
                    Field:
                    {
                        FirstName: this.getField('Relation.Person', 'Relation.Person.FirstName'),
                        MiddleName: this.getField('Relation.Person', 'Relation.Person.MiddleName'),
                        LastName: this.getField('Relation.Person', 'Relation.Person.LastName'),
                        WorkspaceBackground: this.getField('Relation.Person', 'Relation.Person.WorkspaceBackground'),
                        PhoneNumber: this.getField('Relation.Person', 'Relation.Person.PhoneNumber'),
                        MobilePhoneNumber: this.getField('Relation.Person', 'Relation.Person.MobilePhoneNumber'),
                        EmailAddress: this.getField('Relation.Person', 'Relation.Person.EmailAddress'),
                        BirthDate: this.getField('Relation.Person', 'Relation.Person.BirthDate')
                    },
                    Account:
                    {
                        Type: this.getType('Relation.Person.Account'),
                        RelationshipDefinition:
                        {
                            Packs: this.getRelationshipDefinition('Relation.Person.Account:Packs')
                        }
                    }
                },
                Organization:
                {
                    Type: this.getType('Relation.Organization'),
                    Field:
                    {
                        Name: this.getField('Relation.Organization', 'Relation.Organization.Name'),
                        PhoneNumber: this.getField('Relation.Organization', 'Relation.Organization.PhoneNumber'),
                        EmailAddress: this.getField('Relation.Organization', 'Relation.Organization.EmailAddress'),
                        FinancialEmailAddress: this.getField('Relation.Organization', 'Relation.Organization.FinancialEmailAddress'),
                        Website: this.getField('Relation.Organization', 'Relation.Organization.Website'),
                        CompanyActivity: this.getField('Relation.Organization', 'Relation.Organization.CompanyActivity'),
                        NumberOfEmployees: this.getField('Relation.Organization', 'Relation.Organization.NumberOfEmployees'),
                        OIN: this.getField('Relation.Organization', 'Relation.Organization.OIN'),
                        ChamberOfCommerceNumber: this.getField('Relation.Organization', 'Relation.Organization.ChamberOfCommerceNumber'),
                        ChamberOfCommerceName: this.getField('Relation.Organization', 'Relation.Organization.ChamberOfCommerceName'),
                        EstablishmentNumber: this.getField('Relation.Organization', 'Relation.Organization.EstablishmentNumber'),
                        RSIN: this.getField('Relation.Organization', 'Relation.Organization.RSIN'),
                        DateIssued: this.getField('Relation.Organization', 'Relation.Organization.IssueDate'),
                        ExpirationDate: this.getField('Relation.Organization', 'Relation.Organization.ExpirationDate'),
                        FoundingDate: this.getField('Relation.Organization', 'Relation.Organization.FoundingDate'),
                        DiscontinuationDate: this.getField('Relation.Organization', 'Relation.Organization.DiscontinuationDate'),
                        EstablishmentDate: this.getField('Relation.Organization', 'Relation.Organization.EstablishmentDate')
                    },
                    RelationshipDefinition:
                    {
                        VisitingAddress: this.getRelationshipDefinition('Relation.Organization:VisitingAddress'),
                        PostalAddress: this.getRelationshipDefinition('Relation.Organization:PostalAddress'),
                        InvoiceAddress: this.getRelationshipDefinition('Relation.Organization:InvoiceAddress'),
                        LegalForm: this.getRelationshipDefinition('Relation.Organization:Datastore.LegalForm')
                    },
                    Environment:
                    {
                        Type: this.getType('Relation.Organization.Environment'),
                        RelationshipDefinition:
                        {
                            Packs: this.getRelationshipDefinition('Relation.Organization.Environment:Packs'),
                            AutomationActivations: this.getRelationshipDefinition('Relation.Organization.Environment:AutomationActivations'),
                        },
                        Field:
                        {
                            IsOnboarding: this.getField('Relation.Organization.Environment', 'Relation.Organization.Environment.IsOnboarding'),
                            IsInSetup: this.getField('Relation.Organization.Environment', 'Relation.Organization.Environment.IsInSetup')
                        }
                    }
                },
                RelationshipDefinition:
                {
                    Relationships: this.getRelationshipDefinition('Relation:Relationships'),
                    Addresses: this.getRelationshipDefinition('Relation:Addresses'),
                    EmailAddresses: this.getRelationshipDefinition('Relation:EmailAddresses'),
                    PhoneNumbers: this.getRelationshipDefinition('Relation:PhoneNumbers'),
                    Language: this.getRelationshipDefinition('Relation:Language'),
                }
            },
            Relationship:
            {
                Type: this.getType('Relationship'),
                RelationshipDefinition:
                {
                    AccountManager: this.getRelationshipDefinition('Relationship:AccountManager'),
                    Activities: this.getRelationshipDefinition('Relationship:Activities'),
                    TimeRegistrations: this.getRelationshipDefinition('Relationship:TimeRegistrations'),
                    MileageRegistrations: this.getRelationshipDefinition('Relationship:MileageRegistrations'),
                    OptOutEmailingCategories: this.getRelationshipDefinition('Relationship:OptOutEmailingCategories'),
                    ChildAccounts: this.getRelationshipDefinition('Relationship.MasterAccount:ChildAccounts'),
                    PriceList: this.getRelationshipDefinition('Relationship:PriceList'),
                    EmployeeAllocations: this.getRelationshipDefinition('Relationship:EmployeeAllocations'),
                    LeexiCallParticipants: this.getRelationshipDefinition('Relationship:LeexiCallParticipants'),
                },
                Field:
                {
                    OptOut: this.getField('Relationship', 'Relationship.MailingOptOut'),
                    IsFormer: this.getField('Relationship', 'Relationship.IsFormer'),
                    IsMasterAccount: this.getField('Relationship', 'Relationship.IsMasterAccount')
                },
                Function:
                {
                    GetRelationType:
                        (relationship: Entity) =>
                            relationship.entityType.isA(this.entityTypeStore.bespoke.types.Relationship.Person.Type)
                                ?
                                    this.entityTypeStore.bespoke.types.Relation.Person.Type
                                :
                                    this.entityTypeStore.bespoke.types.Relation.Organization.Type,
                    GetRelation:
                        (relationship: Entity) =>
                            relationship.entityType.isA(this.entityTypeStore.bespoke.types.Relationship.Person.Type)
                                ?
                                    relationship.getRelatedEntityByDefinition(
                                        false,
                                        this.entityTypeStore.bespoke.types.Relationship.Person.RelationshipDefinition.Person)
                                :
                                    relationship.getRelatedEntityByDefinition(
                                        false,
                                        this.entityTypeStore.bespoke.types.Relationship.Organization.RelationshipDefinition.Organization)
                },
                Person:
                {
                    Type: this.getType('Relationship.Person'),
                    RelationshipDefinition:
                    {
                        Person: this.getRelationshipDefinition('Relationship.Person:Person')
                    },
                    Candidate:
                    {
                        Type: this.getType('Relationship.Person.Candidate')
                    },
                    Commercial:
                    {
                        Type: this.getType('Relationship.Person.CommercialRelationship'),
                        Customer:
                        {
                            Type: this.getType('Relationship.Person.CommercialRelationship.Customer')
                        },
                        HotProspect:
                        {
                            Type: this.getType('Relationship.Person.CommercialRelationship.HotProspect')
                        },
                        Lead:
                        {
                            Type: this.getType('Relationship.Person.CommercialRelationship.Lead')
                        },
                        Prospect:
                        {
                            Type: this.getType('Relationship.Person.CommercialRelationship.Prospect')
                        },
                        Suspect:
                        {
                            Type: this.getType('Relationship.Person.CommercialRelationship.Suspect')
                        },
                    },
                    Contact:
                    {
                        Type: this.getType('Relationship.Person.Contact'),
                        Field:
                        {
                            PhoneNumber: this.getField('Relationship.Person.Contact', 'Relationship.Person.Contact.PhoneNumber'),
                            MobilePhoneNumber: this.getField('Relationship.Person.Contact', 'Relationship.Person.Contact.MobilePhoneNumber'),
                            EmailAddress: this.getField('Relationship.Person.Contact', 'Relationship.Person.Contact.EmailAddress'),
                            FunctionOnBusinessCard: this.getField('Relationship.Person.Contact', 'Relationship.Person.Contact.FunctionOnBusinessCard')
                        },
                        RelationshipDefinition:
                        {
                            Activities: this.getRelationshipDefinition('Relationship.Person.Contact:Activities'),
                            Function: this.getRelationshipDefinition('Relationship.Person.Contact:Function'),
                            ContactLeexiCallParticipants: this.getRelationshipDefinition('Relationship.Person.Contact:ContactLeexiCallParticipants'),
                        },
                        Standard:
                        {
                            Type: this.getType('Relationship.Person.Contact.Standard')
                        },
                        Employee:
                        {
                            Type: this.getType('Relationship.Person.Contact.Employee'),
                            Field:
                            {
                                IsAdministrator: this.getField('Relationship.Person.Contact.Employee', 'Relationship.Person.Contact.Employee.IsAdministrator'),
                                IsSupport: this.getField('Relationship.Person.Contact.Employee', 'Relationship.Person.Contact.Employee.IsSupport'),
                                WorkableHoursPerWeek: this.getField('Relationship.Person.Contact.Employee', 'Relationship.Person.Contact.Employee.WorkableHoursPerWeek')
                            },
                            RelationshipDefinition:
                            {
                                Pack: this.getRelationshipDefinition('Relationship.Person.Contact.Employee:Pack.User'),
                                CreatedEntities: this.getRelationshipDefinition('Creator:CreatedEntities'),
                                Favorites: this.getRelationshipDefinition('Relationship.Person.Employee:Favorites'),
                                Notifications: this.getRelationshipDefinition('Relationship.Person.Employee:Notifications'),
                                DeprecatedDashboards: this.getRelationshipDefinition('Relationship.Person.Employee:Dashboards'),
                                Dashboards: this.getRelationshipDefinition('Relationship.Person.Contact.Employee:Dashboards'),
                                Settings: this.getRelationshipDefinition('Relationship.Person.Contact.Employee:UserSettings'),
                                ConnectorActivations: this.getRelationshipDefinition('Relationship.Person.Contact.Employee:ConnectorActivations'),
                                AutomationActivations: this.getRelationshipDefinition('Relationship.Person.Contact.Employee:AutomationActivations'),
                                Automations: this.getRelationshipDefinition('Relationship.Person.Contact.Employee:Automations'),
                                CalendarItems: this.getRelationshipDefinition('Relationship.Person.Contact.Employee:CalendarItems'),
                                Allocations: this.getRelationshipDefinition('Relationship.Person.Contact.Employee:Allocations'),
                                TimeRegistrations: this.getRelationshipDefinition('Relationship.Person.Contact.Employee:TimeRegistrations'),
                                MileageRegistrations: this.getRelationshipDefinition('Relationship.Person.Contact.Employee:MileageRegistrations'),
                                Skills: this.getRelationshipDefinition('Relationship.Person.Contact.Employee:Skills'),
                            },
                            Trainee:
                            {
                                Type: this.getType('Relationship.Person.Contact.Employee.Trainee')
                            }
                        }
                    },
                    Private:
                    {
                        Type: this.getType('Relationship.Person.Private')
                    },
                    PortalUser:
                    {
                        Type: this.getType('Relationship.Person.PortalUser')
                    },
                    CourseInstructor:
                    {
                        Type: this.getType('Relationship.Person.CourseInstructor'),
                    }
                },
                Organization:
                {
                    Type: this.getType('Relationship.Organization'),
                    RelationshipDefinition:
                    {
                        Organization: this.getRelationshipDefinition('Relationship.Organization:Organization')
                    },
                    BusinessPartner:
                    {
                        Type: this.getType('Relationship.Organization.BusinessPartner')
                    },
                    Commercial:
                    {
                        Type: this.getType('Relationship.Organization.CommercialRelationship'),
                        Customer:
                        {
                            Type: this.getType('Relationship.Organization.CommercialRelationship.Customer')
                        },
                        HotProspect:
                        {
                            Type: this.getType('Relationship.Organization.CommercialRelationship.HotProspect')
                        },
                        Lead:
                        {
                            Type: this.getType('Relationship.Organization.CommercialRelationship.Lead')
                        },
                        Prospect:
                        {
                            Type: this.getType('Relationship.Organization.CommercialRelationship.Prospect')
                        },
                        Suspect:
                        {
                            Type: this.getType('Relationship.Organization.CommercialRelationship.Suspect')
                        },
                    },
                    Competitor:
                    {
                        Type: this.getType('Relationship.Organization.Competitor')
                    },
                    General:
                    {
                        Type: this.getType('Relationship.Organization.General')
                    },
                    Identity:
                    {
                        Type: this.getType('Relationship.Organization.Identity')
                    },
                    Subsidiary:
                    {
                        Type: this.getType('Relationship.Organization.Subsidiary')
                    },
                    Supplier:
                    {
                        Type: this.getType('Relationship.Organization.Supplier')
                    },
                    Partner:
                    {
                        Type: this.getType('Relationship.Organization.Partner'),
                        RelationshipDefinition:
                        {
                            PartnerRelationshipPriceList: this.getRelationshipDefinition('Relationship.Organization.Partners:PartnerRelationshipPriceList')
                        },
                    }
                }
            },
            Favorite:
            {
                Type: this.getType('Favorite'),
                RelationshipDefinition:
                {
                    Entity: this.getRelationshipDefinition('Favorite:Entity')
                }
            },
            Template:
            {
                Type: this.getType('Template'),
                Field:
                {
                    IsDefault: this.getField('Template', 'Template.IsDefault'),
                    Content: this.getField('Template', 'Template.Content'),
                    File: this.getField('Template', 'Template.File'),
                    LocalizedName: this.getField('Template', 'Template.LocalizedName'),
                    Font: this.getField('Template', 'Template.Font'),
                    Code: this.getField('Template', 'Template.Code'),
                    IsInactive: this.getField('Template', 'Template.IsInactive'),
                },
                RelationshipDefinition:
                {
                    Language: this.getRelationshipDefinition('Template:Language'),
                },
                Document:
                {
                    Type: this.getType('Template.Document'),
                    Field:
                    {
                        Header: this.getField('Template.Document', 'Template.Document.Header'),
                        Footer: this.getField('Template.Document', 'Template.Document.Footer'),

                        MarginTop: this.getField('Template.Document', 'Template.Document.MarginTop'),
                        MarginRight: this.getField('Template.Document', 'Template.Document.MarginRight'),
                        MarginBottom: this.getField('Template.Document', 'Template.Document.MarginBottom'),
                        MarginLeft: this.getField('Template.Document', 'Template.Document.MarginLeft'),
                    },
                    Offer:
                    {
                        Type: this.getType('Template.Document.Offer'),
                        RelationshipDefinition:
                        {
                            DefaultEmailTemplate: this.getRelationshipDefinition('Template.Document.Offer:DefaultEmailTemplate'),
                        },
                    },
                    Invoice:
                    {
                        Type: this.getType('Template.Document.Invoice'),
                        RelationshipDefinition:
                        {
                            DefaultEmailTemplate: this.getRelationshipDefinition('Template.Document.Invoice:DefaultEmailTemplate'),
                        },
                    },
                    DocumentActivity:
                    {
                        Type: this.getType('Template.Document.DocumentActivity'),
                    },
                    WorkOrder:
                    {
                        Type: this.getType('Template.Document.WorkOrder'),
                        RelationshipDefinition:
                        {
                            DefaultEmailTemplate: this.getRelationshipDefinition('Template.Document.WorkOrder:DefaultEmailTemplate'),
                        },
                    }
                },
                Email:
                {
                    Type: this.getType('Template.Email'),
                    General:
                    {
                        Type: this.getType('Template.Email.General'),
                    },
                    Offer:
                    {
                        Type: this.getType('Template.Email.Offer'),
                    },
                    Invoice:
                    {
                        Type: this.getType('Template.Email.Invoice'),
                    },
                    WorkOrder:
                    {
                        Type: this.getType('Template.Email.WorkOrder'),
                    },
                    WorkOrderSign:
                    {
                        Type: this.getType('Template.Email.WorkOrderSign'),
                    },
                    RelationshipDefinition:
                    {
                        DefaultEmailSender: this.getRelationshipDefinition('Template.Email:DefaultEmailSender'),
                        Emails: this.getRelationshipDefinition('Template.Email:Emails'),
                    },
                    Field:
                    {
                        SubjectTemplate: this.getField('Template', 'Template.Email.SubjectTemplate'),
                    }
                },
                Block:
                {
                    Type: this.getType('Template.Block'),
                    RelationshipDefinition:
                    {
                        TemplateType: this.getRelationshipDefinition('Template.Block:TemplateType')
                    }
                }
            },
            TemplateCategory:
            {
                Type: this.getType('TemplateCategory'),
                Field:
                {
                    Name: this.getField('TemplateCategory', 'TemplateCategory.Name')
                },
                RelationshipDefinition:
                {
                    ChildCategories: this.getRelationshipDefinition('TemplateCategory.ParentCategory:ChildCategories'),
                    Templates: this.getRelationshipDefinition('TemplateCategory:Templates')
                },
                Offer:
                {
                    Type: this.getType('TemplateCategory.Offer')
                },
                Invoice:
                {
                    Type: this.getType('TemplateCategory.Invoice')
                },
                Workorder:
                {
                    Type: this.getType('TemplateCategory.WorkOrder')
                },
                Document:
                {
                    Type: this.getType('TemplateCategory.Document')
                },
                Email:
                {
                    Type: this.getType('TemplateCategory.Email')
                }
            },
            EntityType:
            {
                Type: this.getType('EntityType'),
                RelationshipDefinition:
                {
                    Entities: this.getRelationshipDefinition('EntityType:Entities'),
                    Subtypes: this.getRelationshipDefinition('EntityType:EntityTypes'),
                    Fields: this.getRelationshipDefinition('EntityType:EntityFields'),
                    RelationshipDefinitions: this.getRelationshipDefinition('EntityType:EntityRelationshipDefinitions'),
                    TypeGroups: this.getRelationshipDefinition('EntityType:EntityTypeGroups'),
                    Filters: this.getRelationshipDefinition('EntityType:Filters'),
                    Views: this.getRelationshipDefinition('EntityType:Views'),
                    Datasets: this.getRelationshipDefinition('EntityType:Datasets'),
                    Dashboards: this.getRelationshipDefinition('EntityType:Dashboards'),
                    Automations: this.getRelationshipDefinition('EntityType:Automations'),
                    Webhooks: this.getRelationshipDefinition('EntityType:Webhooks'),
                    Workflows: this.getRelationshipDefinition('EntityType:Workflows'),
                    FieldGroups: this.getRelationshipDefinition('EntityType:EntityFieldGroups'),
                    Imports: this.getRelationshipDefinition('EntityType:Imports'),
                    Pipelines: this.getRelationshipDefinition('EntityType:Pipelines'),
                    BulkDeletions: this.getRelationshipDefinition('EntityType:BulkDeletions'),
                    ResourcePlanners: this.getRelationshipDefinition('EntityType:ResourcePlanners'),
                    Pages: this.getRelationshipDefinition('EntityType:Pages')
                },
                Field:
                {
                    LocalizedSingularName: this.getField('EntityType', 'EntityType.LocalizedSingularName'),
                    LocalizedPluralName: this.getField('EntityType', 'EntityType.LocalizedPluralName'),
                    Metadata: this.getField('EntityType', 'EntityType.Metadata'),
                    IsMetadata: this.getField('EntityType', 'EntityType.IsMetadata'),
                    IsOverridable: this.getField('EntityType', 'EntityType.IsOverridable'),
                    IsNameHiddenForUser: this.getField('EntityType', 'EntityType.IsNameHiddenForUser'),
                    IsInUserPack: this.getField('EntityType', 'EntityType.IsInUserPack'),
                    HasProducts: this.getField('EntityType', 'EntityType.HasProducts'),
                    HasInvolved: this.getField('EntityType', 'EntityType.HasInvolved')
                }
            },
            EntityTypeGroup:
            {
                Type: this.getType('EntityTypeGroup'),
                RelationshipDefinition:
                {
                    EntityTypes: this.getRelationshipDefinition('EntityTypeGroup:EntityTypes'),
                    Groups: this.getRelationshipDefinition('EntityTypeGroup:EntityTypeGroups')
                }
            },
            EntityField:
            {
                Type: this.getType('EntityField'),
                Field:
                {
                    IsHiddenFromDetails: this.getField('EntityField', 'EntityField.IsHiddenFromDetails'),
                    LocalizedName: this.getField('EntityField', 'EntityField.LocalizedName'),
                    LocalizedDescription: this.getField('EntityField', 'EntityField.LocalizedDescription')
                },
                Functions:
                    {
                        isSystem:
                            (entity: Entity): boolean =>
                            {
                                return entity.getRelatedEntityByDefinition(
                                    true,
                                    this.types.Pack.RelationshipDefinition.Entities
                                ).entityType.isA(
                                    this.types.Pack.System.Type
                                );
                            },
                        isApplication:
                            (entity: Entity): boolean =>
                            {
                                return entity.getRelatedEntityByDefinition(
                                    true,
                                    this.types.Pack.RelationshipDefinition.Entities
                                ).entityType.isA(
                                    this.types.Pack.Application.Type
                                );
                            }
                    }
            },
            EntityFieldGroup:
            {
                Entity:
                {
                    NameGroup: 125687,
                    SettingGroup: 125756
                },
                Type: this.getType('EntityFieldGroup'),
                RelationshipDefinition:
                {
                    Fields: this.getRelationshipDefinition('EntityFieldGroup:EntityFields'),
                    Relationships: this.getRelationshipDefinition('EntityFieldGroup:EntityRelationshipDefinition')
                },
                Field:
                {
                    Code: this.getField('EntityFieldGroup', 'EntityFieldGroup.Code'),
                    LocalizedName: this.getField('EntityFieldGroup', 'EntityFieldGroup.LocalizedName'),
                    IsSystem: this.getField('EntityFieldGroup', 'EntityFieldGroup.IsSystem'),
                    Predicate: this.getField('EntityFieldGroup', 'EntityFieldGroup.Predicate')
                },
                MainFieldGroupName: 'Algemeen'
            },
            EntityRelationshipDefinition:
            {
                Type: this.getType('EntityRelationshipDefinition'),
                RelationshipDefinition:
                {
                    EntityType: this.getRelationshipDefinition('EntityRelationshipDefinitions:EntityType')
                },
                Field:
                {
                    LocalizedChildName: this.getField('EntityRelationshipDefinition', 'EntityRelationshipDefinition.LocalizedChildName'),
                    LocalizedParentName: this.getField('EntityRelationshipDefinition', 'EntityRelationshipDefinition.LocalizedParentName'),
                    ParentFilter: this.getField('EntityRelationshipDefinition', 'EntityRelationshipDefinition.ParentFilter'),
                    ChildFilter: this.getField('EntityRelationshipDefinition', 'EntityRelationshipDefinition.ChildFilter'),
                }
            },
            Page:
            {
                Type: this.getType('Page'),
                Field:
                {
                    Name: this.getField('Page', 'Page.Name'),
                    TitleLayout: this.getField('Page', 'Page.TitleLayout'),
                    Layout: this.getField('Page', 'Page.Layout')
                }
            },
            ActionTemplate:
            {
                Type: this.getType('ActionTemplate'),
                Field:
                {
                    Name: this.getField('ActionTemplate', 'ActionTemplate.Name'),
                    Specification: this.getField('ActionTemplate', 'ActionTemplate.Specification')
                }
            },
            View:
            {
                Type: this.getType('View'),
                Field:
                {
                    Name: this.getField('View', 'View.Name'),
                    LocalizedName: this.getField('View', 'View.LocalizedName'),
                    Specification: this.getField('View', 'View.Specification'),
                    IsPrivate: this.getField('View', 'View.IsPrivate'),
                    IsDefault: this.getField('View', 'View.IsDefault'),
                },
                RelationshipDefinition:
                {
                    SharedWithEmployees: this.getRelationshipDefinition('Views:SharedWithEmployees'),
                    SharedWithTeams: this.getRelationshipDefinition('Views:SharedWithTeams'),
                    Owners: this.getRelationshipDefinition('Views:Owners'),
                }
            },
            Filter:
            {
                Type: this.getType('Filter'),
                Field:
                {
                    Name: this.getField('Filter', 'Filter.Name'),
                    Predicate: this.getField('Filter', 'Filter.Predicate')
                }
            },
            Dashboard:
            {
                Type: this.getType('Dashboard'),
                Field:
                {
                    Name: this.getField('Dashboard', 'Dashboard.Name'),
                    Icon: this.getField('Dashboard', 'Dashboard.Icon'),
                    Specification: this.getField('Dashboard', 'Dashboard.Specification'),
                    IsDefault: this.getField('Dashboard', 'Dashboard.IsDefault')
                },
                RelationshipDefinition:
                {
                    SharedWithEmployees: this.getRelationshipDefinition('Dashboards:SharedWithEmployees')
                }
            },
            Dataset:
            {
                Type: this.getType('Dataset'),
                RelationshipDefinition:
                {
                    Categories: this.getRelationshipDefinition('Dataset:DatasetCategories'),
                    SharedWithEmployees: this.getRelationshipDefinition('Datasets:SharedWithEmployees'),
                    SharedWithTeams: this.getRelationshipDefinition('Datasets:SharedWithTeams'),
                    Owners: this.getRelationshipDefinition('Datasets:Owners'),
                },
                Field:
                {
                    LocalizedName: this.getField('Dataset', 'Dataset.LocalizedName'),
                    Specification: this.getField('Dataset', 'Dataset.Specification'),
                    CategoryNameTemplate: this.getField('Dataset', 'Dataset.CategoryNameTemplate'),
                    Predicate: this.getField('Dataset', 'Dataset.Predicate'),
                    IsTimeline: this.getField('Dataset', 'Dataset.IsTimeline'),
                    ShowFullDatasetInSidebar: this.getField('Dataset', 'Dataset.ShowFullDatasetInSidebar'),
                    HideForUser: this.getField('Dataset', 'Dataset.HideForUser'),
                    NoDataLabel: this.getField('Dataset', 'Dataset.NoDataLabel'),
                    IsPrivate: this.getField('Dataset', 'Dataset.IsPrivate'),
                }
            },
            DatasetCategory:
            {
                Type: this.getType('DatasetCategory'),
                Field:
                {
                    GroupFieldPath: this.getField('DatasetCategory', 'DatasetCategory.GroupFieldPath'),
                    ValueFieldPath: this.getField('DatasetCategory', 'DatasetCategory.ValueFieldPath')
                },
                RelationshipDefinition:
                {
                    Layout: this.getRelationshipDefinition('DatasetCategory:Datastore.DataCategory.Layout'),
                    Segments: this.getRelationshipDefinition('DatasetCategory:DatasetCategorySegments')
                }
            },
            DatasetCategorySegment:
            {
                Type: this.getType('DatasetCategorySegment'),
                Field:
                {
                    SegmentFieldPath: this.getField('DatasetCategorySegment', 'DatasetCategorySegment.SegmentFieldPath')
                }
            },
            CustomWidget:
            {
                Type: this.getType('CustomWidget'),
                Field:
                {
                    Name: this.getField('CustomWidget', 'CustomWidget.Name'),
                    Specification: this.getField('CustomWidget', 'CustomWidget.Specification')
                }
            },
            Automation:
            {
                Type: this.getType('Automation'),
                Field:
                {
                    LocalizedName: this.getField('Automation', 'Automation.LocalizedName'),
                    Specification: this.getField('Automation', 'Automation.Specification'),
                    Code: this.getField('Automation', 'Automation.Code'),
                    IsUser: this.getField('Automation', 'Automation.IsUser'),
                    Description: this.getField('Automation', 'Automation.Description'),
                },
                RelationshipDefinition:
                {
                    Activations: this.getRelationshipDefinition('Automation:Activations')
                }
            },
            AutomationActivation:
            {
                Type: this.getType('AutomationActivation'),
                Field:
                    {
                        IsDeactivated: this.getField('AutomationActivation', 'AutomationActivation.IsDeactivated'),
                        IsDeactivatedByUser: this.getField('AutomationActivation', 'AutomationActivation.IsDeactivatedByUser'),
                        DeactivationDate: this.getField('AutomationActivation', 'AutomationActivation.DeactivationDate'),
                        DeactivationReason: this.getField('AutomationActivation', 'AutomationActivation.DeactivationReason')
                    }
            },
            AutomationMacro:
            {
                Type: this.getType('AutomationMacro')
            },
            Application:
            {
                Type: this.getType('Application'),
                Field:
                {
                    Name: this.getField('Application', 'Application.Name'),
                    RedirectUrl: this.getField('Application', 'Application.RedirectUrl'),
                    Secret: this.getField('Application', 'Application.Secret')
                }
            },
            Webhook:
            {
                Type: this.getType('Webhook'),
                Field:
                {
                    Url: this.getField('Webhook', 'Webhook.Url'),
                    Secret: this.getField('Webhook', 'Webhook.Secret'),
                    Metadata: this.getField('Webhook', 'Webhook.Metadata'),
                    Trigger: this.getField('Webhook', 'Webhook.Trigger'),
                    IsDeactivated: this.getField('Webhook', 'Webhook.IsDeactivated'),
                    DeactivationDate: this.getField('Webhook', 'Webhook.DeactivationDate'),
                    DeactivationReason: this.getField('Webhook', 'Webhook.DeactivationReason'),
                }
            },
            Notification:
            {
                Type: this.getType('Notification'),
                Field:
                {
                    Name: this.getField('Notification', 'Notification.Name'),
                    IsRead: this.getField('Notification', 'Notification.IsRead'),
                    Date: this.getField('Notification', 'Notification.Date'),
                    Variant: this.getField('Notification', 'Notification.Variant')
                },
                RelationshipDefinition:
                {
                    Initiator: this.getRelationshipDefinition('Notification:Initiator')
                }
            },
            Note:
            {
                Type: this.getType('Note'),
                Field:
                {
                    Content: this.getField('Note', 'Note.Content'),
                    IsVisibleInSupportPortal: this.getField('Note', 'Note.IsVisibleInSupportPortal'),
                    IsPinned: this.getField('Note', 'Note.IsPinned')
                }
            },
            Attachment:
            {
                Type: this.getType('Attachment'),
                Field:
                {
                    File: this.getField('Attachment', 'Attachment.File'),
                    IsVisibleInSupportPortal: this.getField('Attachment', 'Attachment.IsVisibleInSupportPortal')
                }
            },
            Pack:
            {
                Type: this.getType('Pack'),
                RelationshipDefinition:
                {
                    Entities: this.getRelationshipDefinition('Pack:Entities'),
                    EntityType: this.getRelationshipDefinition('Pack:EntityType'),
                    Field: this.getRelationshipDefinition('Pack:EntityField'),
                    RelationshipDefinition: this.getRelationshipDefinition('Pack:EntityRelationshipDefinition')
                },
                Field:
                {
                    Name: this.getField('Pack', 'Pack.Name'),
                    Description: this.getField('Pack', 'Pack.Description'),
                    Price: this.getField('Pack', 'Pack.Price'),
                    Code: this.getField('Pack', 'Pack.Code')
                },
                System:
                {
                    Type: this.getType('Pack.System')
                },
                Application:
                {
                    Type: this.getType('Pack.Application')
                },
                Environment:
                {
                    Type: this.getType('Pack.Environment'),
                    RelationshipDefinition:
                    {
                        Environment: this.getRelationshipDefinition('Pack.Environment:Relation.Organization.Environment')
                    }
                },
                Share:
                {
                    Type: this.getType('Pack.Share')
                },
                Data:
                {
                    Type: this.getType('Pack.Data')
                }
            },
            Activity:
            {
                Type: this.getType('Activity'),
                RelationshipDefinition:
                {
                    Relationship: this.getRelationshipDefinition('Relationship:Activities'),
                    Contact: this.getRelationshipDefinition('Relationship.Person.Contact:Activities'),
                    LinkedActivities: this.getRelationshipDefinition('Activity:LinkedActivities'),
                    Involved: this.getRelationshipDefinition('Activity:Involved'),
                    ProductLines: this.getRelationshipDefinition('Activity:ProductLines'),
                    TimeRegistrations: this.getRelationshipDefinition('Activity:TimeRegistrations'),
                    MileageRegistrations: this.getRelationshipDefinition('Activity:MileageRegistrations'),
                    PriceList: this.getRelationshipDefinition('Activity:PriceList'),
                    EmployeeAllocations: this.getRelationshipDefinition('Activity:EmployeeAllocations'),
                },
                Field:
                {
                    Subject: this.getField('Activity', 'Activity.Subject'),
                    Number: this.getField('Activity', 'Activity.Number'),
                    NumberOfPlannedActions: this.getField('Activity', 'Activity.NumberOfPlannedActions'),
                    Amount: this.getField('Activity', 'Activity.Amount'),
                    NumberOfProductLines: this.getField('Activity', 'Activity.NumberOfProductLines'),
                    IsVatIncluded: this.getField('Activity', 'Activity.IsVatIncluded'),
                    DiscountPercentage: this.getField('Activity', 'Activity.DiscountPercentage'),
                    TotalSalesExcludingVat: this.getField('Activity', 'Activity.TotalSalesExcludingVAT'),
                    TotalSalesIncludingVat: this.getField('Activity', 'Activity.TotalSalesIncludingVAT'),
                    BillingAmount: this.getField('Activity', 'Activity.BillingAmount'),
                    TotalDiscountExcludingVat: this.getField('Activity', 'Activity.TotalDiscountExcludingVAT'),
                    VatAmountIncludingDiscount: this.getField('Activity', 'Activity.VatAmountIncludingDiscount'),

                    TotalNonRecurringSalesExcludingVat: this.getField('Activity', 'Activity.TotalNonRecurringSalesExcludingVAT'),
                    TotalNonRecurringSalesExcludingVatInCurrency: this.getField('Activity', 'Activity.TotalNonRecurringSalesExcludingVATInCurrency'),
                    TotalNonRecurringSalesIncludingVat: this.getField('Activity', 'Activity.TotalNonRecurringSalesIncludingVAT'),
                    TotalNonRecurringSalesIncludingVatInCurrency: this.getField('Activity', 'Activity.TotalNonRecurringSalesIncludingVATInCurrency'),
                    TotalNonRecurringDiscountExcludingVat: this.getField('Activity', 'Activity.TotalNonRecurringDiscountExcludingVAT'),
                    TotalNonRecurringDiscountExcludingVatInCurrency: this.getField('Activity', 'Activity.TotalNonRecurringDiscountExcludingVATInCurrency'),
                    TotalNonRecurringPurchaseAmount: this.getField('Activity', 'Activity.TotalNonRecurringPurchaseAmount'),
                    TotalNonRecurringVatAmount: this.getField('Activity', 'Activity.TotalNonRecurringVatAmount'),
                    TotalNonRecurringVatAmountInCurrency: this.getField('Activity', 'Activity.TotalNonRecurringVatAmountInCurrency'),
                    TotalNonRecurringMargin: this.getField('Activity', 'Activity.TotalNonRecurringMargin'),

                    TotalRecurringSalesExcludingVat: this.getField('Activity', 'Activity.TotalRecurringSalesExcludingVAT'),
                    TotalRecurringSalesExcludingVatInCurrency: this.getField('Activity', 'Activity.TotalRecurringSalesExcludingVATInCurrency'),
                    TotalRecurringSalesIncludingVat: this.getField('Activity', 'Activity.TotalRecurringSalesIncludingVAT'),
                    TotalRecurringSalesIncludingVatInCurrency: this.getField('Activity', 'Activity.TotalRecurringSalesIncludingVATInCurrency'),
                    TotalRecurringDiscountExcludingVat: this.getField('Activity', 'Activity.TotalRecurringDiscountExcludingVAT'),
                    TotalRecurringDiscountExcludingVatInCurrency: this.getField('Activity', 'Activity.TotalRecurringDiscountExcludingVATInCurrency'),
                    TotalRecurringPurchaseAmount: this.getField('Activity', 'Activity.TotalRecurringPurchaseAmount'),
                    TotalRecurringVatAmount: this.getField('Activity', 'Activity.TotalRecurringVatAmount'),
                    TotalRecurringVatAmountInCurrency: this.getField('Activity', 'Activity.TotalRecurringVatAmountInCurrency'),
                    TotalRecurringMargin: this.getField('Activity', 'Activity.TotalRecurringMargin'),

                    Currency: this.getField('Activity', 'Activity.Currency'),
                    AmountInCurrency: this.getField('Activity', 'Activity.AmountInCurrency'),
                    BillingAmountInCurrency: this.getField('Activity', 'Activity.BillingAmountInCurrency'),
                    TotalDiscountExcludingVatInCurrency: this.getField('Activity', 'Activity.TotalDiscountExcludingVATInCurrency'),
                    TotalSalesExcludingVatInCurrency: this.getField('Activity', 'Activity.TotalSalesExcludingVATInCurrency'),
                    TotalSalesIncludingVatInCurrency: this.getField('Activity', 'Activity.TotalSalesIncludingVATInCurrency'),
                    VatAmountIncludingDiscountInCurrency: this.getField('Activity', 'Activity.VatAmountIncludingDiscountInCurrency'),
                    VatAmountInCurrency: this.getField('Activity', 'Activity.VatAmountInCurrency'),
                },
                SalesOpportunity:
                {
                    Type: this.getType('Activity.SalesOpportunity'),
                    RelationshipDefinition:
                    {
                        Pipeline: this.getRelationshipDefinition('Activity.SalesOpportunity:Pipeline'),
                        Phase: this.getRelationshipDefinition('Activity.SalesOpportunity:Phase'),
                        SalesRepresentative: this.getRelationshipDefinition('Activity.SalesOpportunity:SalesRepresentative'),
                        LostToCompetitor: this.getRelationshipDefinition('Activity.SalesOpportunity:LostToCompetitor'),
                        LostReason: this.getRelationshipDefinition('Activity.SalesOpportunity:LostReason')
                    },
                    Field:
                    {
                        Probability: this.getField('Activity.SalesOpportunity', 'Activity.SalesOpportunity.Probability'),
                        WeightedAmount: this.getField('Activity', 'Activity.SalesOpportunity.WeightedAmount'),
                        EstimatedAmount: this.getField('Activity.SalesOpportunity', 'Activity.SalesOpportunity.EstimatedAmount'),
                        ExpectedOrderDate: this.getField('Activity.SalesOpportunity', 'Activity.SalesOpportunity.ExpectedOrderDate')
                    }
                },
                SalesOrder:
                {
                    Type: this.getType('Activity.SalesOrder'),
                    RelationshipDefinition:
                    {
                        Phase: this.getRelationshipDefinition('Activity.SalesOrder:Phase')
                    }
                },
                ApsisForm:
                {
                    Type: this.getType('Activity.ApsisForm'),
                    RelationshipDefinition:
                    {
                        Connector: this.getRelationshipDefinition('Activity.ApsisForm:Connector'),
                        Results: this.getRelationshipDefinition('Activity.ApsisForm:ApsisFormResults'),
                    }
                },
                ApsisFormResult:
                {
                    Type: this.getType('Activity.ApsisFormResult'),
                    Field:
                    {
                        FormData: this.getField('Activity.ApsisFormResult', 'Activity.ApsisFormResult.FormData'),
                        SubmitDate: this.getField('Activity.ApsisFormResult', 'Activity.ApsisFormResult.SubmitDate')
                    }
                },
                ApsisCampaign:
                {
                    Type: this.getType('Activity.ApsisCampaign'),
                    Field:
                    {
                        RootType: this.getField('Activity.ApsisCampaign', 'Activity.ApsisCampaign.RootType'),
                        RelationshipPath: this.getField('Activity.ApsisCampaign', 'Activity.ApsisCampaign.RelationshipPath'),
                    },
                    RelationshipDefinition:
                    {
                        Phase: this.getRelationshipDefinition('Activity.ApsisCampaign:Phase'),
                        Results: this.getRelationshipDefinition('Activity.ApsisCampaign:ApsisCampaignResults'),
                    },
                    State:
                    {
                        Concept: 'Concept',
                        Planned: 'Planned',
                        InProgress: 'InProgress'
                    }
                },
                ApsisCampaignResult:
                {
                    Type: this.getType('Activity.ApsisCampaignResult'),
                    Field:
                    {
                        SendDate: this.getField('Activity.ApsisCampaignResult', 'Activity.ApsisCampaignResult.SendDate'),
                        IsBounced: this.getField('Activity.ApsisCampaignResult', 'Activity.ApsisCampaignResult.IsBounced'),
                        IsViewed: this.getField('Activity.ApsisCampaignResult','Activity.ApsisCampaignResult.IsViewed'),
                        IsClicked: this.getField('Activity.ApsisCampaignResult','Activity.ApsisCampaignResult.IsClicked'),
                    },
                    RelationshipDefinition:
                    {
                        Events: this.getRelationshipDefinition('Activity.ApsisCampaignResult:ApsisCampaignEvents')
                    }
                },
                Campaign:
                {
                    Type: this.getType('Activity.Campaign'),
                    RelationshipDefinition:
                    {
                        Phase: this.getRelationshipDefinition('Activity.Campaign:Phase'),
                        Email: this.getRelationshipDefinition('Activity.Campaign:Email'),
                        EmailingCategory: this.getRelationshipDefinition('Activity.Campaign:EmailingCategory')
                    },
                    Field:
                    {
                        SenderName: this.getField('Activity.Campaign', 'Activity.Campaign.SenderName'),
                        SenderEmailAddress: this.getField('Activity.Campaign', 'Activity.Campaign.SenderEmailAddress'),
                        UseAccountManagerAsSender: this.getField('Activity.Campaign', 'Activity.Campaign.UseAccountManagerAsSender'),
                        EmailSubject: this.getField('Activity.Campaign', 'Activity.Campaign.EmailSubject'),
                        DateScheduled: this.getField('Activity.Campaign', 'Activity.Campaign.DateScheduled'),
                        DateDispatched: this.getField('Activity.Campaign', 'Activity.Campaign.DateDispatched'),
                        Template: this.getField('Activity.Campaign', 'Activity.Campaign.Template'),
                        RootType: this.getField('Activity.Campaign', 'Activity.Campaign.RootType'),
                        RelationshipPath: this.getField('Activity.Campaign', 'Activity.Campaign.RelationshipPath'),
                        SelectionFilter: this.getField('Activity.Campaign', 'Activity.Campaign.SelectionFilter'),
                        ManualSelection: this.getField('Activity.Campaign', 'Activity.Campaign.ManualSelection'),
                        Font: this.getField('Activity.Campaign', 'Activity.Campaign.Font')
                    },
                    Phase:
                    {
                        Concept: 'Concept',
                        Planned: 'Planned',
                        InProgress: 'InProgress',
                        Completed: 'Completed'
                    }
                },
                SpotlerCampaign:
                {
                    Type: this.getType('Activity.SpotlerCampaign'),
                    RelationshipDefinition:
                    {
                        Results: this.getRelationshipDefinition('Activity.SpotlerCampaign:SpotlerCampaignResults'),
                        Phase: this.getRelationshipDefinition('Activity.SpotlerCampaign:Phase'),
                        Steps: this.getRelationshipDefinition('Activity.SpotlerCampaign:SpotlerCampaignSteps')
                    },
                    Field:
                    {
                        RootType: this.getField('Activity.SpotlerCampaign', 'Activity.SpotlerCampaign.RootType'),
                        RelationshipPath: this.getField('Activity.SpotlerCampaign', 'Activity.SpotlerCampaign.RelationshipPath'),
                        SelectionFilter: this.getField('Activity.SpotlerCampaign', 'Activity.SpotlerCampaign.SelectionFilter'),
                        ManualSelection: this.getField('Activity.SpotlerCampaign', 'Activity.SpotlerCampaign.ManualSelection'),
                        Configuration: this.getField('Activity.SpotlerCampaign', 'Activity.SpotlerCampaign.Configuration'),
                    },
                    State:
                    {
                        Concept: 'Concept',
                        Planned: 'Planned',
                        InProgress: 'InProgress'
                    }
                },
                SpotlerCampaignResult:
                {
                    Type: this.getType('Activity.SpotlerCampaignResult'),
                    Field:
                        {
                            SendDate: this.getField('Activity.SpotlerCampaignResult', 'Activity.SpotlerCampaignResult.SendDate'),
                            IsViewed: this.getField('Activity.SpotlerCampaignResult','Activity.SpotlerCampaignResult.IsViewed'),
                            IsClicked: this.getField('Activity.SpotlerCampaignResult','Activity.SpotlerCampaignResult.IsClicked'),
                            IsBounced: this.getField('Activity.SpotlerCampaignResult', 'Activity.SpotlerCampaignResult.IsBounced'),
                        },
                    RelationshipDefinition:
                        {
                            Events: this.getRelationshipDefinition('Activity.SpotlerCampaignResult:SpotlerCampaignEvents')
                        }
                },
                Appointment:
                {
                    Type: this.getType('Activity.Appointment'),
                    RelationshipDefinition:
                    {
                        Phase: this.getRelationshipDefinition('Activity.Appointment:Phase'),
                        Owner: this.getRelationshipDefinition('Activity.Appointment:Owner'),
                        CalendarItem: this.getRelationshipDefinition('Activity.Appointment:CalendarItem'),
                        AppointmentType: this.getRelationshipDefinition('Activity.Appointment:AppointmentType'),
                        Attendees: this.getRelationshipDefinition('Activity.Appointment:Attendees'),
                        Address: this.getRelationshipDefinition('Activity.Appointment:Address'),
                        TimeRegistrationActivity: this.getRelationshipDefinition('Activity.Appointments:TimeRegistrationActivity')
                    },
                    Field:
                    {
                        StartDate: this.getField('Activity.Appointment', 'Activity.Appointment.StartDate'),
                        EndDate: this.getField('Activity.Appointment', 'Activity.Appointment.EndDate'),
                        IsRecurring: this.getField('Activity.Appointment', 'Activity.Appointment.IsRecurring'),
                        RecurrenceStartDate: this.getField('Activity.Appointment', 'Activity.Appointment.RecurrenceStartDate'),
                        RecurrenceEndDate: this.getField('Activity.Appointment', 'Activity.Appointment.RecurrenceEndDate'),
                        RecurrencePattern: this.getField('Activity.Appointment', 'Activity.Appointment.RecurrencePattern'),
                        Description: this.getField('Activity.Appointment', 'Activity.Appointment.Description'),
                        Location: this.getField('Activity.Appointment', 'Activity.Appointment.Location'),
                        HasTimeRegistration: this.getField('Activity.Appointment', 'Activity.Appointment.HasTimeRegistration')
                    }
                },
                Task:
                {
                    Type: this.getType('Activity.Task'),
                    RelationshipDefinition:
                    {
                        Type: this.getRelationshipDefinition('Activity.Task:Type'),
                        Phase: this.getRelationshipDefinition('Activity.Task:Phase'),
                        Assignee: this.getRelationshipDefinition('Activity.Task:Assignee'),
                        CalendarItem: this.getRelationshipDefinition('Activity.Task:CalendarItem'),
                        TimeRegistrationActivity: this.getRelationshipDefinition('Activity.Tasks:TimeRegistrationActivity')
                    },
                    Field:
                    {
                        StartDate: this.getField('Activity.Task', 'Activity.Task.StartDate'),
                        EndDate: this.getField('Activity.Task', 'Activity.Task.EndDate'),
                        HasCalendarItem: this.getField('Activity.Task', 'Activity.Task.HasCalendarItem'),
                        HasTimeRegistration: this.getField('Activity.Task', 'Activity.Task.HasTimeRegistration')
                    }
                },
                Project:
                {
                    Type: this.getType('Activity.Project'),
                    RelationshipDefinition:
                    {
                        Phase: this.getRelationshipDefinition('Activity.Project:Phase'),
                        Owner: this.getRelationshipDefinition('Activity.Project:Owner'),
                        Milestones: this.getRelationshipDefinition('Activity.Project:Milestones'),
                        ExternalCosts: this.getRelationshipDefinition('Activity.Project:ExternalCosts'),
                        Members: this.getRelationshipDefinition('Activity.Project:Members')
                    },
                    Field:
                    {
                        Budget: this.getField('Activity.Project', 'Activity.Project.Budget'),
                        HourBudget: this.getField('Activity.Project', 'Activity.Project.HourBudget'),
                        StartDate: this.getField('Activity.Project', 'Activity.Project.StartDate'),
                        EndDate: this.getField('Activity.Project', 'Activity.Project.EndDate'),
                    }
                },
                Email:
                {
                    Type: this.getType('Activity.Email'),
                    RelationshipDefinition:
                    {
                        Phase: this.getRelationshipDefinition('Activity.Email:Phase'),
                        From: this.getRelationshipDefinition('Activity.Email:From'),
                        To: this.getRelationshipDefinition('Activity.Email:To'),
                        CC: this.getRelationshipDefinition('Activity.Email:CC'),
                        BCC: this.getRelationshipDefinition('Activity.Email:BCC'),
                        EmailsInThread: this.getRelationshipDefinition('Activity.Email:EmailsInThread'),
                        Replies: this.getRelationshipDefinition('Activity.Email:Replies'),
                        Forwards: this.getRelationshipDefinition('Activity.Email:Forwards'),
                        Layout: this.getRelationshipDefinition('Activity.Email:Layout'),
                        Resources: this.getRelationshipDefinition('Activity.Email:Resources'),
                        Clicks: this.getRelationshipDefinition('Activity.Email:Clicks')
                    },
                    Field:
                    {
                        Content: this.getField('Activity.Email', 'Activity.Email.Content'),
                        HTML: this.getField('Activity.Email', 'Activity.Email.HtmlContent'),
                        TextContent: this.getField('Activity.Email', 'Activity.Email.TextContent'),
                        IsDraft: this.getField('Activity.Email', 'Activity.Email.IsDraft'),
                        SendDate: this.getField('Activity.Email', 'Activity.Email.SendDate'),
                        IsHardBounced: this.getField('Activity.Email', 'Activity.Email.IsHardBounced')
                    },
                    Workflow: 'Activity.Email.Default',
                    State:
                    {
                        Concept: 'Concept',
                        Sent: 'Sent',
                        Delivered: 'Delivered',
                        Blocked: 'Blocked',
                        Bounced: 'Bounced',
                        Clicked: 'Clicked',
                        Opened: 'Opened',
                        Spam: 'Spam',
                        Unsubscribed: 'Unsubscribed',
                        Received: 'Received'
                    }
                },
                Offer:
                {
                    Type: this.getType('Activity.Offer'),
                    Field:
                    {
                        Phase: this.getRelationshipDefinition('Activity.Offer:Phase'),
                        Version: this.getField('Activity.Offer', 'Activity.Offer.Version'),
                        Reference: this.getField('Activity.Offer', 'Activity.Offer.Reference'),
                        Content: this.getField('Activity.Offer', 'Activity.Offer.Content'),
                        TemplateFile: this.getField('Activity.Offer', 'Activity.Offer.TemplateFile'),
                        RespondentFirstName: this.getField('Activity.Offer', 'Activity.Offer.RespondentFirstName'),
                        RespondentInsertion: this.getField('Activity.Offer', 'Activity.Offer.RespondentInsertion'),
                        RespondentLastName: this.getField('Activity.Offer', 'Activity.Offer.RespondentLastName'),
                        RespondentEmailAddress: this.getField('Activity.Offer', 'Activity.Offer.RespondentEmailAddress'),
                        RespondentIpAddress: this.getField('Activity.Offer', 'Activity.Offer.RespondentIpAddress'),
                        RespondentNote: this.getField('Activity.Offer', 'Activity.Offer.RespondentNote'),
                        ExpirationDate: this.getField('Activity.Offer', 'Activity.Offer.ExpirationDate')
                    },
                    RelationshipDefinition:
                    {
                        Phase: this.getRelationshipDefinition('Activity.Offer:Phase'),
                        Template: this.getRelationshipDefinition('Activity.Offer:Template'),
                        DeprecatedTemplate: this.getRelationshipDefinition('Activity.Offer:DeprecatedTemplate'),
                        Layout: this.getRelationshipDefinition('Activity.Offer:Layout'),
                        FinalizedDocumentAttachment: this.getRelationshipDefinition('Activity.Offer:FinalizedDocumentAttachment'),
                        SignedOfferAttachment: this.getRelationshipDefinition('Activity.Offer:SignedOfferAttachment')
                    }
                },
                Invoice:
                {
                    Type: this.getType('Activity.Invoice'),
                    RelationshipDefinition:
                    {
                        Phase: this.getRelationshipDefinition('Activity.Invoice:Phase'),
                        Template: this.getRelationshipDefinition('Activity.Invoice:Template'),
                        DocumentAttachment: this.getRelationshipDefinition('Activity.Invoice:DocumentAttachment'),
                        SendToAlternativeRelationship: this.getRelationshipDefinition("Relationship.SendToAlternativeRelationship:AlternativeRelationshipInInvoices"),
                        SendToAlternativeContact: this.getRelationshipDefinition("Relationship.Person.Contact.SendToAlternativeContact:AlternativeContactInInvoices"),
                    },
                    Field:
                    {
                        Date: this.getField('Activity.Invoice', 'Activity.Invoice.Date'),
                        ExpirationDate: this.getField('Activity.Invoice', 'Activity.Invoice.ExpirationDate'),
                        PaymentTermInDays: this.getField('Activity.Invoice', 'Activity.Invoice.PaymentTermInDays'),
                        Document: this.getField('Activity.Invoice', 'Activity.Invoice.Document'),
                        TemplateFile: this.getField('Activity.Invoice', 'Activity.Invoice.TemplateFile'),
                        PeriodStartDate: this.getField('Activity.Invoice', 'Activity.Invoice.PeriodStartDate'),
                        PeriodEndDate: this.getField('Activity.Invoice', 'Activity.Invoice.PeriodEndDate'),
                        IsFinalized: this.getField('Activity.Invoice', 'Activity.Invoice.IsFinalized'),
                        DocumentAttachmentHash: this.getField('Activity.Invoice', 'Activity.Invoice.DocumentAttachmentHash'),
                        IsVatIncluded: this.getField('Activity.Invoice', 'Activity.Invoice.IsVatIncluded'),
                    },
                    Phase:
                    {
                        Concept: 'Concept'
                    }
                },
                Subscription:
                {
                    Type: this.getType('Activity.Subscription'),
                    RelationshipDefinition:
                    {
                        Phase: this.getRelationshipDefinition('Activity.Subscription:Phase'),
                        InvoiceEmailTemplate: this.getRelationshipDefinition('Activity.Subscription:InvoiceEmailTemplate'),
                        SendToAlternativeRelationship: this.getRelationshipDefinition("Relationship.SendToAlternativeRelationship:AlternativeRelationshipInSubscriptions"),
                        SendToAlternativeContact: this.getRelationshipDefinition("Relationship.Person.Contact.SendToAlternativeContact:AlternativeContactInSubscriptions"),
                    },
                    Field:
                    {
                        Interval: this.getField('Activity.Subscription', 'Activity.Subscription.Interval'),
                        StartDate: this.getField('Activity.Subscription', 'Activity.Subscription.StartDate'),
                        InvoicingStartDate: this.getField('Activity.Subscription', 'Activity.Subscription.InvoicingStartDate'),
                        StartDateOfSecondPeriod: this.getField('Activity.Subscription', 'Activity.Subscription.StartDateOfSecondPeriod'),
                        EndDate: this.getField('Activity.Subscription', 'Activity.Subscription.EndDate'),
                        DoSendAutomatically: this.getField('Activity.Subscription', 'Activity.Subscription.DoSendInvoicesAutomatically'),
                        InvoiceEmailAddress: this.getField('Activity.Subscription', 'Activity.Subscription.InvoiceEmailAddress'),
                        InvoiceCcEmailAddress: this.getField('Activity.Subscription', 'Activity.Subscription.InvoiceCcEmailAddress'),
                        AdvanceBillingPeriod: this.getField('Activity.Subscription', 'Activity.Subscription.AdvanceBillingPeriod'),
                    },
                    Phase:
                    {
                        AwaitingActivation: 'AwaitingActivation',
                        Activated: 'Activated',
                        Deactivated: 'Deactivated'
                    }
                },
                Document:
                {
                    Type: this.getType('Activity.Document'),
                    RelationshipDefinition:
                    {
                        Phase: this.getRelationshipDefinition('Activity.Document:Phase'),
                        Template: this.getRelationshipDefinition('Activity.Document:Template'),
                        DocumentAttachment: this.getRelationshipDefinition('Activity.Document:DocumentAttachment')
                    },
                    Field:
                    {
                        Date: this.getField('Activity.Document', 'Activity.Document.Date'),
                        Reference: this.getField('Activity.Document', 'Activity.Document.Reference'),
                        Content: this.getField('Activity.Document', 'Activity.Document.Content'),
                        TemplateFile: this.getField('Activity.Document', 'Activity.Document.TemplateFile')
                    }
                },
                WorkOrder:
                {
                    Type: this.getType('Activity.WorkOrder'),
                    RelationshipDefinition:
                    {
                        Phase: this.getRelationshipDefinition('Activity.WorkOrder:Phase'),
                        Employee: this.getRelationshipDefinition('Activity.WorkOrder:Employee'),
                        Template: this.getRelationshipDefinition('Activity.WorkOrder:Template'),
                        CalendarItem: this.getRelationshipDefinition('Activity.WorkOrder:CalendarItem'),
                        Members: this.getRelationshipDefinition('Activity.WorkOrder:Members'),
                        Address: this.getRelationshipDefinition('Activity.WorkOrder:Address')
                    },
                    Field:
                    {
                        Date: this.getField('Activity.WorkOrder', 'Activity.WorkOrder.Date'),
                        StartDate: this.getField('Activity.WorkOrder', 'Activity.WorkOrder.StartDate'),
                        EndDate: this.getField('Activity.WorkOrder', 'Activity.WorkOrder.EndDate'),
                        Document: this.getField('Activity.WorkOrder', 'Activity.WorkOrder.Document'),
                        TemplateFile: this.getField('Activity.WorkOrder', 'Activity.WorkOrder.TemplateFile'),
                        FinalizedDocument: this.getField('Activity.WorkOrder', 'Activity.WorkOrder.FinalizedDocument'),
                        SignedDocument: this.getField('Activity.WorkOrder', 'Activity.WorkOrder.SignedDocument'),
                        RespondentTitle: this.getField('Activity.WorkOrder', 'Activity.WorkOrder.RespondentTitle'),
                        RespondentFirstName: this.getField('Activity.WorkOrder', 'Activity.WorkOrder.RespondentFirstName'),
                        RespondentInsertion: this.getField('Activity.WorkOrder', 'Activity.WorkOrder.RespondentInsertion'),
                        RespondentLastName: this.getField('Activity.WorkOrder', 'Activity.WorkOrder.RespondentLastName'),
                        RespondentEmailAddress: this.getField('Activity.WorkOrder', 'Activity.WorkOrder.RespondentEmailAddress'),
                        RespondentIpAddress: this.getField('Activity.WorkOrder', 'Activity.WorkOrder.RespondentIpAddress'),
                        RespondentNote: this.getField('Activity.WorkOrder', 'Activity.WorkOrder.RespondentNote'),
                        HasCalendarItem: this.getField('Activity.WorkOrder', 'Activity.WorkOrder.HasCalendarItem')
                    }
                },
                SupportTicket:
                {
                    Type: this.getType('Activity.SupportTicket'),
                    RelationshipDefinition:
                    {
                        Phase: this.getRelationshipDefinition('Activity.SupportTicket:Phase')
                    }
                },
                Event:
                {
                    Type: this.getType('Activity.Event'),
                    RelationshipDefinition:
                    {
                        Phase: this.getRelationshipDefinition('Activity.Event:Phase'),
                        Owner: this.getRelationshipDefinition('Activity.Event:Owner'),
                        Participations: this.getRelationshipDefinition('Activity.Event:Participations'),
                        CalendarItem: this.getRelationshipDefinition('Activity.Event:CalendarItem'),
                        EventLocation: this.getRelationshipDefinition('Activity.Event:Datastore.EventLocation')
                    },
                    Field:
                    {
                        StartDate: this.getField('Activity.Event', 'Activity.Event.StartDate'),
                        EndDate: this.getField('Activity.Event', 'Activity.Event.EndDate'),
                        HasCalendarItem: this.getField('Activity.Event', 'Activity.Event.HasCalendarItem')
                    }
                },
                DigitalSigning:
                {
                    Type: this.getType('Activity.DigitalSigning'),
                    RelationshipDefinition:
                        {
                            Phase: this.getRelationshipDefinition('Activity.DigitalSigning:Datastore.Phase.DigitalSigning'),
                            Signers: this.getRelationshipDefinition('Activity.DigitalSigning:DigitalSigner'),
                            ActivityToSign: this.getRelationshipDefinition('Activity.DigitalSigning:ActivityToSign'),
                            DocumentToSign: this.getRelationshipDefinition('Activity.DigitalSigning:DocumentToSign')
                        },
                    Field:
                        {
                            IsFixedSigningOrder: this.getField('Activity.DigitalSigning', 'Activity.DigitalSigning.IsFixedSigningOrder')
                        }
                },
                Course:
                {
                    Type: this.getType('Activity.Course'),
                    Field:
                        {
                            StartDate: this.getField('Activity.Course', 'Activity.Course.StartDate'),
                            EndDate: this.getField('Activity.Course', 'Activity.Course.EndDate'),
                            NumberOfLessons: this.getField('Activity.Course', 'Activity.Course.NumberOfLessons'),
                            LessonDuration: this.getField('Activity.Course', 'Activity.Course.LessonDuration'),
                            LessonCronPattern: this.getField('Activity.Course', 'Activity.Course.LessonCronPattern'),
                            Price: this.getField('Activity.Course', 'Activity.Course.Price'),
                            MaxNumberOfAttendees: this.getField('Activity.Course', 'Activity.Course.MaxNumberOfAttendees'),
                            NumberOfAttendees: this.getField('Activity.Course', 'Activity.Course.NumberOfAttendees'),
                            IsRegistrationPerLesson: this.getField('Activity.Course', 'Activity.Course.IsRegistrationPerLesson'),
                            DoLessonsHaveCalendarItem: this.getField('Activity.Course', 'Activity.Course.DoLessonsHaveCalendarItem'),
                        },
                    RelationshipDefinition:
                        {
                            CourseTemplate: this.getRelationshipDefinition('Activity.Course:CourseTemplate'),
                            CourseLocation: this.getRelationshipDefinition('Activity.Course:CourseLocation'),
                            CourseInstructor: this.getRelationshipDefinition('Activity.Course:CourseInstructor'),
                            Phase: this.getRelationshipDefinition('Activity.Course:Phase'),
                            CourseFiles: this.getRelationshipDefinition('Activity.Course:CourseFiles'),
                            Attendances: this.getRelationshipDefinition('Activity.Course:Attendances'),
                            Lessons: this.getRelationshipDefinition('Activity.Course:Lessons'),
                        },
                },
                CourseAttendance:
                {
                    Type: this.getType('Activity.CourseAttendance'),
                    Field:
                    {

                    },
                    RelationshipDefinition:
                    {
                        Phase: this.getRelationshipDefinition('Activity.CourseAttendance:Phase'),
                        CourseLessonRegistrations: this.getRelationshipDefinition('Activity.CourseAttendance:CourseLessonRegistrations'),
                    },
                },
                CourseLessonRegistration:
                {
                    Type: this.getType('Activity.CourseLessonRegistration'),
                    Field:
                    {

                    },
                    RelationshipDefinition:
                    {

                    },
                },
                CourseLessonAttendance:
                {
                    Type: this.getType('Activity.CourseLessonAttendance'),
                    Field:
                        {
                            IsPresent: this.getField('Activity.CourseLessonAttendance', 'Activity.CourseLessonAttendance.IsPresent'),
                        },
                    RelationshipDefinition:
                        {
                            Phase: this.getRelationshipDefinition('Activity.CourseLessonAttendance:Phase'),
                            CourseAttendance: this.getRelationshipDefinition('Activity.CourseLessonAttendance:CourseAttendance'),
                        },
                },

            }, // end activity
            DigitalSigner:
            {
                Type: this.getType('DigitalSigner'),
                RelationshipDefinition:
                    {
                        Phase: this.getRelationshipDefinition('DigitalSigner:Datastore.Phase.DigitalSigner'),
                        Relationship: this.getRelationshipDefinition('DigitalSigner:Relationship'),
                    },
                Field:
                    {
                        FirstName: this.getField('DigitalSigner', 'DigitalSigner.FirstName'),
                        LastName: this.getField('DigitalSigner', 'DigitalSigner.LastName'),
                        EmailAddress: this.getField('DigitalSigner', 'DigitalSigner.EmailAddress'),
                        SignOrderIndex: this.getField('DigitalSigner', 'DigitalSigner.SignOrderIndex'),
                        CanChangeSigner: this.getField('DigitalSigner', 'DigitalSigner.CanChangeSigner'),
                    }
            },
            ProductLine:
            {
                Type: this.getType('ProductLine'),
                RelationshipDefinition:
                {
                    Product: this.getRelationshipDefinition('ProductLine:Product'),
                    VatGroup: this.getRelationshipDefinition('ProductLine:Datastore.VatGroup'),
                    InvoiceProductLine: this.getRelationshipDefinition('ProductLine:InvoiceProductLine'),
                    BilledTimeRegistrations: this.getRelationshipDefinition('ProductLine.BillingLine:BilledTimeRegistrations'),
                    BilledMileageRegistrations: this.getRelationshipDefinition('ProductLine.BillingLine:BilledMileageRegistrations'),
                },
                Field:
                {
                    Quantity: this.getField('ProductLine', 'ProductLine.Quantity'),
                    VatPercentage: this.getField('ProductLine', 'ProductLine.VatPercentage'),
                    PurchasePrice: this.getField('ProductLine', 'ProductLine.PurchasePrice'),
                    TotalPurchasePrice: this.getField('ProductLine', 'ProductLine.TotalPurchasePrice'),
                    Description: this.getField('ProductLine', 'ProductLine.Description'),
                    LocalizedDescription: this.getField('ProductLine', 'ProductLine.LocalizedDescription'),
                    TotalExcludingVat: this.getField('ProductLine', 'ProductLine.TotalExcludingVat'),
                    TotalExcludingVatInCurrency: this.getField('ProductLine', 'ProductLine.TotalExcludingVatInCurrency'),
                    TotalIncludingVat: this.getField('ProductLine', 'ProductLine.TotalIncludingVat'),
                    TotalIncludingVatInCurrency: this.getField('ProductLine', 'ProductLine.TotalIncludingVatInCurrency'),
                    Price: this.getField('ProductLine', 'ProductLine.Price'),
                    IsPriceManuallyChanged: this.getField('ProductLine', 'ProductLine.IsPriceManuallyChanged'),
                    PriceInCurrency: this.getField('ProductLine', 'ProductLine.PriceInCurrency'),
                    DiscountPercentage: this.getField('ProductLine', 'ProductLine.DiscountPercentage'),
                    Total: this.getField('ProductLine', 'ProductLine.Total'),
                    TotalInCurrency: this.getField('ProductLine', 'ProductLine.TotalInCurrency'),
                    RepeatInterval: this.getField('ProductLine', 'ProductLine.RepeatInterval'),
                    IsBilled: this.getField('ProductLine', 'ProductLine.IsBilled'),
                    IsProcessableInParent: this.getField('ProductLine', 'ProductLine.IsProcessableInParent'),
                    StartDate: this.getField('ProductLine', 'ProductLine.StartDate'),
                    EndDate: this.getField('ProductLine', 'ProductLine.EndDate'),
                    BilledUntil: this.getField('ProductLine', 'ProductLine.BilledUntil'),
                    ExtraDescription: this.getField('ProductLine', 'ProductLine.ExtraDescription'),
                    Currency: this.getField('ProductLine', 'ProductLine.Currency'),
                    VatAmountIncludingDiscountInCurrency: this.getField('ProductLine', 'ProductLine.VatAmountIncludingDiscountInCurrency'),
                    VatAmountInCurrency: this.getField('ProductLine', 'ProductLine.VatAmountInCurrency'),
                    VatAmount: this.getField('ProductLine', 'ProductLine.VatAmount')
                }
            },
            ProductGroup:
            {
                Type: this.getType('ProductGroup'),
                Field:
                {
                    Name: this.getField('ProductGroup', 'ProductGroup.Name')
                },
                RelationshipDefinition:
                {
                    Products: this.getRelationshipDefinition('ProductGroup:Products')
                }
            },
            Product:
            {
                Type: this.getType('Product'),
                Field:
                {
                    Code: this.getField('Product', 'Product.Code'),
                    Name: this.getField('Product', 'Product.Name'),
                    LocalizedName: this.getField('Product', 'Product.LocalizedName'),
                    Description: this.getField('Product', 'Product.Description'),
                    IsDescriptionEditable: this.getField('Product', 'Product.IsDescriptionEditable'),
                    IsPriceEditable: this.getField('Product', 'Product.IsPriceEditable'),
                    IsVatEditable: this.getField('Product', 'Product.IsVatEditable'),
                    IsVatIncluded: this.getField('Product', 'Product.IsVatIncluded'),
                    IsInactive: this.getField('Product', 'Product.IsInactive'),
                    PurchasePrice: this.getField('Product', 'Product.PurchasePrice'),
                    SalesPrice: this.getField('Product', 'Product.SalesPrice'),
                    RepeatInterval: this.getField('Product', 'Product.RepeatInterval'),
                    StockLevel: this.getField('Product', 'Product.StockLevel')
                },
                RelationshipDefinition:
                {
                    VatGroup: this.getRelationshipDefinition('Product:Datastore.VatGroup'),
                    Unit: this.getRelationshipDefinition('Product:Datastore.Unit')
                }
            },
            PriceList:
            {
                Type: this.getType('PriceList'),
                Field:
                {
                    Name: this.getField('PriceList', 'PriceList.Name'),
                    Currency: this.getField('PriceList', 'PriceList.Currency'),
                    IsDefaultForCurrency: this.getField('PriceList', 'PriceList.IsDefaultForCurrency'),
                    IsExcludedFromPriceSelection: this.getField('PriceList', 'PriceList.IsExcludedFromPriceSelection'),
                },
                RelationshipDefinition:
                {
                    Prices: this.getRelationshipDefinition('PriceList:Prices')
                }
            },
            Price:
            {
                Type: this.getType('Price'),
                Field:
                {
                    BasePrice: this.getField('Price', 'Price.BasePrice'),
                    DiscountPercentage: this.getField('Price', 'Price.DiscountPercentage'),
                    Price: this.getField('Price', 'Price.Price'),
                    StartDate: this.getField('Price', 'Price.StartDate'),
                    EndDate: this.getField('Price', 'Price.EndDate'),
                    Quantity: this.getField('Price', 'Price.Quantity'),
                    Currency: this.getField('Price', 'Price.Currency'),
                },
                RelationshipDefinition:
                {
                    Product: this.getRelationshipDefinition('Price:Product'),
                    Relationship: this.getRelationshipDefinition('Price:Relationship')
                }
            },
            Resource:
            {
                Type: this.getType('Resource'),
                Field:
                {
                    File: this.getField('Resource', 'Resource.File'),
                    Name: this.getField('Resource', 'Resource.Name'),
                    Token: this.getField('Resource', 'Resource.Token')
                },
                RelationshipDefinition: {}
            },
            SalesTarget:
            {
                Type: this.getType('SalesTarget'),
                Field:
                {
                    Amount: this.getField('SalesTarget', 'SalesTarget.Amount'),
                    Period: this.getField('SalesTarget', 'SalesTarget.Period')
                },
                RelationshipDefinition:
                {
                    SalesCategory: this.getRelationshipDefinition('SalesTarget:Datastore.SalesCategory'),
                    Person: this.getRelationshipDefinition('SalesTarget:Relation.Person')
                }
            },
            Involved:
            {
                Type: this.getType('Involved'),
                RelationshipDefinition:
                {
                    Relationship: this.getRelationshipDefinition('Involved:Relationship'),
                    Contact: this.getRelationshipDefinition('Involved:Contact'),
                    Role: this.getRelationshipDefinition('Involved:Role')
                }
            },
            LabelCategory:
            {
                Type: this.getType('LabelCategory'),
                Field:
                {
                    Name: this.getField('LabelCategory', 'LabelCategory.Name'),
                    IsInactive: this.getField('LabelCategory', 'LabelCategory.IsInactive')
                },
                RelationshipDefinition:
                {
                    Labels: this.getRelationshipDefinition('LabelCategory:Labels')
                }
            },
            Label:
            {
                Type: this.getType('Label'),
                Field:
                {
                    Name: this.getField('Label', 'Label.Name'),
                    IsInactive: this.getField('Label', 'Label.IsInactive')
                }
            },
            Datastore:
            {
                Type: this.getType('Datastore'),
                Field:
                {
                    LocalizedName: this.getField('Datastore', 'Datastore.LocalizedName'),
                    Code: this.getField('Datastore', 'Datastore.Code'),
                    IsDefault: this.getField('Datastore', 'Datastore.IsDefault'),
                    Color: this.getField('Datastore', 'Datastore.Color')
                },
                Phase:
                {
                    Type: this.getType('Datastore.Phase'),
                    Field:
                    {
                        LocalizedActionName: this.getField('Datastore.Phase', 'Datastore.Phase.LocalizedActionName'),
                        IsTerminal: this.getField('Datastore.Phase', 'Datastore.Phase.IsTerminal'),
                        IsAssignable: this.getField('Datastore.Phase', 'Datastore.Phase.IsAssignable'),
                        Icon: this.getField('Datastore.Phase', 'Datastore.Phase.Icon'),
                        Color: this.getField('Datastore.Phase', 'Datastore.Phase.Color')
                    },
                    ActivityAppointment:
                    {
                        Type: this.getType('Datastore.Phase.ActivityAppointment'),
                    },
                    ActivityTask:
                    {
                        Type: this.getType('Datastore.Phase.ActivityTask'),
                    },
                    ActivityEmail:
                    {
                        Type: this.getType('Datastore.Phase.ActivityEmail'),
                    },
                    ActivityCampaign:
                    {
                        Type: this.getType('Datastore.Phase.ActivityCampaign'),
                    },
                    ActivitySpotlerCampaign:
                    {
                        Type: this.getType('Datastore.Phase.ActivitySpotlerCampaign'),
                    },
                    ActivitySubscription:
                    {
                        Type: this.getType('Datastore.Phase.ActivitySubscription'),
                    },
                    BulkDeletion:
                    {
                        Type: this.getType('Datastore.Phase.BulkDeletion'),
                    },
                    DigitalSigning:
                    {
                        Type: this.getType('Datastore.Phase.DigitalSigning'),
                    },
                    DigitalSigner:
                    {
                        Type: this.getType('Datastore.Phase.DigitalSigner'),
                    },
                },
                GeneralLedgerAccount:
                {
                    Type: this.getType('Datastore.GeneralLedgerAccount')
                },
                AppointmentType:
                    {
                        Type: this.getType('Datastore.AppointmentType'),
                    },
                Language:
                {
                    Type: this.getType('Datastore.Language'),
                    Field:
                    {
                        Flag: this.getField('Datastore.Language', 'Datastore.Language.Flag')
                    }
                },
                State:
                {
                    Type: this.getType('Datastore.State')
                },
                TaskType:
                    {
                        Type: this.getType('Datastore.TaskType')
                    },
                Country:
                {
                    Type: this.getType('Datastore.Country'),
                    Field:
                    {
                        HasIntraCommunityVat: this.getField('Datastore.Country', 'Datastore.Country.HasIntraCommunityVat'),
                        CountryNumber: this.getField('Datastore.Country', 'Datastore.Country.CountryNumber')
                    },
                    RelationshipDefinition:
                    {
                        States: this.getRelationshipDefinition('Datastore.Country:States'),
                    }
                },
                VatGroup:
                {
                    Type: this.getType('Datastore.VatGroup'),
                    Field:
                    {
                        Percentage: this.getField('Datastore.VatGroup', 'Datastore.VatGroup.Percentage')
                    },
                    RelationshipDefinition:
                    {
                        Country: this.getRelationshipDefinition('Datastore.VatGroup:Country'),
                    },
                    Function:
                    {
                        ComputeVatPrice:
                            (price: number,
                             fromInclVat: boolean = false,
                             toInclVat: boolean = false,
                             vatGroup: Entity): number =>
                        {
                            if (price === undefined)
                            {
                                return undefined;
                            }

                            const priceDecimal = new Decimal(price);
                            const percentage =
                                new Decimal(
                                    vatGroup.getObjectValueByField(this.types.Datastore.VatGroup.Field.Percentage)
                                    || 0);

                            const normalizedPercentage = percentage.div(100).add(1);

                            if (fromInclVat === toInclVat)
                            {
                                return price;
                            }
                            else
                            {
                                if (!fromInclVat && toInclVat)
                                {
                                    return priceDecimal.mul(normalizedPercentage).toNumber();
                                }
                                else
                                {
                                    return priceDecimal.div(normalizedPercentage).toNumber();
                                }
                            }
                        }
                    }
                },
                LegalForm:
                {
                    Type: this.getType('Datastore.LegalForm')
                },
                Unit:
                {
                    Type: this.getType('Datastore.Unit')
                },
                AddressType:
                {
                    Type: this.getType('Datastore.AdressType')
                },
                PhoneNumberType:
                {
                    Type: this.getType('Datastore.PhoneNumberType')
                },
                EmailAddressType:
                {
                    Type: this.getType('Datastore.EmailAddressType')
                },
                EmailingCategory:
                {
                    Type: this.getType('Datastore.EmailingCategory')
                },
                EventLocation:
                {
                    Type: this.getType('Datastore.EventLocation'),
                },
                EventType:
                {
                    Type: this.getType('Datastore.EventType'),
                },
                Skill:
                {
                    Type: this.getType('Datastore.Skill'),
                },
                Function:
                {
                    Type: this.getType('Datastore.Function'),
                },
                LicenseContractEdition:
                {
                    Type: this.getType('Datastore.LicenseContractEdition')
                }
            },
            CustomEntity:
            {
                Type: this.getType('CustomEntity'),
                RelationshipDefinition:
                {
                    Activities: this.getRelationshipDefinition('CustomEntity:Activities')
                }
            },
            CalendarItem:
            {
                Type: this.getType('CalendarItem'),
                Field:
                {
                    Subject: this.getField('CalendarItem', 'CalendarItem.Subject'),
                    Location: this.getField('CalendarItem', 'CalendarItem.Location'),
                    StartDate: this.getField('CalendarItem', 'CalendarItem.StartDate'),
                    EndDate: this.getField('CalendarItem', 'CalendarItem.EndDate'),
                    IsPrivate: this.getField('CalendarItem', 'CalendarItem.IsPrivate'),
                    Content: this.getField('CalendarItem', 'CalendarItem.Content'),
                    IsRecurring: this.getField('CalendarItem', 'CalendarItem.IsRecurring'),
                    RecurrenceStartDate: this.getField('CalendarItem', 'CalendarItem.RecurrenceStartDate'),
                    RecurrenceEndDate: this.getField('CalendarItem', 'CalendarItem.RecurrenceEndDate'),
                    RecurrencePattern: this.getField('CalendarItem', 'CalendarItem.RecurrencePattern'),
                },
                RelationshipDefinition:
                {
                    Employee: this.getRelationshipDefinition('Relationship.Person.Contact.Employee:CalendarItems'),
                    Attendees: this.getRelationshipDefinition('CalendarItem:Attendees'),
                    Address: this.getRelationshipDefinition('CalendarItem:Address')
                }
            },
            CalendarItemAttendee:
            {
                Type: this.getType('CalendarItemAttendee'),
                Field:
                {
                    Name: this.getField('CalendarItemAttendee', 'CalendarItemAttendee.Name'),
                    EmailAddress: this.getField('CalendarItemAttendee', 'CalendarItemAttendee.EmailAddress'),
                    IsRequired: this.getField('CalendarItemAttendee', 'CalendarItem.IsRequired'),
                },
                RelationshipDefinition:
                {
                    Relationship: this.getRelationshipDefinition('CalendarItemAttendee:Relationship'),
                    Contact: this.getRelationshipDefinition('CalendarItemAttendee:Contact'),
                    ResponseStatus: this.getRelationshipDefinition('CalendarItemAttendee:ResponseStatus'),
                }
            },
            AppointmentAttendee:
            {
                Type: this.getType('AppointmentAttendee'),
                Field:
                {
                    EmailAddress: this.getField('AppointmentAttendee', 'AppointmentAttendee.EmailAddress'),
                    IsRequired: this.getField('AppointmentAttendee', 'AppointmentAttendee.IsRequired'),
                },
                RelationshipDefinition:
                {
                    Relationship: this.getRelationshipDefinition('AppointmentAttendee:Relationship'),
                    Contact: this.getRelationshipDefinition('AppointmentAttendee:Contact'),
                    CalendarItemAttendee: this.getRelationshipDefinition('AppointmentAttendee:CalendarItemAttendee'),
                }
            },
            WorkOrderMember:
            {
                Type: this.getType('WorkOrderMember'),
                RelationshipDefinition:
                {
                    Employee: this.getRelationshipDefinition('WorkOrderMember:Employee'),
                    CalendarItemAttendee: this.getRelationshipDefinition('WorkOrderMember:CalendarItemAttendee'),
                }
            },
            EmployeeAllocation:
            {
                Type: this.getType('EmployeeAllocation'),
                Field:
                {
                    Year: this.getField('EmployeeAllocation', 'EmployeeAllocation.Year'),
                    Week: this.getField('EmployeeAllocation', 'EmployeeAllocation.Week'),
                    WeekDate: this.getField('EmployeeAllocation', 'EmployeeAllocation.WeekDate'),
                    DurationInHours: this.getField('EmployeeAllocation', 'EmployeeAllocation.DurationInHours'),
                    Description: this.getField('EmployeeAllocation', 'EmployeeAllocation.Description'),
                }
            },
            EmployeeAllocationSequence:
            {
                Type: this.getType('EmployeeAllocationSequence'),
                Field:
                {
                    StartDate: this.getField('EmployeeAllocationSequence', 'EmployeeAllocationSequence.StartDate'),
                    EndDate: this.getField('EmployeeAllocationSequence', 'EmployeeAllocationSequence.EndDate'),
                },
                RelationshipDefinition:
                {
                    Allocations: this.getRelationshipDefinition('EmployeeAllocationSequence:Allocations'),
                }
            },
            TimeRegistration:
            {
                Type: this.getType('TimeRegistration'),
                Field:
                {
                    StartDate: this.getField('TimeRegistration', 'TimeRegistration.StartDate'),
                    EndDate: this.getField('TimeRegistration', 'TimeRegistration.EndDate'),
                    IsBillable: this.getField('TimeRegistration', 'TimeRegistration.IsBillable'),
                    IsBilled: this.getField('TimeRegistration', 'TimeRegistration.IsBilled'),
                    IsProcessed: this.getField('TimeRegistration', 'TimeRegistration.IsProcessed'),
                    DurationInHours: this.getField('TimeRegistration', 'TimeRegistration.DurationInHours'),
                    TotalCostPrice: this.getField('TimeRegistration', 'TimeRegistration.TotalCostPrice'),
                    TotalSalesPrice: this.getField('TimeRegistration', 'TimeRegistration.TotalSalesPrice'),
                    Description: this.getField('TimeRegistration', 'TimeRegistration.Description')
                },
                RelationshipDefinition:
                {
                    Activity: this.getRelationshipDefinition('TimeRegistration:Activity'),
                    SubActivity: this.getRelationshipDefinition('TimeRegistration:SubActivity'),
                    ProductLine: this.getRelationshipDefinition('TimeRegistration:ProductLine'),
                    ProductLines: this.getRelationshipDefinition('TimeRegistration:ProductLines'),
                    MileageRegistrations: this.getRelationshipDefinition('TimeRegistration:MileageRegistrations'),
                }
            },
            TimeRegistrationActivity:
            {
                Type: this.getType('TimeRegistrationActivity'),
                Field:
                {
                    IsDefault: this.getField('TimeRegistrationActivity', 'TimeRegistrationActivity.IsDefault')
                },
                RelationshipDefinition:
                {
                    Product: this.getRelationshipDefinition('TimeRegistrationActivity:Product')
                }
            },
            MileageRegistration:
            {
                Type: this.getType('MileageRegistration'),
                Field:
                {
                    Date: this.getField('MileageRegistration', 'MileageRegistration.Date'),
                    Distance: this.getField('MileageRegistration', 'MileageRegistration.Distance'),
                    IsBillable: this.getField('MileageRegistration', 'MileageRegistration.IsBillable'),
                    IsBilled: this.getField('MileageRegistration', 'MileageRegistration.IsBilled'),
                    IsProcessed: this.getField('MileageRegistration', 'MileageRegistration.IsProcessed'),
                    Description: this.getField('MileageRegistration', 'MileageRegistration.Description'),
                },
                RelationshipDefinition:
                {
                    Type: this.getRelationshipDefinition('MileageRegistration:MileageRegistrationType'),
                }
            },
            MileageRegistrationType:
            {
                Type: this.getType('MileageRegistrationType'),
                Field:
                {
                    Name: this.getField('MileageRegistrationType', 'MileageRegistrationType.Name'),
                },
                RelationshipDefinition:
                {
                    Product: this.getRelationshipDefinition('MileageRegistrationType:Product'),
                }
            },
            Milestone:
            {
                Type: this.getType('ProjectMilestone'),
                Field:
                {
                    Name: this.getField('ProjectMilestone', 'ProjectMilestone.Name'),
                    Budget: this.getField('ProjectMilestone', 'ProjectMilestone.Budget'),
                    HourBudget: this.getField('ProjectMilestone', 'ProjectMilestone.HourBudget'),
                    IsFinished: this.getField('ProjectMilestone', 'ProjectMilestone.IsFinished'),
                    StartDate: this.getField('ProjectMilestone', 'ProjectMilestone.StartDate'),
                    EndDate: this.getField('ProjectMilestone', 'ProjectMilestone.EndDate')
                },
                RelationshipDefinition:
                {
                    Owner: this.getRelationshipDefinition('ProjectMilestone:Owner'),
                    Activities: this.getRelationshipDefinition('Milestone:Activities'),
                    ProductLines: this.getRelationshipDefinition('Milestone:ProductLines'),
                    TimeRegistrations: this.getRelationshipDefinition('Milestone:TimeRegistrations'),
                    MileageRegistrations: this.getRelationshipDefinition('Milestone:MileageRegistrations'),
                    EmployeeAllocations: this.getRelationshipDefinition('Milestone:EmployeeAllocations'),
                    ExternalCosts: this.getRelationshipDefinition('Milestone:ExternalCosts'),
                    Notes: this.getRelationshipDefinition('ProjectMilestone:Notes'),
                    Attachments: this.getRelationshipDefinition('ProjectMilestone:Attachments'),
                }
            },
            ExternalCost:
            {
                Type: this.getType('ExternalCost'),
                Field:
                {
                    Description: this.getField('ExternalCost', 'ExternalCost.Description'),
                    Amount: this.getField('ExternalCost', 'ExternalCost.Amount')
                },
                RelationshipDefinition:
                {
                    VatGroup: this.getRelationshipDefinition('ExternalCost:VatGroup')
                }
            },
            Form:
            {
                Type: this.getType('Form')
            },
            Recipient:
            {
                Type: this.getType('Recipient'),
                RelationshipDefinition:
                {
                    Addressee: this.getRelationshipDefinition('Recipient:Addressee')
                },
                Email:
                {
                    Type: this.getType('Recipient.Email'),
                    Field:
                    {
                        Name: this.getField('Recipient.Email', 'Recipient.Email.Name'),
                        EmailAddress: this.getField('Recipient.Email', 'Recipient.Email.EmailAddress')
                    },
                    Functions:
                    {
                        // Sort by prefered field ids (if exist) followed by other field ids.
                        SortByPreferedField:
                            (a, b) =>
                            {
                                const fieldId =
                                    [
                                        this.types.Relation.Organization.Field.EmailAddress.id,
                                        this.types.Relation.Organization.Field.FinancialEmailAddress.id,
                                        this.types.Relationship.Person.Contact.Field.EmailAddress.id,
                                        this.types.Relation.Person.Field.EmailAddress.id
                                    ]
                                const idA = a.field.id || 0;
                                const idB = b.field.id || 0;

                                if (fieldId.includes(idA) && fieldId.includes(idB))
                                {
                                    return fieldId.indexOf(idA) - fieldId.indexOf(idB);
                                }
                                else if (fieldId.includes(idA))
                                {
                                    return -1;
                                }
                                else if (fieldId.includes(idB))
                                {
                                    return 1;
                                }
                                return 0;
                            },

                        RecipientFromEntity:
                            (
                                entity: Entity,
                                emailAddress?: string,
                                commitContext?: CommitContext,
                            ): Entity =>
                            {
                                if (!entity)
                                {
                                    return undefined;
                                }

                                // Get e-mail address from entity
                                const emailAddresses: string[] =
                                    emailAddress
                                        ?
                                            [
                                                emailAddress
                                            ]
                                        :
                                            getEmailAddressesByEntity(
                                                entity,
                                                commitContext
                                            )
                                            .sort(
                                                this.types.Recipient.Email.Functions.SortByPreferedField
                                            )
                                            .map(
                                                emailAddress =>
                                                    emailAddress.emailAddress
                                            );

                                if (emailAddresses.length > 0)
                                {
                                    // Create new recipient entity
                                    const recipientEntity =
                                        constructEntityOfType(
                                            this.types.Recipient.Email.Type,
                                            commitContext
                                        );

                                    // Set e-mail address to recipient entity
                                    setValueByFieldInEntity(
                                        recipientEntity,
                                        this.types.Recipient.Email.Field.EmailAddress,
                                        emailAddresses[0],
                                        commitContext
                                    );

                                    // Add addressee relationship
                                    updateRelationship(
                                        recipientEntity,
                                        false,
                                        this.types.Recipient.RelationshipDefinition.Addressee,
                                        entity,
                                        commitContext,
                                        undefined,
                                        true
                                    );

                                    return recipientEntity;
                                }
                                else
                                {
                                    return undefined;
                                }
                            }
                    }
                }
            },
            Documentation:
            {
                Type: this.getType('Documentation'),
                Field:
                {
                    Name: this.getField('Documentation', 'Documentation.Name'),
                    Description: this.getField('Documentation', 'Documentation.Description')
                },
                RelationshipDefinition:
                {
                    Pages: this.getRelationshipDefinition('Documentation:Documentation.Pages')
                },
                Document:
                {
                    Type: this.getType('Documentation.Document')
                },
                Page:
                {
                    Type: this.getType('Documentation.Page'),
                    RelationshipDefinition:
                    {
                        Layout: this.getRelationshipDefinition('Documentation.Page:Layout')
                    }
                }
            },
            Setting:
            {
                Type: this.getType('Settings')
            },
            ArchitectualElement:
            {
                Type: this.getType('ArchitectualElement')
            },
            Address:
            {
                Type: this.getType('Address'),
                Field:
                {
                    Street: this.getField('Address', 'Address.Street'),
                    HouseNr: this.getField('Address', 'Address.HouseNumber'),
                    HouseNrSuffix: this.getField('Address', 'Address.HouseNumberSuffix'),
                    PostalCode: this.getField('Address', 'Address.Postalcode'),
                    City: this.getField('Address', 'Address.City'),
                    Longitude: this.getField('Address', 'Address.Longitude'),
                    Latitude: this.getField('Address', 'Address.Latitude'),
                    // Extra: this.getField('Address', 'Address.Extra')
                },
                RelationshipDefinition:
                {
                    Type: this.getRelationshipDefinition('Address:Datastore.AddressType'),
                    State: this.getRelationshipDefinition('Address:State'),
                    Country: this.getRelationshipDefinition('Address:Datastore.Country')
                },
                Functions:
                {
                    getAddressLine: (entity: Entity) =>
                    {
                        const streetName = entity.getDataObjectValueByField(this.types.Address.Field.Street).getValue();
                        const houseNr = entity.getDataObjectValueByField(this.types.Address.Field.HouseNr).getValue();
                        const houseNrSuffix = entity.getDataObjectValueByField(this.types.Address.Field.HouseNrSuffix).getValue();
                        const postalCode = entity.getDataObjectValueByField(this.types.Address.Field.PostalCode).getValue();
                        const city = entity.getDataObjectValueByField(this.types.Address.Field.City).getValue();

                        if (streetName || houseNr || houseNrSuffix || postalCode || city)
                        {
                            return `${streetName ? streetName : ''} ${houseNr ? houseNr : ''}${houseNrSuffix ? ` ${houseNrSuffix}` : ''}${postalCode || city ? ',' : ''} ` +
                                `${postalCode ? postalCode : ''} ${city ? city : ''}`;
                        }
                        else
                        {
                            return undefined;
                        }
                    }
                }
            },
            EmailAddress:
            {
                Type: this.getType('EmailAddress'),
                Field:
                {
                    EmailAdress: this.getField('EmailAddress', 'EmailAddress.EmailAddres')
                },
                RelationshipDefinition:
                {
                    Type: this.getRelationshipDefinition('EmailAddress:Datastore.AdressType')
                }
            },
            PhoneNumber:
            {
                Type: this.getType('PhoneNumber'),
                Field:
                {
                    PhoneNumber: this.getField('PhoneNumber', 'PhoneNumber.PhoneNumber')
                },
                RelationshipDefinition:
                {
                    Type: this.getRelationshipDefinition('PhoneNumber:Datastore.PhoneNumberType')
                }
            },
            ExternalId:
            {
                Type: this.getType('ExternalId'),
                Field:
                {
                    Service: this.getField('ExternalId', 'ExternalId.Service'),
                    Type: this.getField('ExternalId', 'ExternalId.Type'),
                    Id: this.getField('ExternalId', 'ExternalId.Id'),
                    Metadata: this.getField('ExternalId', 'ExternalId.Metadata')
                }
            },
            ConnectorCategory:
            {
                Type: this.getType('ConnectorCategory'),
                Field:
                {
                    Name: this.getField('ConnectorCategory', 'ConnectorCategory.Name'),
                    Code: this.getField('ConnectorCategory', 'ConnectorCategory.Code')
                },
                RelationshipDefinition:
                {
                    Connectors: this.getRelationshipDefinition('ConnectorCategory:Connectors')
                }
            },
            Connector:
            {
                Type: this.getType('Connector'),
                Field:
                {
                    Code: this.getField('Connector', 'Connector.Code'),
                    Name: this.getField('Connector', 'Connector.Name'),
                    Description: this.getField('Connector', 'Connector.Description'),
                    Logo: this.getField('Connector', 'Connector.Logo')
                },
                RelationshipDefinition:
                {
                    Activations: this.getRelationshipDefinition('Connector:Activations')
                }
            },
            ConnectorActivation:
            {
                Type: this.getType('ConnectorActivation'),
                Field:
                {
                    IsActivated: this.getField('ConnectorActivation', 'ConnectorActivation.IsActivated'),
                    Credentials: this.getField('ConnectorActivation', 'ConnectorActivation.Credentials'),
                    Configuration: this.getField('ConnectorActivation', 'ConnectorActivation.Configuration'),
                    Runtime: this.getField('ConnectorActivation', 'ConnectorActivation.Runtime')
                }
            },
            LicenseContract:
            {
                Type: this.getType('LicenseContract'),
                Field:
                {
                    CompanyName: this.getField('LicenseContract', 'LicenseContract.CompanyName'),
                    ContactFirstName: this.getField('LicenseContract', 'LicenseContract.ContactFirstName'),
                    ContactLastName: this.getField('LicenseContract', 'LicenseContract.ContactLastName'),
                    ContactPhoneNumber: this.getField('LicenseContract', 'LicenseContract.ContactPhoneNumber'),
                    ContactEmailAddress: this.getField('LicenseContract', 'LicenseContract.ContactEmailAddress'),
                    VatNumber: this.getField('LicenseContract', 'LicenseContract.VatNumber'),
                    ActivationDate: this.getField('LicenseContract', 'LicenseContract.ActivationDate'),
                    CancellationDate: this.getField('LicenseContract', 'LicenseContract.CancellationDate'),
                    TrialPeriodEndDate: this.getField('LicenseContract', 'LicenseContract.TrialPeriodEndDate'),
                    CooldownPeriodEndDate: this.getField('LicenseContract', 'LicenseContract.CooldownPeriodEndDate'),
                    FreezePeriodEndDate: this.getField('LicenseContract', 'LicenseContract.FreezePeriodEndDate'),
                    EndDate: this.getField('LicenseContract', 'LicenseContract.EndDate'),
                    BlockDate: this.getField('LicenseContract', 'LicenseContract.BlockDate'),
                    IsCancelled: this.getField('LicenseContract', 'LicenseContract.IsCancelled'),
                    ClientInvoiceReference: this.getField('LicenseContract', 'LicenseContract.ClientInvoiceReference'),
                    Notification: this.getField('LicenseContract', 'LicenseContract.Notification'),
                    ShowNotification: this.getField('LicenseContract', 'LicenseContract.ShowNotification')
                },
                RelationshipDefinition:
                {
                    InvoicingAddress: this.getRelationshipDefinition('LicenseContract:InvoicingAddress'),
                    Environments: this.getRelationshipDefinition('LicenseContract:Environments'),
                    LicensePeriods: this.getRelationshipDefinition('LicenseContract:LicensePeriods'),
                    Licenses: this.getRelationshipDefinition('LicenseContract:Licenses'),
                    Language: this.getRelationshipDefinition('LicenseContract:Language'),
                    LicenseContractEdition: this.getRelationshipDefinition('LicenseContract:LicenseContractEdition')
                }
            },
            License:
            {
                Type: this.getType('License'),
                Field:
                {
                    StartDate: this.getField('License', 'License.StartDate'),
                    EndDate: this.getField('License', 'License.EndDate')
                },
                User:
                {
                    Type: this.getType('License.User'),
                    RelationshipDefinition:
                    {
                        Employee: this.getRelationshipDefinition('License.User:Employee')
                    }
                }
            },
            NumberSequence:
            {
                Type: this.getType('NumberSequence'),
                Field:
                {
                    Pattern: this.getField('NumberSequence', 'NumberSequence.Pattern'),
                    LastNumber: this.getField('NumberSequence', 'NumberSequence.LastNumber'),
                }
            },
            NumberSequenceDefinition:
            {
                Type: this.getType('NumberSequenceDefinition'),
                Field:
                {
                    EntityType: this.getField('NumberSequenceDefinition', 'NumberSequenceDefinition.EntityType'),
                    Specification: this.getField('NumberSequenceDefinition', 'NumberSequenceDefinition.Specification'),
                    StartNumber: this.getField('NumberSequenceDefinition', 'NumberSequenceDefinition.StartNumber'),
                    SubsequentStartNumber: this.getField('NumberSequenceDefinition', 'NumberSequenceDefinition.SubsequentStartNumber'),
                    EntityField: this.getField('NumberSequenceDefinition', 'NumberSequenceDefinition.EntityField'),
                    NumberLength: this.getField('NumberSequenceDefinition', 'NumberSequenceDefinition.NumberLength'),
                },
                RelationshipDefinition:
                {
                    NumberSequences: this.getRelationshipDefinition('NumberSequenceDefinition:NumberSequences')
                }
            },
            Import:
            {
                Type: this.getType('Import'),
                Field:
                {
                    File: this.getField('Import', 'Import.File'),
                    FileSpecification: this.getField('Import', 'Import.FileSpecification'),
                    Fields: this.getField('Import', 'Import.Fields'),
                    Mapping: this.getField('Import', 'Import.Mapping'),
                    NumberOfRecords: this.getField('Import', 'Import.NumberOfRecords'),
                    NumberOfProcessedRecords: this.getField('Import', 'Import.NumberOfProcessedRecords'),
                    Date: this.getField('Import', 'Import.Date'),
                    StartDate: this.getField('Import', 'Import.StartDate'),
                    EndDate: this.getField('Import', 'Import.EndDate'),
                    RollbackDate: this.getField('Import', 'Import.RollbackDate'),
                    Result: this.getField('Import', 'Import.Result'),
                },
                RelationshipDefinition:
                {
                    Phase: this.getRelationshipDefinition('Import:Phase'),
                    Entities: this.getRelationshipDefinition('Import:Entities')
                }
            },
            Team:
            {
                Type: this.getType('Team'),
                Field:
                {
                    Name: this.getField('Team', 'Team.Name'),
                    IsAccessLimitedToTeam: this.getField('Team', 'Team.IsAccessLimitedToTeam'),
                    HasAccessToNonTeamRelationships: this.getField('Team', 'Team.HasAccessToNonTeamRelationships'),
                },
                RelationshipDefinition:
                {
                    Members: this.getRelationshipDefinition('Team:Members'),
                    Entities: this.getRelationshipDefinition('Team:Entities')
                }
            },
            Role:
            {
                Type: this.getType('Role'),
                Field:
                {
                    Name: this.getField('Role', 'Role.Name'),
                    Specification: this.getField('Role', 'Role.Specification')
                },
                RelationshipDefinition:
                {
                    Members: this.getRelationshipDefinition('Role:Members'),
                }
            },
            Mailbox:
            {
                Type: this.getType('Mailbox'),
                Field:
                {
                    Name: this.getField('Mailbox', 'Mailbox.Name'),
                    Configuration: this.getField('Mailbox', 'Mailbox.Configuration'),
                    Credentials: this.getField('Mailbox', 'Mailbox.Credentials')
                },
                RelationshipDefinition:
                {
                    Emails: this.getRelationshipDefinition('Mailbox:Emails'),
                    Automations: this.getRelationshipDefinition('Mailbox:Automations'),
                }
            },
            EmailClick:
            {
                Type: this.getType('EmailClick'),
                Field:
                {
                    LinkUrl: this.getField('EmailClick', 'EmailClick.LinkUrl')
                }
            },
            EmailSender:
            {
                Type: this.getType('EmailSender'),
                Field:
                {
                    Name: this.getField('EmailSender', 'EmailSender.Name'),
                    EmailAddress: this.getField('EmailSender', 'EmailSender.EmailAddress'),
                    IsInactive: this.getField('EmailSender', 'EmailSender.IsInactive')
                }
            },
            Pipeline:
            {
                Type: this.getType('Pipeline'),
                RelationshipDefinition:
                {
                    Phases: this.getRelationshipDefinition('Pipeline:Phases')
                },
                Field:
                {
                    Name: this.getField('Pipeline', 'Pipeline.Name'),
                    Code: this.getField('Pipeline', 'Pipeline.Code'),
                    IsActive: this.getField('Pipeline', 'Pipeline.IsActive')
                }
            },
            ProjectMember:
            {
                Type: this.getType('ProjectMember'),
                RelationshipDefinition:
                {
                    Role: this.getRelationshipDefinition('ProjectMember:Role'),
                    Employee: this.getRelationshipDefinition('ProjectMember:Employee')
                }
            },
            EventParticipation:
            {
                Type: this.getType('EventParticipation'),
                RelationshipDefinition:
                {
                    Relationship: this.getRelationshipDefinition('EventParticipation:Relationship'),
                    Activities: this.getRelationshipDefinition('EventParticipation:Activities')
                }
            },
            BulkDeletion:
            {
                Type: this.getType('BulkDeletion'),
                RelationshipDefinition:
                {
                    Phase: this.getRelationshipDefinition('BulkDeletion:Phase')
                },
                Field:
                {
                    Filter: this.getField('BulkDeletion', 'BulkDeletion.Filter'),
                    NumberOfRecords: this.getField('BulkDeletion', 'BulkDeletion.NumberOfRecords'),
                    NumberOfDeletedRecords: this.getField('BulkDeletion', 'BulkDeletion.NumberOfDeletedRecords')
                },
                Phase:
                {
                    Concept: 'Concept',
                    InProgress: 'InProgress',
                    Paused: 'Paused',
                    Finished: 'Finished'
                }
            },
            LocalizedText:
            {
                Type: this.getType('LocalizedText'),
                Field:
                {
                    Code: this.getField('LocalizedText', 'LocalizedText.Code'),
                    Text: this.getField('LocalizedText', 'LocalizedText.Text')
                }
            },
            Portal:
            {
                Type: this.getType('Portal'),
                Field:
                {
                    Name: this.getField('Portal', 'Portal.Name'),
                    Layout: this.getField('Portal', 'Portal.Layout'),
                    Logo: this.getField('Portal', 'Portal.Logo'),
                    Favicon: this.getField('Portal', 'Portal.Favicon'),
                    Url: this.getField('Portal', 'Portal.Url'),
                    PrimaryColor: this.getField('Portal', 'Portal.PrimaryColor'),
                    InvitationEmailSubject: this.getField('Portal', 'Portal.InvitationEmailSubject'),
                    InvitationEmailBody: this.getField('Portal', 'Portal.InvitationEmailBody'),
                    PasswordResetEmailSubject: this.getField('Portal', 'Portal.PasswordResetEmailSubject'),
                    PasswordResetEmailBody: this.getField('Portal', 'Portal.PasswordResetEmailBody'),
                    UserSettingsForm: this.getField('Portal', 'Portal.UserSettingsForm')
                },
                RelationshipDefinition:
                {
                    EmailSender: this.getRelationshipDefinition('Portal:EmailSender'),
                    Pages: this.getRelationshipDefinition('Portal:Pages'),
                    Users: this.getRelationshipDefinition('Portal:Users'),
                    DataSources: this.getRelationshipDefinition('Portal:DataSources'),
                    Actions: this.getRelationshipDefinition('Portal:Actions'),
                    Resources: this.getRelationshipDefinition('Portal:Resources')
                }
            },
            PortalUser:
            {
                Type: this.getType('PortalUser'),
                RelationshipDefinition:
                {
                    Relationship: this.getRelationshipDefinition('PortalUser:Relationship'),
                    Contact: this.getRelationshipDefinition('PortalUser:Contact')
                },
                Field:
                {
                    EmailAddress: this.getField('PortalUser', 'PortalUser.EmailAddress'),
                    UserSettingsParameterAssignment: this.getField('PortalUser', 'PortalUser.UserSettingsParameterAssignment'),
                    HasTwoFactorAuthentication: this.getField('PortalUser', 'PortalUser.HasTwoFactorAuthentication')
                }
            },
            PortalDataSource:
            {
                Type: this.getType('PortalDataSource'),
                Field:
                {
                    Name: this.getField('PortalDataSource', 'PortalDataSource.Name'),
                    Specification: this.getField('PortalDataSource', 'PortalDataSource.Specification')
                },
            },
            PortalAction:
            {
                Type: this.getType('PortalAction'),
                Field:
                {
                    Name: this.getField('PortalAction', 'PortalAction.Name'),
                    Specification: this.getField('PortalAction', 'PortalAction.Specification')
                }
            },
            PortalPage:
            {
                Type: this.getType('PortalPage'),
                Field:
                {
                    Name: this.getField('PortalPage', 'PortalPage.Name'),
                    Route: this.getField('PortalPage', 'PortalPage.Route'),
                    Layout: this.getField('PortalPage', 'PortalPage.Layout')
                }
            },
            PortalResource:
            {
                Type: this.getType('PortalResource'),
                Field:
                {
                    File: this.getField('PortalResource', 'PortalResource.File')
                }
            },
            ResourcePlanner:
            {
                Type: this.getType('ResourcePlanner'),
                Field:
                {
                    Name: this.getField('ResourcePlanner', 'ResourcePlanner.Name'),
                    Specification: this.getField('ResourcePlanner', 'ResourcePlanner.Specification')
                }
            },
            LayoutTemplate:
            {
                Type: this.getType('LayoutTemplate'),
                Field:
                {
                    Name: this.getField('LayoutTemplate', 'LayoutTemplate.Name'),
                    Specification: this.getField('LayoutTemplate', 'LayoutTemplate.Specification')
                }
            },
            ArchivingEvent:
            {
                Type: this.getType('ArchivingEvent'),
            },
            SpotlerCampaignStep:
            {
                Type: this.getType('SpotlerCampaignStep'),
                Field:
                {
                    Name: this.getField('SpotlerCampaignStep', 'SpotlerCampaignStep.Name')
                },
                RelationshipDefinition:
                {
                    Results: this.getRelationshipDefinition('SpotlerCampaignStep:SpotlerCampaignResults')
                }
            },
            SpotlerCampaignEvent:
            {
                Type: this.getType('SpotlerCampaignEvent'),
                Field:
                {
                    Date: this.getField('SpotlerCampaignEvent', 'SpotlerCampaignEvent.Date'),
                    Value: this.getField('SpotlerCampaignEvent','SpotlerCampaignEvent.Value')
                },
                RelationshipDefinition:
                {
                    EventType: this.getRelationshipDefinition('SpotlerCampaignEvent:SpotlerCampaignEventType')
                },
                EventType:
                {
                    View: 'View',
                    Click: 'Click',
                    Bounce: 'Bounce'
                }
            },
            ApsisCampaignEvent:
            {
                Type: this.getType('ApsisCampaignEvent'),
                Field:
                {
                    Date: this.getField('ApsisCampaignEvent', 'ApsisCampaignEvent.Date'),
                    Value: this.getField('ApsisCampaignEvent','ApsisCampaignEvent.Value')
                },
                RelationshipDefinition:
                {
                    EventType: this.getRelationshipDefinition('ApsisCampaignEvent:ApsisCampaignEventType')
                },
                EventType:
                {
                    View: 'View',
                    Click: 'Click',
                    Bounce: 'Bounce'
                }
            },
            CourseTemplate:
            {
                Type: this.getType('CourseTemplate'),
                Field:
                {
                    NumberOfLessons: this.getField('CourseTemplate', 'CourseTemplate.NumberOfLessons'),
                    LessonDuration: this.getField('CourseTemplate', 'CourseTemplate.LessonDuration'),
                    LessonCronPattern: this.getField('CourseTemplate', 'CourseTemplate.LessonCronPattern'),
                    Price: this.getField('CourseTemplate', 'CourseTemplate.Price'),
                    MaxNumberOfAttendees: this.getField('CourseTemplate', 'CourseTemplate.MaxNumberOfAttendees'),
                },
                RelationshipDefinition:
                {
                    CourseLocation: this.getRelationshipDefinition('CourseTemplate:CourseLocation'),
                    CourseInstructor: this.getRelationshipDefinition('CourseTemplate:CourseInstructor'),
                    CourseFiles: this.getRelationshipDefinition('CourseTemplate:CourseFiles'),
                },
            },
            CourseHoliday:
            {
                Type: this.getType('CourseHoliday'),
                Field:
                {
                    StartDate: this.getField('CourseHoliday', 'CourseHoliday.StartDate'),
                    EndDate: this.getField('CourseHoliday', 'CourseHoliday.EndDate'),
                },
                RelationshipDefinition:
                {

                },
            },
            CourseLocation:
            {
                Type: this.getType('CourseLocation'),
                Field:
                {

                },
                RelationshipDefinition:
                {
                    Address: this.getRelationshipDefinition('CourseLocation:Address'),
                },
            },
            CourseFile:
            {
                Type: this.getType('CourseFile'),
                Field:
                {
                    Name: this.getField('CourseFile', 'CourseFile.Name'),
                    File: this.getField('CourseFile', 'CourseFile.File'),
                },
                RelationshipDefinition:
                {

                },
            },
            CourseLesson:
            {
                Type: this.getType('CourseLesson'),
                Field:
                {
                    StartDate: this.getField('CourseLesson', 'CourseLesson.StartDate'),
                    EndDate: this.getField('CourseLesson', 'CourseLesson.EndDate'),
                },
                RelationshipDefinition:
                {
                    Attendances: this.getRelationshipDefinition('CourseLesson:Attendances'),
                    Registrations: this.getRelationshipDefinition('CourseLesson:Registrations'),
                    CalendarItem: this.getRelationshipDefinition('CourseLesson:CalendarItem'),
                    Instructor: this.getRelationshipDefinition('CourseLesson:CourseInstructor'),
                },
            },
            InternalEnvironment:
            {
                Type: this.getType('InternalEnvironment'),
                Field:
                {
                    Code: this.getField('InternalEnvironment', 'InternalEnvironment.Code'),
                    IsMigrationInProgress: this.getField('InternalEnvironment', 'InternalEnvironment.IsMigrationInProgress'),
                    CreateSalesOpportunityForNewLeads: this.getField('InternalEnvironment', 'InternalEnvironment.CreateSalesOpportunityForNewLeads'),
                    Currency: this.getField('InternalEnvironment', 'InternalEnvironment.Currency'),
                },
                RelationshipDefinition:
                {
                    Country: this.getRelationshipDefinition('InternalEnvironments:Country'),
                    Language: this.getRelationshipDefinition('InternalEnvironments:Language'),
                    InvoiceTemplate: this.getRelationshipDefinition('InternalEnvironments:InvoiceTemplate'),
                    EmailInvoiceTemplate: this.getRelationshipDefinition('InternalEnvironments:EmailInvoiceTemplate'),
                    SalesRepresentative: this.getRelationshipDefinition('InternalEnvironments:SalesRepresentative'),
                    ShiftedVatGroup: this.getRelationshipDefinition('InternalEnvironments:ShiftedVatGroup'),
                    ProductVatGroup: this.getRelationshipDefinition('InternalEnvironments:ProductVatGroup'),
                    PartnerInvoiceTemplate: this.getRelationshipDefinition('InternalEnvironments:PartnerInvoiceTemplate'),
                    PartnerEmailInvoiceTemplate: this.getRelationshipDefinition('InternalEnvironments:PartnerEmailInvoiceTemplate'),
                    PartnerPriceList: this.getRelationshipDefinition('InternalEnvironments:PartnerPriceList'),
                    DataPackReferences: this.getRelationshipDefinition('InternalEnvironments:DataPackReferences'),
                    Entities: this.getRelationshipDefinition('InternalEnvironment:Entities'),
                }
            },
            LeexiCall:
            {
                Type: this.getType('LeexiCall'),
                Field:
                {
                    Title: this.getField('LeexiCall', 'LeexiCall.Title'),
                    Description: this.getField('LeexiCall', 'LeexiCall.Description'),
                },
                RelationshipDefinition:
                {
                    Participants: this.getRelationshipDefinition('LeexiCall:Participants'),
                    Completions: this.getRelationshipDefinition('LeexiCall:Completions'),
                },
            },
            LeexiCallParticipant:
            {
                Type: this.getType('LeexiCallParticipant'),
                Field:
                    {
                        Email: this.getField('LeexiCallParticipant', 'LeexiCallParticipant.EmailAddress'),
                        PhoneNumber: this.getField('LeexiCallParticipant', 'LeexiCallParticipant.PhoneNumber'),
                    },
                RelationshipDefinition:
                    {
                        Relation: this.getRelationshipDefinition('Relationship:LeexiCallParticipants'),
                        Contact: this.getRelationshipDefinition('LeexiCall:Completions'),
                    },
            },
            LeexiCallCompletion:
                {
                    Type: this.getType('LeexiCallCompletion'),
                    Field:
                        {
                            PromptTitle: this.getField('LeexiCallCompletion', 'LeexiCallCompletion.PromptTitle'),
                            Text: this.getField('LeexiCallCompletion', 'LeexiCallCompletion.Text'),
                        }
                },
        };
    }

    // ------------------------ Constructor -------------------------

    constructor(entityTypeStore: EntityTypeStore)
    {
        this.entityTypeStore = entityTypeStore;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    public getType(code: string): EntityType
    {
        return this.entityTypeStore.getTypeByCode(code);
    }

    public getField(entityTypeCode: string, fieldCode: string): EntityField
    {
        const type = this.getType(entityTypeCode);

        if (type)
        {
            return this.entityTypeStore.getFieldByCode(fieldCode);
        }
        else
        {
            return undefined;
        }
    }

    public getRelationshipDefinition(code: string): EntityRelationshipDefinition
    {
        return this.entityTypeStore.getRelationshipDefinitionByCode(code);
    }

    // ----------------------- Private logic ------------------------
}
