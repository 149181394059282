import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Entity } from '../../../../../../../../../@Api/Model/Implementation/Entity';
import { observer, useComputed } from 'mobx-react-lite';
import Card from '../../../../../../../../../@Future/Component/Generic/Card/Card';
import { EntityExpansionBuilder } from '../../../../../../Selection/Builder/EntityExpansionBuilder';
import useTypes from '../../../../../../Type/Api/useTypes';
import { EntityPath } from '../../../../../../Path/@Model/EntityPath';
import Centered from '../../../../../../../../../@Future/Component/Generic/Centered/Centered';
import Loader from '../../../../../../../../../@Future/Component/Generic/Loader/Loader';
import { Table } from '@material-ui/core';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import { createTransactionalModel, TransactionalModel } from '../../../../../../../../../@Util/TransactionalModelV2/Model/TransactionalModel';
import EntityTypeContext from '../../../../../../Type/EntityTypeContext';
import useFreshTransactionalEntity from '../../../../../../../../../@Api/Entity/Bespoke/useFreshTransactionalEntity';
import ExternalCost from './ExternalCost/ExternalCost';
import HoverCardBottom from '../../../../../../../../../@Future/Component/Generic/Card/HoverCardBottom/HoverCardBottom';
import equalsEntity from '../../../../../../../../../@Api/Entity/Bespoke/equalsEntity';
import LocalizedText from '../../../../../../../Localization/LocalizedText/LocalizedText';

export interface ExternalCostsProps
{
    entity: Entity;
    milestone?: Entity;
}

const ExternalCosts: React.FC<ExternalCostsProps> =
    props =>
    {
        const types = useTypes();
        const entityTypeStore = useContext(EntityTypeContext);

        const entity = useFreshTransactionalEntity(props.entity);

        const [ isLoading, setLoading ] = useState(true);
        const [ externalCosts, setExternalCosts ] = useState<TransactionalModel<Entity>[]>([]);

        const pathToExternalCosts =
            useMemo(
                () =>
                    EntityPath.fromEntity(entity)
                        .joinTo(
                            types.Activity.Project.RelationshipDefinition.ExternalCosts,
                            false),
                [
                    entity,
                    types
                ]);

        const onAdd =
            useCallback(
                () =>
                {
                    const newEntity =
                        createTransactionalModel(
                            new Entity(types.ExternalCost.Type)
                                .initialize(entityTypeStore));

                    if (props.milestone)
                    {
                        newEntity.updateRelationship(
                            true,
                            types.Milestone.RelationshipDefinition.ExternalCosts,
                            createTransactionalModel(props.milestone));
                    }

                    newEntity.updateRelationship(
                        true,
                        types.Activity.Project.RelationshipDefinition.ExternalCosts,
                        createTransactionalModel(entity));

                    setExternalCosts([
                        ...externalCosts,
                        newEntity
                    ]);
                },
                [
                    types,
                    entityTypeStore,
                    entity,
                    setExternalCosts,
                    externalCosts,
                    props.milestone
                ]);

        useEffect(
            () =>
            {
                setLoading(true);

                new EntityExpansionBuilder(
                    entity.entityType,
                    [
                        entity
                    ],
                    [
                        pathToExternalCosts,
                        pathToExternalCosts
                            .joinTo(
                                types.ExternalCost.RelationshipDefinition.VatGroup,
                                false),
                        pathToExternalCosts
                            .joinTo(
                                types.Milestone.RelationshipDefinition.ExternalCosts,
                                true)
                    ]).expand()
                    .then(
                        () =>
                        {
                            setLoading(false);

                            const externalCosts =
                                pathToExternalCosts
                                    .traverseEntity(entity)
                                    .map(
                                        timeRegistration =>
                                            createTransactionalModel(timeRegistration));

                            if (externalCosts.length === 0)
                            {
                                // onAdd();
                            }
                            else
                            {
                                setExternalCosts(externalCosts);
                            }
                        });
            },
            [
                entity,
                pathToExternalCosts,
                setExternalCosts,
                setLoading,
                types,
                props.milestone
            ]);

        const visibleExternalCosts =
            useComputed(
                () =>
                    externalCosts
                        .filter(
                            timeRegistrations =>
                                !timeRegistrations.isDeleted)
                        .filter(
                            timeRegistration =>
                                props.milestone
                                    ?
                                        equalsEntity(
                                            timeRegistration.getRelatedEntityByDefinition(
                                                true,
                                                types.Milestone.RelationshipDefinition.ExternalCosts),
                                            props.milestone)
                                    :
                                        true),
                [
                    externalCosts,
                    types,
                    props.milestone
                ]);

        if (isLoading)
        {
            return <Card
                inset
            >
                <Centered
                    horizontal
                >
                    <Loader />
                </Centered>
            </Card>
        }
        else
        {
            return <Card>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell
                                {...{ width: '40%' }}
                            >
                                <LocalizedText
                                    code="Generic.Description"
                                    value="Omschrijving"
                                />
                            </TableCell>
                            <TableCell
                                {...{ width: '15%' }}
                            >
                                <LocalizedText
                                    code="Generic.Amount"
                                    value="Bedrag"
                                />
                            </TableCell>
                            <TableCell
                                {...{ width: '15%' }}
                            >
                                <LocalizedText
                                    code="Generic.VAT"
                                    value="BTW"
                                />
                            </TableCell>
                            {
                                !props.milestone &&
                                    <TableCell
                                        {...{ width: '25%' }}
                                    >
                                        <LocalizedText
                                            code="Generic.Milestone"
                                            value="Milestone"
                                        />
                                    </TableCell>
                            }
                            <TableCell
                                {...{ width: '5%' }}
                            >

                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            visibleExternalCosts
                                .map(
                                    externalCost =>
                                        <ExternalCost
                                            key={externalCost.uuid}
                                            entity={externalCost}
                                            milestone={props.milestone}
                                        />)
                        }
                    </TableBody>
                </Table>
                <HoverCardBottom
                    onClick={onAdd}
                >
                    <LocalizedText
                        code="ExternalCosts.AddButton"
                        value="+ Externe kosten"
                    />
                </HoverCardBottom>
            </Card>;
        }
    };

export default observer(ExternalCosts);
