import React from 'react';
import { observer } from 'mobx-react-lite';
import { Entity } from '../../../../@Api/Model/Implementation/Entity';
import Attachment from './Attachment/Attachment';
import ViewGroup from '../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import { CommitContext } from '../../../../@Api/Entity/Commit/Context/CommitContext';

export interface AttachmentListProps
{
    attachments: Entity[];
    commitContext?: CommitContext;
    readOnly?: boolean;
}

const AttachmentList: React.FC<AttachmentListProps> =
    props =>
    {
        return <ViewGroup
            orientation="horizontal"
            spacing={10}
            wrap
        >
            {
                props.attachments.map(
                    (attachment) =>
                        <ViewGroupItem
                            key={attachment.uuid}
                        >
                            <Attachment
                                key={attachment.uuid}
                                attachment={attachment}
                                commitContext={props.commitContext}
                                readOnly={props.readOnly}
                            />
                        </ViewGroupItem>)
            }
        </ViewGroup>;
    };

export default observer(AttachmentList);
