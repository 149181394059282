import React from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import ViewGroupItem from '../../../../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import useTypes from '../../../../../../../../../Type/Api/useTypes';
import ViewGroup from '../../../../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import FieldView from '../../../../../../../../../Field/View/FieldView';
import { FileValue } from '../../../../../../../../../../DataObject/Type/File/FileValue';
import styles from './Attachment.module.scss';
import { ContentProps } from '../Content';
import Base from '../Base/Base';

export interface AttachmentProps extends ContentProps
{

}

const Attachment: React.FC<AttachmentProps> =
    props =>
    {
        const types = useTypes();
        const filesize =
            useComputed(
                () =>
                {
                    const value = props.entity.getDataObjectValueByField(types.Attachment.Field.File);

                    if (value && value.getValue() instanceof FileValue)
                    {
                        return (value.getValue() as FileValue).humanReadableFilesize;
                    }
                    else
                    {
                        return '-';
                    }
                },
                [
                    props.entity,
                    types
                ]);

        return <Base
            {...props}
            noInputGroup
            showAttachments={false}
        >
            <ViewGroup
                orientation="horizontal"
                spacing={40}
                alignment="center"
            >
                <ViewGroupItem
                    ratio={1}
                >
                    <FieldView
                        entity={props.entity}
                        field={types.Attachment.Field.File}
                    />
                </ViewGroupItem>
                <ViewGroupItem>
                    <div
                        className={styles.fileSize}
                    >
                        {filesize}
                    </div>
                </ViewGroupItem>
            </ViewGroup>
        </Base>;
    };

export default observer(Attachment);
