import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Entity } from '../../../../../../../../@Api/Model/Implementation/Entity';
import { observer, useComputed } from 'mobx-react-lite';
import Card from '../../../../../../../../@Future/Component/Generic/Card/Card';
import CardInset from '../../../../../../../../@Future/Component/Generic/Card/CardInset';
import styles from './TimeSheet.module.scss';
import useTypes from '../../../../../Type/Api/useTypes';
import Centered from '../../../../../../../../@Future/Component/Generic/Centered/Centered';
import Loader from '../../../../../../../../@Future/Component/Generic/Loader/Loader';
import { getModel } from '../../../../../../../../@Util/TransactionalModelV2/index';
import CurrentUserContext from '../../../../../../User/CurrentUserContext';
import moment from 'moment';
import Divider from '../../../../../../../../@Future/Component/Generic/Divider/Divider';
import HoverCardMiddle from '../../../../../../../../@Future/Component/Generic/Card/HoverCardMiddle/HoverCardMiddle';
import TabBar from '../../../../../../../../@Future/Component/Generic/TabBar/TabBar';
import Tab from '../../../../../../../../@Future/Component/Generic/TabBar/Tab/Tab';
import useAggregateResult from '../../../../../Selection/Hooks/useAggregateResult';
import { Aggregate } from '../../../../../../DataObject/Model/Aggregate';
import { DataObject } from '../../../../../../DataObject/Model/DataObject';
import CloseButton from '../../../../../../../../@Future/Component/Generic/Button/Variant/CloseButton/CloseButton';
import useClock from '../../../../../../../../@Util/Date/useClock';
import getDurationLabel from '../../../../../../../../@Util/Date/getDurationLabel';
import { TabLabel } from '../../../../../../../../@Future/Component/Generic/TabBar/Tab/TabLabel/TabLabel';
import ViewGroup from '../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import LocalizedText from '../../../../../../Localization/LocalizedText/LocalizedText';
import usePaginatedSelection from '../../../../../View/Api/usePaginatedSelection';
import useResults from '../../../../../Selection/Hooks/useResults';
import TimeSheetProcessingOptions from './ProcessingOptions/TimeSheetProcessingOptions';
import useDividerGlue from '../../../../../../../../@Future/Component/Generic/ViewGroup/Api/useDividerGlue';
import TimeSheetTable, { StartDateSortDirection } from './Table/TimeSheetTable';
import useLocalSetting from '../../../../../../Setting/Api/useLocalSetting';
import { useNewCommitContext } from '../../../../../../../../@Api/Entity/Commit/Context/Api/useNewCommitContext';
import { constructEntityOfType } from '../../../../../../../../@Api/Entity/Commit/Context/Api/Compatibility/constructEntityOfType';
import { updateRelationship } from '../../../../../../../../@Api/Entity/Commit/Context/Api/Compatibility/updateRelationship';
import { setValueByFieldInEntity } from '../../../../../../../../@Api/Entity/Commit/Context/Api/Compatibility/setValueByFieldInEntity';
import equalsEntity from '../../../../../../../../@Api/Entity/Bespoke/equalsEntity';
import initializeDefaultRelationships from '../../../../../Constructor/Api/initializeDefaultRelationships';

export interface TimeSheetProps
{
    relationship: Entity;
    activity?: Entity;
    milestone?: Entity;
    onClose?: () => void;
}

const TimeSheet: React.FC<TimeSheetProps> =
    props =>
    {
        const { relationship, activity, milestone } = props;
        const types = useTypes();
        const commitContext = useNewCommitContext();

        const relationshipDefinition =
            useMemo(
                () =>
                {
                    if (milestone)
                    {
                        return types.Milestone.RelationshipDefinition.TimeRegistrations;
                    }
                    else if (activity)
                    {
                        return types.Activity.RelationshipDefinition.TimeRegistrations;
                    }
                    else if (relationship.entityType.isA(types.Relationship.Person.Contact.Employee.Type))
                    {
                        return types.Relationship.Person.Contact.Employee.RelationshipDefinition.TimeRegistrations;
                    }
                    else
                    {
                        return types.Relationship.RelationshipDefinition.TimeRegistrations;
                    }
                },
                [
                    types,
                    relationship,
                    activity,
                    milestone
                ]);

        const entity =
            useMemo(
                () =>
                    milestone || activity || relationship,
                [
                    relationship,
                    activity,
                    milestone
                ]);

        const currentUserStore = useContext(CurrentUserContext);

        const [ tab, setTab ] = useState(1);
        const [ constructingEntity, setConstructingEntity ] = useState<Entity | undefined>(undefined);

        const [ startDateSortDirection, setStartDateSortDirection ] =
            useLocalSetting<StartDateSortDirection>(
                'TimeSheet.StartDateSortDirection',
                'Ascending');

        const onAdd =
            useCallback(
                () =>
                {
                    const newEntity =
                        constructEntityOfType(
                            types.TimeRegistration.Type,
                            commitContext
                        );

                    updateRelationship(
                        newEntity,
                        true,
                        types.Relationship.RelationshipDefinition.TimeRegistrations,
                        relationship,
                        commitContext
                    );

                    if (activity)
                    {
                        updateRelationship(
                            newEntity,
                            true,
                            types.Activity.RelationshipDefinition.TimeRegistrations,
                            activity,
                            commitContext
                        );
                    }

                    if (milestone)
                    {
                        updateRelationship(
                            newEntity,
                            true,
                            types.Milestone.RelationshipDefinition.TimeRegistrations,
                            milestone,
                            commitContext
                        );
                    }

                    updateRelationship(
                        newEntity,
                        true,
                        types.Relationship.Person.Contact.Employee.RelationshipDefinition.TimeRegistrations,
                        currentUserStore.employeeEntity,
                        commitContext
                    );

                    setValueByFieldInEntity(
                        newEntity,
                        types.TimeRegistration.Field.StartDate,
                        new Date(),
                        commitContext
                    );

                    setValueByFieldInEntity(
                        newEntity,
                        types.TimeRegistration.Field.EndDate,
                        moment(new Date()).add(1, 'hour').toDate(),
                        commitContext
                    );

                    initializeDefaultRelationships(newEntity)
                        .then(
                            () =>
                            {
                                setConstructingEntity(newEntity)
                                if (newEntity.isValid)
                                {
                                    commitContext
                                        .commit()
                                        .then();
                                }
                            }
                        );

                },
                [
                    types,
                    relationship,
                    activity,
                    milestone,
                    currentUserStore,
                    setConstructingEntity,
                    commitContext,
                ]);

        const [ pages, hasMore, _loadMore, isLoading ] =
            usePaginatedSelection(
                types.TimeRegistration.Type,
                (builder, rootPath) =>
                    builder
                        .where(
                            cb =>
                                cb.relatedToEntity(
                                    rootPath.joinTo(
                                        relationshipDefinition,
                                        true),
                                    entity))
                        .where(
                            cb =>
                                cb.eq(
                                    rootPath.field(types.TimeRegistration.Field.IsProcessed),
                                    undefined,
                                    tab === 2))
                        .if(
                            () =>
                                tab === 0,
                            () =>
                                builder.where(
                                    cb =>
                                        cb.isNotDefined(rootPath.field(types.TimeRegistration.Field.EndDate))))
                        .if(
                            () =>
                                tab !== 0,
                            () =>
                                builder.where(
                                    cb =>
                                        cb.isDefined(rootPath.field(types.TimeRegistration.Field.EndDate))))
                        .join(
                            rootPath
                                .joinTo(
                                    types.Relationship.RelationshipDefinition.TimeRegistrations,
                                    true))
                        .join(
                            rootPath
                                .joinTo(
                                    types.Activity.RelationshipDefinition.TimeRegistrations,
                                    true))
                        .if(
                            () =>
                                types.Milestone.RelationshipDefinition.TimeRegistrations !== undefined,
                            () =>
                                builder.join(
                                    rootPath
                                        .joinTo(
                                            types.Milestone.RelationshipDefinition.TimeRegistrations,
                                            true)))
                        .join(
                            rootPath
                                .joinTo(
                                    types.TimeRegistration.RelationshipDefinition.Activity,
                                    false)
                                .joinTo(
                                    types.TimeRegistrationActivity.RelationshipDefinition.Product,
                                    false))
                        .join(
                            rootPath
                                .joinTo(
                                    types.Relationship.Person.Contact.Employee.RelationshipDefinition.TimeRegistrations,
                                    true))
                        .join(
                            rootPath
                                .joinTo(
                                    types.ProductLine.RelationshipDefinition.BilledTimeRegistrations,
                                    true)
                                .joinTo(
                                    types.Activity.RelationshipDefinition.ProductLines,
                                    true))
                        .join(
                            rootPath
                                .joinTo(
                                    types.ProductLine.RelationshipDefinition.BilledTimeRegistrations,
                                    true)
                                .joinTo(
                                    types.ProductLine.RelationshipDefinition.InvoiceProductLine,
                                    false)
                                .joinTo(
                                    types.Activity.RelationshipDefinition.ProductLines,
                                    true))
                        .orderBy(
                            rootPath.field(types.TimeRegistration.Field.StartDate),
                            startDateSortDirection === 'Ascending'),
                [
                    types,
                    tab,
                    entity,
                    relationshipDefinition,
                    startDateSortDirection
                ]);

        const loadMore =
            useCallback(
                () =>
                {
                    setConstructingEntity(undefined);

                    return _loadMore();
                },
                [
                    setConstructingEntity,
                    _loadMore
                ]);
        const loadAll =
            useCallback(
                async () =>
                {
                    while (await _loadMore())
                    {
                    }
                },
                [
                    _loadMore
                ]
            );

        const [ selectedLines, setSelectedLines ] = useState<Entity[]>([]);

        useEffect(
            () =>
            {
                setSelectedLines([]);
            },
            [
                relationship,
                activity,
                milestone,
                setSelectedLines
            ]);

        const activeTimeRegistrations =
            useResults(
                types.TimeRegistration.Type,
                (builder, rootPath) =>
                    builder
                        .where(
                            cb =>
                                cb.relatedToEntity(
                                    rootPath.joinTo(
                                        relationshipDefinition,
                                        true),
                                    entity))
                        .where(
                            cb =>
                                cb.isNotDefined(rootPath.field(types.TimeRegistration.Field.EndDate)))
                        .join(
                            rootPath
                                .joinTo(
                                    types.Relationship.RelationshipDefinition.TimeRegistrations,
                                    true))
                        .join(
                            rootPath
                                .joinTo(
                                    types.Activity.RelationshipDefinition.TimeRegistrations,
                                    true))
                        .if(
                            () =>
                                types.Milestone.RelationshipDefinition.TimeRegistrations !== undefined,
                            () =>
                                builder.join(
                                    rootPath
                                        .joinTo(
                                            types.Milestone.RelationshipDefinition.TimeRegistrations,
                                            true)))
                        .join(
                            rootPath
                                .joinTo(
                                    types.TimeRegistration.RelationshipDefinition.Activity,
                                    false)
                                .joinTo(
                                    types.TimeRegistrationActivity.RelationshipDefinition.Product,
                                    false))
                        .join(
                            rootPath
                                .joinTo(
                                    types.Relationship.Person.Contact.Employee.RelationshipDefinition.TimeRegistrations,
                                    true))
                        .orderBy(
                            rootPath.field(types.TimeRegistration.Field.StartDate),
                            startDateSortDirection === 'Ascending'),
                [
                    types,
                    tab,
                    entity,
                    relationshipDefinition,
                    startDateSortDirection
                ])

        useEffect(
            () =>
            {
                if (activeTimeRegistrations?.length > 0)
                {
                    setTab(0);
                }
                else
                {
                    setTab(1);
                }
            },
            [
                activeTimeRegistrations,
                setTab
            ]);

        const now = useClock(activeTimeRegistrations?.length > 0);

        const activeTimeLabel =
            useComputed(
                () =>
                {
                    const durationInMillis =
                        (activeTimeRegistrations || [])
                            .map(
                                timeRegistration =>
                                    moment(now).diff(moment(timeRegistration.getObjectValueByField(types.TimeRegistration.Field.StartDate)), 'milliseconds'))
                            .reduce((a, b) => a + b, 0);

                    return getDurationLabel(durationInMillis, true);
                },
                [
                    activeTimeRegistrations,
                    now,
                    types
                ]);

        const unprocessedTimeSpentResult =
            useAggregateResult(
                types.TimeRegistration.Type,
                (builder, rootPath) =>
                    builder
                        .where(
                            cb =>
                                cb.relatedToEntity(
                                    rootPath.joinTo(
                                        relationshipDefinition,
                                        true),
                                    entity))
                        .where(
                            cb =>
                                cb.isDefined(
                                    rootPath.field(types.TimeRegistration.Field.EndDate)))
                        .where(
                            cb =>
                                cb.eq(
                                    rootPath.field(types.TimeRegistration.Field.IsProcessed),
                                    undefined,
                                    false))
                        .aggregateOn(
                            rootPath.field(types.TimeRegistration.Field.DurationInHours),
                            undefined,
                            Aggregate.Sum),
                [
                    types,
                    relationshipDefinition,
                    entity
                ]);

        const unprocessedTimeSpent =
            useComputed(
                () =>
                {
                    if (unprocessedTimeSpentResult && !unprocessedTimeSpentResult.aggregates[0].isEmpty)
                    {
                        return unprocessedTimeSpentResult.aggregates[0];
                    }
                    else
                    {
                        return DataObject.constructFromTypeIdAndValue('Number', 0);
                    }
                },
                [
                    unprocessedTimeSpentResult
                ]);

        const unprocessedTimeSpentLabel =
            useComputed(
                () =>
                    getDurationLabel(unprocessedTimeSpent.value * 3600 * 1000),
                [
                    unprocessedTimeSpent
                ]);

        const processedTimeSpentResult =
            useAggregateResult(
                types.TimeRegistration.Type,
                (builder, rootPath) =>
                    builder
                        .where(
                            cb =>
                                cb.relatedToEntity(
                                    rootPath.joinTo(
                                        relationshipDefinition,
                                        true),
                                    entity))
                        .where(
                            cb =>
                                cb.isDefined(
                                    rootPath.field(types.TimeRegistration.Field.EndDate)))
                        .where(
                            cb =>
                                cb.eq(
                                    rootPath.field(types.TimeRegistration.Field.IsProcessed),
                                    undefined,
                                    true))
                        .aggregateOn(
                            rootPath.field(types.TimeRegistration.Field.DurationInHours),
                            undefined,
                            Aggregate.Sum),
                [
                    types,
                    relationshipDefinition,
                    entity
                ]);

        const processedTimeSpent =
            useComputed(
                () =>
                {
                    if (processedTimeSpentResult && !processedTimeSpentResult.aggregates[0].isEmpty)
                    {
                        return processedTimeSpentResult.aggregates[0];
                    }
                    else
                    {
                        return DataObject.constructFromTypeIdAndValue('Number', 0);
                    }
                },
                [
                    processedTimeSpentResult
                ]);

        const processedTimeSpentLabel =
            useComputed(
                () =>
                    getDurationLabel(processedTimeSpent.value * 3600 * 1000),
                [
                    processedTimeSpent
                ]);

        const showActivity =
            useMemo(
                () =>
                    activity === undefined,
                [
                    activity
                ]);

        const canHaveMilestone =
            useComputed(
                () =>
                    activity?.entityType.isA(types.Activity.Project.Type),
                [
                    activity,
                    types
                ]);

        const dividerGlue = useDividerGlue();
        const showBillingActivity =
            useMemo(
                () =>
                    tab === 2 && types.Activity.Invoice.Type !== undefined,
                [
                    tab,
                    types
                ]);

        const filter =
            useCallback(
                (timeRegistration: Entity) =>
                {
                    if (props.milestone
                        && !equalsEntity(
                            timeRegistration.getRelatedEntityByDefinition(
                                true,
                                types.Milestone.RelationshipDefinition.TimeRegistrations,
                                commitContext
                            ),
                            props.milestone
                        )
                    )
                    {
                        return false;
                    }

                    const isEnded = getModel(timeRegistration).hasValueForField(types.TimeRegistration.Field.EndDate);

                    if (tab === 0)
                    {
                        return !isEnded;
                    }
                    else if (tab === 1)
                    {
                        return !getModel(timeRegistration).getObjectValueByField(types.TimeRegistration.Field.IsProcessed)
                            && (timeRegistration.isNew() || isEnded);
                    }
                    else
                    {
                        return getModel(timeRegistration).getObjectValueByField(types.TimeRegistration.Field.IsProcessed)
                            && isEnded;
                    }
                },
                [
                    props.milestone,
                    types,
                    tab,
                    commitContext,
                ]);

        if (isLoading && false)
        {
            return <Card
                inset
            >
                <Centered
                    horizontal
                >
                    <Loader />
                </Centered>
            </Card>
        }
        else
        {
            return <ViewGroup
                orientation="vertical"
                spacing={15}
            >
                <ViewGroupItem>
                    <Card>
                        <div
                            className={styles.header}
                        >
                            <TabBar
                                value={tab}
                            >
                                {
                                    activeTimeRegistrations?.length > 0 &&
                                        <Tab
                                            value={0}
                                            onClick={setTab}
                                        >
                                            <TabLabel
                                                label={
                                                    <LocalizedText
                                                        code="TimeSheet.Active"
                                                        value="Lopend"
                                                    />
                                                }
                                                secondary={`${activeTimeLabel} uur`}
                                            />
                                        </Tab>
                                }
                                <Tab
                                    value={1}
                                    onClick={setTab}
                                >
                                    <TabLabel
                                        label={
                                            <LocalizedText
                                                code="TimeSheet.ToBeProcessed"
                                                value="Te verwerken"
                                            />
                                        }
                                        secondary={`${unprocessedTimeSpentLabel} uur`}
                                    />
                                </Tab>
                                <Tab
                                    value={2}
                                    onClick={setTab}
                                >
                                    <TabLabel
                                        label={
                                            <LocalizedText
                                                code="TimeSheet.Processed"
                                                value="Verwerkt"
                                            />
                                        }
                                        secondary={
                                            <LocalizedText
                                                code="Generic.DurationInHours"
                                                value="${duration} uur"
                                                duration={processedTimeSpentLabel}
                                            />
                                        }
                                    />
                                </Tab>
                            </TabBar>
                            {
                                props.onClose &&
                                    <div
                                        className={styles.menu}
                                    >
                                        <CardInset>
                                            <CloseButton
                                                onClick={props.onClose}
                                            />
                                        </CardInset>
                                    </div>
                            }
                        </div>
                        <div
                            className={styles.tableContainer}
                        >
                            <TimeSheetTable
                                allowSelectAll={tab === 1}
                                milestone={props.milestone}
                                showActivity={showActivity}
                                showBillingActivity={showBillingActivity}
                                canHaveMilestone={canHaveMilestone}
                                selectedLines={selectedLines}
                                onChangeSelectedLines={setSelectedLines}
                                constructingEntity={constructingEntity}
                                onChangeConstructingEntity={setConstructingEntity}
                                pages={pages}
                                hasMore={hasMore}
                                filter={filter}
                                showsActiveTimeRegistrations={tab === 0}
                                startDateSortDirection={startDateSortDirection}
                                onChangeStartDateSortDirection={setStartDateSortDirection}
                                commitContext={commitContext}
                            />
                        </div>
                        {
                            isLoading &&
                                <CardInset>
                                    <Centered
                                        horizontal
                                    >
                                        <Loader />
                                    </Centered>
                                </CardInset>
                        }
                        {
                            hasMore &&
                                <>
                                    <HoverCardMiddle
                                        onClick={loadMore}
                                        disabled={isLoading}
                                    >
                                        <LocalizedText
                                            code="Generic.LoadMore"
                                            value="Meer laden"
                                        />...
                                    </HoverCardMiddle>
                                    <Divider />
                                    <HoverCardMiddle
                                        onClick={loadAll}
                                        disabled={isLoading}
                                    >
                                        <LocalizedText
                                            code="Generic.LoadAll"
                                            value="Alles laden"
                                        />...
                                    </HoverCardMiddle>
                                    <Divider />
                                </>
                        }
                        <ViewGroup
                            orientation="vertical"
                            spacing={0}
                            glue={dividerGlue}
                        >
                            {
                                tab === 1 &&
                                    <ViewGroupItem>
                                        <HoverCardMiddle
                                            onClick={onAdd}
                                            disabled={constructingEntity?.isNew()}
                                        >
                                            + {types.TimeRegistration.Type.getName()}
                                        </HoverCardMiddle>
                                    </ViewGroupItem>
                            }
                            {
                                (tab === 1 || tab === 2) &&
                                    <ViewGroupItem>
                                        <TimeSheetProcessingOptions
                                            relationship={relationship}
                                            activity={activity}
                                            milestone={milestone}
                                            selectedLines={selectedLines}
                                            onChangeSelectedLines={setSelectedLines}
                                        />
                                    </ViewGroupItem>
                            }
                        </ViewGroup>
                    </Card>
                </ViewGroupItem>
            </ViewGroup>;
        }
    };

export default observer(TimeSheet);
