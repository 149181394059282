import { action, IObservableArray } from 'mobx';
import { Entity } from '../../../@Api/Model/Implementation/Entity';
import { EntityEvent } from '../../../@Api/Model/Implementation/EntityEvent';
import { injectWithQualifier } from '../../../@Util/DependencyInjection/index';
import { ApiClient } from '../../../@Service/ApiClient/ApiClient';
import { EntityTypeStore } from '../../Domain/Entity/Type/EntityTypeStore';
import { DataObject } from '../../Domain/DataObject/Model/DataObject';
import { BaseStore } from '../../../@Framework/Store/BaseStore';
import { EntitySelectionResult } from '../../Domain/Entity/Selection/Model/EntitySelectionResult';
import { EntitySelectionAggregateResult } from '../../Domain/Entity/Selection/Model/EntitySelectionAggregateResult';
import { EntityQuery } from '../../Domain/Entity/Selection/Model/EntityQuery';
import { Comparator } from '../../Domain/DataObject/Model/Comparator';
import { EntityMatchResult } from './EntityMatchResult';
import { OldComparisonPredicate } from '../../Domain/Predicate/Type/Comparison/OldComparisonPredicate';
import { PredicateTypeStore } from '../../Domain/Predicate/PredicateTypeStore';
import { EntityFieldComputation } from '../../Domain/Computation/Type/Entity/Field/EntityFieldComputation';
import { ComputationTypeStore } from '../../Domain/Computation/ComputationTypeStore';
import { DataObjectStore } from '../../Domain/DataObject/DataObjectStore';
import { ConstantComputation } from '../../Domain/Computation/Type/Constant/ConstantComputation';
import { EntityQueryMatchResult } from './EntityQueryMatchResult';
import { Aggregate } from '../../Domain/DataObject/Model/Aggregate';
import { EntitySelectionAggregate } from '../../Domain/Entity/Selection/Model/EntitySelectionAggregate';
import { consoleLog } from '../../../@Future/Util/Logging/consoleLog';
import { CurrentUserStore } from '../../Domain/User/CurrentUserStore';
import equalsEntity from '../../../@Api/Entity/Bespoke/equalsEntity';
import { EntitySelectionListResult } from '../../Domain/Entity/Selection/Model/EntitySelectionListResult';
import { BaseEntityQuery } from '../../Domain/Entity/Selection/Model/BaseEntityQuery';
import { createEntityExpressionComparator } from '../../Domain/Entity/List/Comparator/EntityExpressionComparator';
import { DataComparator } from '../../Generic/List/V2/ListStore';
import ParameterDictionary from '../../../@Api/Automation/Parameter/ParameterDictionary';
import ParameterAssignment from '../../../@Api/Automation/Parameter/ParameterAssignment';
import EntityValue from '../../../@Api/Automation/Value/EntityValue';
import EmptyValue from '../../../@Api/Automation/Value/EmptyValue';

type EntityQueryResult = EntitySelectionListResult | EntitySelectionAggregateResult;
type QueryResult = ListQueryResult | AggregateQueryResult;

interface ListQueryResult
{
    type: 'List';
    page: ListQueryPage;
}

interface ListQueryPage
{
    offset: number;
    limit: number;
    results: IObservableArray<EntitySelectionResult>;
    numberOfRecords: number;
}

interface AggregateQueryResult
{
    type: 'Aggregate';
    result: EntitySelectionAggregateResult;
}

export class EntityQueryCacheService extends BaseStore
{
    // ------------------------ Dependencies ------------------------

    @injectWithQualifier('ApiClient') apiClient: ApiClient;
    @injectWithQualifier('EntityTypeStore') entityTypeStore: EntityTypeStore;
    @injectWithQualifier('PredicateTypeStore') predicateTypeStore: PredicateTypeStore;
    @injectWithQualifier('ComputationTypeStore') computationTypeStore: ComputationTypeStore;
    @injectWithQualifier('DataObjectStore') dataObjectStore: DataObjectStore;
    @injectWithQualifier('CurrentUserStore') currentUserStore: CurrentUserStore;

    // ------------------------- Properties -------------------------

    baseQueryByHash = new Map<number, BaseEntityQuery[]>();
    promiseByQuery = new Map<EntityQuery, Promise<EntityQueryResult>>();
    resultByQuery = new Map<EntityQuery, QueryResult>();

    // ------------------------ Constructor -------------------------

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    clear()
    {
        this.baseQueryByHash.clear();
        this.promiseByQuery.clear();
        this.resultByQuery.clear();

        return Promise.resolve();
    }

    getOrSetCachedQuery(query: EntityQuery)
    {
        if (!query.isCached)
        {
            return query;
        }

        const cachedBaseQuery = this.getOrSetCachedBaseQuery(query);
        let cachedQuery =
            cachedBaseQuery.queries
                .find(
                    checkQuery =>
                        checkQuery.equals(query)
                );

        if (!cachedQuery)
        {
            cachedBaseQuery.queries.push(query);
            cachedQuery = query;
        }

        return cachedQuery;
    }

    getOrSetCachedBaseQuery(query: EntityQuery)
    {
        const cachedQuery = this.getCachedBaseQuery(query);

        if (cachedQuery)
        {
            return cachedQuery;
        }
        else
        {
            const baseQuery =
                new BaseEntityQuery(
                    query.type,
                    query.selection,
                    query.ordering,
                    query.aggregates,
                    []
                );
            const hash = baseQuery.hashCode;

            if (this.baseQueryByHash.has(hash))
            {
                this.baseQueryByHash.get(hash).push(baseQuery);
            }
            else
            {
                this.baseQueryByHash.set(hash, [baseQuery]);
            }

            return baseQuery;
        }
    }

    setPromiseByQuery(query: EntityQuery,
                      promise: Promise<EntityQueryResult>)
    {
        this.promiseByQuery.set(
            query,
            promise);
    }

    clearPromiseByQuery(query: EntityQuery)
    {
        this.promiseByQuery.delete(query);
    }

    registerListQueryResult(
        query: EntityQuery,
        offset: number,
        limit: number,
        result: IObservableArray<EntitySelectionResult>,
        numberOfRecords: number
    )
    {
        if (query.isCached)
        {
            const page = {
                offset,
                limit,
                results: result,
                numberOfRecords
            };

            this.resultByQuery.set(
                query,
                {
                    type: 'List',
                    page,
                }
            );
        }
    }

    registerAggregateQueryResult(query: EntityQuery,
                                 result: EntitySelectionAggregateResult)
    {
        if (query.isCached)
        {
            this.resultByQuery.set(
                query,
                {
                    type: 'Aggregate',
                    result: result
                });
        }
    }

    @action.bound
    addToQueryResultSet(
        baseQuery: BaseEntityQuery,
        entity: Entity,
        updatedAggregates: Set<DataObject>,
        postQueryResult: EntityQueryMatchResult
    )
    {
        // log('add to query', query.selection.rootNode.entityType.code, entity, query, resultSet);

        if (baseQuery.type === 'Aggregate')
        {
            baseQuery.queries
                .forEach(
                    query =>
                    {
                        const result = this.getAggregateResultByQuery(query);

                        this.mutateAggregate(
                            query,
                            result,
                            0,
                            updatedAggregates,
                            undefined,
                            postQueryResult
                        );
                    }
                );
        }
        else if (baseQuery.type === 'List')
        {
            if (!this.findQueryResultContainingEntity(baseQuery, entity))
            {
                this.insertEntityInCachedResults(baseQuery, entity);
            }
            else
            {
                console.warn('double entity in selection');
            }
        }
    }

    getQueryOrderingComparators(
        query: BaseEntityQuery
    )
    {
        const parameters =
            new ParameterDictionary(
                query.selection.entityNodes.map(
                    node =>
                        node.parameter
                )
            );
        const getParameterAssignment =
            (entity: Entity): ParameterAssignment =>
                new ParameterAssignment(
                    new Map(
                        query.selection.entityNodes.map(
                            node =>
                            {
                                const entities = node.entityPath().traverseEntity(entity);
                                const value =
                                    entities.length > 0
                                        ? new EntityValue(entities[0])
                                        : EmptyValue.instance;

                                return [
                                    node.parameter,
                                    value,
                                ];
                            }
                        )
                    )
                );

        return query.ordering.map<DataComparator<EntitySelectionResult>>(
                ordering =>
                    createEntityExpressionComparator(
                        result => result.entity,
                        parameters,
                        getParameterAssignment,
                        ordering.expression,
                        ordering.isAscending
                    )
            );
    }

    @action.bound
    updateInQueryResultSet(
        baseQuery: BaseEntityQuery,
        entity: Entity,
        updatedAggregates: Set<DataObject>,
        preQueryResult: EntityQueryMatchResult,
        postQueryResult: EntityQueryMatchResult
    )
    {
        // log('update in query', query.selection.rootNode.entityType.code, query, entity, resultSet);

        if (baseQuery.type === 'Aggregate')
        {
            baseQuery.queries.forEach(
                query =>
                {
                    const result = this.getAggregateResultByQuery(query);

                    this.mutateAggregate(
                        query,
                        result,
                        0,
                        updatedAggregates,
                        preQueryResult,
                        postQueryResult
                    );
                }
            );
        }
    }

    @action.bound
    removeFromQueryResultSet(
        baseQuery: BaseEntityQuery,
        entity: Entity,
        updatedAggregates: Set<DataObject>,
        preQueryResult: EntityQueryMatchResult
    )
    {

        if (baseQuery.type === 'Aggregate')
        {
            baseQuery.queries.forEach(
                query =>
                {
                    const result = this.getAggregateResultByQuery(query);

                    this.mutateAggregate(
                        query,
                        result,
                        0,
                        updatedAggregates,
                        preQueryResult,
                        undefined
                    );
                }
            );
        }
        else if (baseQuery.type === 'List')
        {
            const result =
                this.findQueryResultContainingEntity(
                    baseQuery,
                    entity
                );

            if (result)
            {
                const idx =
                    result.page.results.findIndex(
                        resultSetItem =>
                            equalsEntity(
                                resultSetItem.entity,
                                entity
                            )
                    );

                if (idx >= 0)
                {
                    result.page.results.splice(idx, 1);
                    result.page.numberOfRecords = result.page.numberOfRecords - 1;
                }
            }
        }
    }

    findQueryResultContainingEntity(
        baseQuery: BaseEntityQuery,
        entity: Entity
    ): ListQueryResult
    {
        return baseQuery.queries
            .map(
                query =>
                    this.resultByQuery.get(query)
            )
            .find(
                result =>
                    result !== undefined
                    && result.type === 'List'
                    && result.page.results.some(
                        result =>
                            equalsEntity(
                                result.entity,
                                entity
                            )
                    )
            ) as ListQueryResult;
    }

    insertEntityInCachedResults(
        baseQuery: BaseEntityQuery,
        entity: Entity
    )
    {
        const comparators = this.getQueryOrderingComparators(baseQuery);
        const entityResultToAdd = new EntitySelectionResult(entity);

        const insertedIn =
            baseQuery.queries
                .map(
                    query =>
                        this.resultByQuery.get(query)
                )
                .filter(
                    result =>
                        result !== undefined
                        && result.type === 'List'
                )
                .map(
                    result => result  as ListQueryResult
                )
                .filter(
                    queryListResult =>
                    {
                        const pageResults = queryListResult.page.results;

                        // find index (if any) of first entity in results larger than entity to add
                        // according to order settings of query
                        const idx = pageResults
                            .findIndex(
                                result =>
                                {
                                    const order =
                                        comparators
                                            .map(
                                                comp =>
                                                    comp(entityResultToAdd, result)
                                            )
                                            .find(r => r !== 0)
                                    // -1: entitySelectionResult > entity to add
                                    return (order < 0)
                                }
                            )

                        // found location to insert!
                        if (idx !== -1)
                        {
                            // insert it here
                            pageResults.splice(idx, 0, entityResultToAdd)
                            queryListResult.page.numberOfRecords = queryListResult.page.numberOfRecords + 1;
                            // we're done
                            return true;
                        }
                        else
                        {
                            // not found, try next queryListResult
                            return false;
                        }
                    }
                )
                .find(_ => true); // done on first success

        if (!insertedIn)
        {
            // No place found to insert, add to last page
            const lastQuery = baseQuery.queries[baseQuery.queries.length - 1];
            const lastResult = this.resultByQuery.get(lastQuery) as ListQueryResult;
            const lastPage = lastResult.page;
            lastPage.results.push(new EntitySelectionResult(entity));
            lastPage.numberOfRecords = lastPage.numberOfRecords + 1;
        }
    }

    @action.bound
    mutateAggregate(query: EntityQuery,
                    aggregateResult: EntitySelectionAggregateResult,
                    level: number,
                    updatedAggregates: Set<DataObject>,
                    preQueryResult?: EntityQueryMatchResult,
                    postQueryResult?: EntityQueryMatchResult)
    {
        // log('   - mutate aggregate', query, preQueryResult !== undefined, postQueryResult !== undefined, aggregateResult, level);

        if (preQueryResult || postQueryResult)
        {
            query.aggregates.forEach((aggregate, idx) =>
            {
                this.mutateAggregateValue(query, aggregateResult, level, updatedAggregates, aggregate, idx, preQueryResult, postQueryResult);
            });
        }

        // Add non-existent aggregates
        const childResults = new Set<EntitySelectionAggregateResult>(aggregateResult.children);

        if (postQueryResult)
        {
            postQueryResult.resultByMatchedAggregate.forEach((value, result) =>
            {
                if (!childResults.has(result))
                {
                    // consoleLog('adding result to child', level, aggregateResult, result);
                    aggregateResult.children.push(result);
                }
            });
        }

        // Update existing aggregates
        aggregateResult.children.forEach(childResult =>
        {
            const preChildResult = preQueryResult ? preQueryResult.resultByMatchedAggregate.get(childResult) : undefined;
            const postChildResult = postQueryResult ? postQueryResult.resultByMatchedAggregate.get(childResult) : undefined;

            this.mutateAggregate(
                query,
                childResult,
                level + 1,
                updatedAggregates,
                preChildResult,
                postChildResult);
        });
    }

    @action.bound
    mutateAggregateValue(query: EntityQuery,
                         aggregateResult: EntitySelectionAggregateResult,
                         level: number,
                         updatedAggregates: Set<DataObject>,
                         aggregate: EntitySelectionAggregate,
                         idx: number,
                         preQueryResult?: EntityQueryMatchResult,
                         postQueryResult?: EntityQueryMatchResult,)
    {
        if (idx >= aggregateResult.aggregates.length)
        {
            aggregateResult.aggregates.push(
                aggregate.aggregate === Aggregate.Count
                    ?
                        DataObject.constructFromTypeIdAndValue('Number', 0, this.dataObjectStore)
                    :
                        aggregate.fieldPath.isField
                            ?
                                DataObject.constructFromValue(aggregate.fieldPath.field.dataObjectSpecification, 0)
                            :
                                DataObject.constructFromTypeIdAndValue('Number', 0, this.dataObjectStore));
        }

        const result = aggregateResult.aggregates[idx];

        if (!updatedAggregates.has(result))
        {
            let isUpdated = false;

            switch (aggregate.aggregate)
            {
                case Aggregate.Count:
                    if (preQueryResult && !postQueryResult)
                    {
                        isUpdated = true;
                        result.setValue(result.value - 1);
                    }
                    else if (!preQueryResult && postQueryResult)
                    {
                        isUpdated = true;
                        result.setValue(result.value + 1);
                    }

                    break;

                case Aggregate.Sum:
                    {
                        let preQueryValue = preQueryResult ? preQueryResult.valueByAggregate.get(aggregate).value : 0;

                        if (preQueryValue === undefined)
                        {
                            preQueryValue = 0;
                        }

                        let postQueryValue = postQueryResult ? postQueryResult.valueByAggregate.get(aggregate).value : 0;

                        if (postQueryValue === undefined)
                        {
                            postQueryValue = 0;
                        }

                        let fromValue = result.value;

                        if (fromValue === undefined)
                        {
                            fromValue = 0;
                        }

                        if (preQueryValue !== postQueryValue)
                        {
                            isUpdated = true;
                            result.setValue(parseFloat(fromValue) - parseFloat(preQueryValue) + parseFloat(postQueryValue));

                            if (query.logName)
                            {
                                console.warn(`[query aggregate mutation] ${query.logName}: updated aggregate`, aggregate, preQueryResult, postQueryResult, preQueryValue, postQueryValue, result.value);
                            }
                            // console.warn('      ###### result', level, 'updating', aggregate.fieldPath.code, 'from', fromValue, 'to', result.value, 'pre:', preQueryValue, 'post:', postQueryValue);
                        }
                    }

                    break;

                case Aggregate.Max:
                    {
                        let postQueryValue = postQueryResult ? postQueryResult.valueByAggregate.get(aggregate).value : 0;

                        if (postQueryValue === undefined)
                        {
                            postQueryValue = 0;
                        }

                        let fromValue = result.value;

                        if (fromValue === undefined)
                        {
                            fromValue = 0;
                        }

                        if (fromValue !== postQueryValue)
                        {
                            isUpdated = true;
                            result.setValue(Math.max(fromValue, postQueryValue));
                        }
                    }

                    break;

                case Aggregate.Min:
                    {
                        let postQueryValue = postQueryResult ? postQueryResult.valueByAggregate.get(aggregate).value : 0;

                        if (postQueryValue === undefined)
                        {
                            postQueryValue = 0;
                        }

                        let fromValue = result.value;

                        if (fromValue === undefined)
                        {
                            fromValue = 0;
                        }

                        if (fromValue !== postQueryValue)
                        {
                            isUpdated = true;
                            result.setValue(Math.min(fromValue, postQueryValue));
                        }
                    }

                    break;

                // TODO [DD]: implement average
            }

            if (isUpdated)
            {
                // log('       | updating aggregate value', aggregate.fieldPath.code, aggregate, result.value, preQueryResult !== undefined, postQueryResult !== undefined, result);

                updatedAggregates.add(result);
            }
        }
        else
        {
            // log('       |xx| cancel update aggregate value', aggregate, result.value, preQueryResult !== undefined && postQueryResult === undefined, preQueryResult === undefined && postQueryResult !== undefined);
        }
    }

    disposeQuery(cachedQuery: EntityQuery)
    {
        const baseQuery = this.getCachedBaseQuery(cachedQuery);
        const idxOfQuery = baseQuery.queries.indexOf(cachedQuery);

        if (idxOfQuery >= 0)
        {
            baseQuery.queries.splice(
                idxOfQuery,
                1
            );

            if (baseQuery.queries.length === 0)
            {
                this.disposeBaseQuery(baseQuery);
            }
        }

        this.promiseByQuery.delete(cachedQuery);
        this.resultByQuery.delete(cachedQuery);
    }

    disposeBaseQuery(baseQuery: BaseEntityQuery)
    {
        const hash = baseQuery.hashCode;
        const cachedBaseQueries = this.baseQueryByHash.get(hash);

        if (cachedBaseQueries)
        {
            const idxOfQuery = cachedBaseQueries.indexOf(baseQuery);

            if (idxOfQuery >= 0)
            {
                cachedBaseQueries.splice(idxOfQuery, 1);
            }

            if (cachedBaseQueries.length === 0)
            {
                this.baseQueryByHash.delete(hash);
            }
        }
    }

    // ------------------------ Public logic ------------------------

    getCachedQuery(query: EntityQuery)
    {
        if (query.isCached)
        {
            const baseQuery = this.getCachedBaseQuery(query);

            if (baseQuery === undefined)
            {
                return undefined;
            }
            else
            {
                return baseQuery.queries
                    .find(
                        checkQuery =>
                            checkQuery.equals(query)
                    );
            }
        }
        else
        {
            return undefined;
        }
    }

    getCachedBaseQuery(query: EntityQuery)
    {
        if (query.isCached)
        {
            return (this.baseQueryByHash.get(query.hashCodeWithoutOffset) || [])
                .find(
                    checkQuery =>
                        checkQuery.equals(query)
                );
        }
        else
        {
            return undefined;
        }
    }

    getPromiseBySelection(query: EntityQuery)
    {
        const cachedQuery = this.getCachedQuery(query);

        if (cachedQuery)
        {
            return this.promiseByQuery.get(cachedQuery);
        }
        else
        {
            return undefined;
        }
    }

    getListResultByQuery(query: EntityQuery)
    {
        return (this.resultByQuery.get(query) as ListQueryResult)?.page;
    }

    getAggregateResultByQuery(query: EntityQuery)
    {
        return (this.resultByQuery.get(query) as AggregateQueryResult)?.result;
    }

    matchQueries(entity: Entity): EntityMatchResult
    {
        const resultByMatchedQuery = new Map<BaseEntityQuery, EntityQueryMatchResult>();

        this.baseQueryByHash.forEach(
            baseQueries =>
                baseQueries.forEach(
                    baseQuery =>
                    {
                        if (baseQuery.matches(entity))
                        {
                            const result = this.resultByQuery.get(baseQuery.firstQuery);

                            if (result)
                            {
                                resultByMatchedQuery.set(
                                    baseQuery,
                                    this.matchQueryResult(
                                        entity,
                                        baseQuery,
                                        result,
                                        0
                                    )
                                );
                            }

                            if (baseQuery.logName)
                            {
                                consoleLog(`[query] ${baseQuery.logName}: matched entity ${entity.entityType.code} (id: ${entity.id}, name: ${entity.name}) in query`);
                            }
                        }
                        else
                        {
                            if (baseQuery.logName)
                            {
                                consoleLog(`[query] ${baseQuery.logName}: not matched entity ${entity.entityType.code} (id: ${entity.id}, name: ${entity.name}) in query`, baseQuery, entity);
                            }
                        }
                    }
                )
        );

        return new EntityMatchResult(
            entity,
            entity.isNew(),
            resultByMatchedQuery
        );
    }

    matchQueryResult(
        entity: Entity,
        query: BaseEntityQuery,
        resultSet: QueryResult,
        level: number
    ): EntityQueryMatchResult
    {
        const valueByAggregate = new Map<EntitySelectionAggregate, DataObject>();

        query.aggregates.forEach(
            aggregate =>
            {
                // log('           - stake in aggregate', entity.entityType.code, entity, aggregate.fieldPath.code, aggregate, aggregate.fieldPath
                //     .getDataObject(
                //         entity,
                //         this.dataObjectStore).toString());

                valueByAggregate.set(
                    aggregate,
                    aggregate.fieldPath
                        .getDataObject(entity, null)
                        .clone()); // Clone data object because it will be modified after committing
            }
        );

        const resultByMatchedAggregate = new Map<EntitySelectionAggregateResult, EntityQueryMatchResult>();

        if (resultSet.type === 'Aggregate')
        {
            const aggregateResult = resultSet.result;
            const groupFieldPath = aggregateResult.groupFieldPath;

            if (groupFieldPath)
            {
                let isMatchedByOneOfTheChildren = false;

                aggregateResult.children.forEach(childResult =>
                {
                    const predicate =
                        new OldComparisonPredicate(
                            new EntityFieldComputation(groupFieldPath),
                            aggregateResult.groupInterval ? Comparator.In : Comparator.Equals,
                            new ConstantComputation(
                                childResult.groupEntity
                                    ?
                                        DataObject.constructFromTypeIdAndValue(
                                            'Entity',
                                            childResult.groupEntity,
                                            this.dataObjectStore)
                                    :
                                        childResult.groupValue)
                            );

                    const isMatched =
                        predicate.evaluate({
                            entityContext: entity.entityContext,
                            commitContext: null,
                        });

                    if (isMatched)
                    {
                        isMatchedByOneOfTheChildren = true;

                        if (query.logName)
                        {
                            consoleLog('   [x] matched entity', entity.name, 'with value', groupFieldPath.getDataObject(entity, null)?.value, 'with group', level, groupFieldPath && groupFieldPath.code, 'with value', childResult.groupValue && childResult.groupValue.value, childResult.groupEntity && childResult.groupEntity.name);
                        }

                        resultByMatchedAggregate.set(
                            childResult,
                            this.matchQueryResult(
                                entity,
                                query,
                                {
                                    type: 'Aggregate',
                                    result: childResult
                                },
                                level + 1
                            )
                        );
                    }
                    else
                    {
                        if (query.logName)
                        {
                            consoleLog('   [x] not matched entity', entity.name, 'with value', groupFieldPath.getDataObject(entity, null)?.value, 'with group', level, groupFieldPath && groupFieldPath.code, 'with value', childResult.groupValue && childResult.groupValue.value, childResult.groupEntity && childResult.groupEntity.name);
                        }
                    }
                });

                if (!isMatchedByOneOfTheChildren)
                {
                    // An aggregate result should be created
                    const newChildResult =
                        new EntitySelectionAggregateResult(
                            groupFieldPath.isField
                                ?
                                    undefined
                                :
                                    groupFieldPath.path
                                        .traverseEntity(entity, null)
                                        .find(() => true),
                            groupFieldPath.isField
                                ?
                                    // Clone because this data object may be mutated in the future
                                    groupFieldPath.getDataObject(entity, null).clone()
                                :
                                    undefined,
                            undefined,
                            query.aggregates.map(aggregate =>
                            {
                                if (aggregate.aggregate === Aggregate.Count)
                                {
                                    return DataObject.constructFromTypeIdAndValue('Number', 0, this.dataObjectStore);
                                }
                                else
                                {
                                    return aggregate.fieldPath.getNewDataObject(this.dataObjectStore);

                                    // Clone because this data object may be mutated in the future
                                    // return aggregate.fieldPath.getDataObject(entity, this.dataObjectStore).clone();
                                }
                            }),
                            level + 1 < query.selection.groupNodes.length
                                ?
                                    query.selection.groupNodes[level + 1].groupEntityNode
                                        .entityPath()
                                        .field(query.selection.groupNodes[level + 1].groupEntityField)
                                :
                                    undefined,
                            []);

                    if (aggregateResult.groupInterval && newChildResult.groupValue)
                    {
                        newChildResult.groupValue =
                            newChildResult.groupValue.constructRange(
                                newChildResult.groupValue,
                                aggregateResult.groupInterval,
                                this.dataObjectStore);
                    }

                    resultByMatchedAggregate.set(
                        newChildResult,
                        this.matchQueryResult(
                            entity,
                            query,
                            {
                                type: 'Aggregate',
                                result: newChildResult
                            },
                            level + 1));
                }
            }
        }

        return new EntityQueryMatchResult(valueByAggregate, resultByMatchedAggregate);
    }

    postmatch(
        entity: Entity,
        preMatchResult: EntityMatchResult
    )
    {
        this.postmatchEntity(entity, preMatchResult);

        return Promise.resolve();
    }

    postmatchEntity(
        entity: Entity,
        preMatchResult: EntityMatchResult
    )
    {
        const isNew = preMatchResult.isNew;
        const isDeleted = entity.isDeleted;

        const postMatchResult = this.matchQueries(entity);
        const updatedAggregates = new Set<DataObject>();

        preMatchResult.resultByMatchedQuery.forEach(
            (preQueryMatchResult, preMatchedQuery) =>
            {
                const isMatchedInPostResult = postMatchResult.resultByMatchedQuery.has(preMatchedQuery);

                if (isDeleted || !isMatchedInPostResult)
                {
                    // Remove from resultset
                    this.removeFromQueryResultSet(
                        preMatchedQuery,
                        entity,
                        updatedAggregates,
                        preQueryMatchResult
                    );

                    if (preMatchedQuery.logName)
                    {
                        consoleLog(`[query mutation] ${preMatchedQuery.logName}: removed result from resultset`, preQueryMatchResult, preMatchedQuery, updatedAggregates);
                    }
                }
                else if (!isNew && isMatchedInPostResult)
                {
                    // Update in resultset
                    this.updateInQueryResultSet(
                        preMatchedQuery,
                        entity,
                        updatedAggregates,
                        preQueryMatchResult,
                        postMatchResult.resultByMatchedQuery.get(preMatchedQuery)
                    );

                    if (preMatchedQuery.logName)
                    {
                        consoleLog(`[query mutation] ${preMatchedQuery.logName}: updated result in resultset`, preQueryMatchResult, postMatchResult.resultByMatchedQuery.get(preMatchedQuery), preMatchedQuery, updatedAggregates);
                    }
                }
            });

        if (!isDeleted)
        {
            postMatchResult.resultByMatchedQuery.forEach(
                (postQueryMatchResult, postMatchedQuery) =>
                {
                    if (isNew || !preMatchResult.resultByMatchedQuery.has(postMatchedQuery))
                    {
                        // Add to resultset
                        this.addToQueryResultSet(
                            postMatchedQuery,
                            entity,
                            updatedAggregates,
                            postQueryMatchResult
                        );

                        if (postMatchedQuery.logName)
                        {
                            consoleLog(`[query mutation] ${postMatchedQuery.logName}: added result in resultset`, postQueryMatchResult, postMatchedQuery, updatedAggregates);
                        }
                    }
                }
            );
        }
    }

    getAffectedQueries(event: EntityEvent): EntityQuery[]
    {
        return Array.from(this.resultByQuery.keys())
            .filter(
                query =>
                    query.isAffectedBy(event)
            );
    }

    // ----------------------- Private logic ------------------------
}
