import { ProductLineEditorInterface } from '../Hooks/useProductLine';
import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import Input from '../../../../../../../Input/Input';
import { Alignment } from '../../../../../../../../DataObject/Model/DataObject';
import useTypes from '../../../../../../../Type/Api/useTypes';
import { makeStyles } from '@material-ui/styles';
import { primaryColor } from '../../../../../../../../../../@Resource/Theme/Theme';
import useEntityValue from '../../../../../../../../../../@Api/Entity/Hooks/useEntityValue';
import { setValueByFieldInEntity } from '../../../../../../../../../../@Api/Entity/Commit/Context/Api/Compatibility/setValueByFieldInEntity';
import { CommitBuilder } from '../../../../../../../../../../@Api/Entity/Commit/Context/Builder/CommitBuilder';
import { classNames } from '../../../../../../../../../../@Future/Util/Class/classNames';

export interface ProductLinePriceProps
{
    productLine: ProductLineEditorInterface;
    currency: string;
    autoCommit: boolean;
    showLabel?: boolean;
    alignment?: Alignment;
}

const useStyles =
    makeStyles(
        {
            userEdited:
            {
                color: primaryColor
            }
        }
    );

export const ProductLinePrice: React.FC<ProductLinePriceProps> = observer(
    ({
         productLine,
         currency,
         autoCommit = true,
         showLabel,
         alignment,
     }) =>
    {
        const types = useTypes();
        const classes = useStyles();

        const priceField =
            currency
                ? types.ProductLine.Field.PriceInCurrency
                : types.ProductLine.Field.Price;

        const isManuallyChanged =
            useEntityValue(
                productLine.productLine,
                types.ProductLine.Field.IsPriceManuallyChanged,
                false,
                productLine.commitContext
            );

        const onBlurPrice =
            useCallback(
                () =>
                {
                    if (productLine.commitContext.isEntityDirty(productLine.productLine))
                    {
                        const value = productLine.productLine.getObjectValueByField(
                            priceField,
                            productLine.commitContext
                        )
                        setValueByFieldInEntity(
                            productLine.productLine,
                            types.ProductLine.Field.IsPriceManuallyChanged,
                            value !== undefined,
                            productLine.commitContext
                        )

                        if (!value)
                        {
                            return productLine.resetPrice();
                        }
                        else if (autoCommit !== false)
                        {
                            const builder = new CommitBuilder(productLine.commitContext)
                            builder.commit()
                                 .then();
                        }

                    }
                },
                [
                    productLine,
                    priceField,
                    types,
                    autoCommit,
                ]
            );

        return <div
            className={
                classNames(
                    isManuallyChanged && classes.userEdited
                )
            }
        >
            <Input
                entity={productLine.productLine}
                field={priceField}
                labelPosition={showLabel ? 'left' : 'none'}
                alignment={alignment}
                disabled={productLine.isPriceReadOnly || productLine.isProductAndQuantityAndPriceDisabled}
                doAutocommit={false}
                commitContext={productLine.commitContext}
                onBlur={onBlurPrice}
            />
        </div>
    })