import * as React from 'react';
import { useCallback, useState } from 'react';
import { observer } from 'mobx-react';
import { MobileStepper, Typography } from '@material-ui/core';
import { Loader } from '../../../../@Component/Generic/Loader/Loader';
import ReactPdf from '../../../../@Util/ReactPdf/ReactPdf';
import LabelButton from '../Button/Variant/Label/LabelButton';
import IconButton from '../Button/Variant/Icon/IconButton';
import styles from '../../../../@Component/Generic/FileViewer/FileViewer.module.scss';
import useIsMobile from '../../../../@Util/Responsiveness/useIsMobile';
import ViewGroup from '../ViewGroup/ViewGroup';
import ViewGroupItem from '../ViewGroup/ViewGroupItem';
import CardInset from '../Card/CardInset';
import PrimaryButton from '../Button/Variant/PrimaryButton/PrimaryButton';
import LocalizedText from '../../../../@Component/Domain/Localization/LocalizedText/LocalizedText';
import { downloadApiFile } from '../../../../@Service/ApiClient/Hooks/downloadApiFile';

export interface PdfViewerProps
{
    url: string;
    initializedUrl: string;
    docxUrl?: string;
}

const PdfViewer: React.FC<PdfViewerProps> =
    ({
         url,
         initializedUrl,
         docxUrl,
     }) =>
    {
        const isMobile = useIsMobile();
        const isDocx = !!docxUrl;
        const [ pdfPageNr, setPdfPageNr ] = useState(0);
        const [ pdfNumberOfPages, setPdfNumberOfPages ] = useState(0);
        const [ pdfWidth, setPdfWidth ] = useState(0);
        const [ , setPdfHeight ] = useState(0);

        const download =
            useCallback(
                () =>
                    downloadApiFile(url),
                [
                    url,
                ]
            );
        const downloadDocx =
            useCallback(
                () =>
                    downloadApiFile(docxUrl),
                [
                    docxUrl
                ]
            );

        const onLoadSuccess =
            useCallback(
                (pdf: any) =>
                {
                    const containerElement = document.querySelector('#pdfContainer');

                    // setPdf(pdf);
                    setPdfWidth(containerElement.clientWidth - 40); // Padding (2 * 32))
                    setPdfHeight(containerElement.clientHeight - 40) // Padding (2 * 32)
                    setPdfPageNr(0);
                    setPdfNumberOfPages(pdf.numPages);
                },
                [
                    setPdfPageNr,
                    setPdfNumberOfPages,
                    setPdfWidth,
                    setPdfHeight,
                ]
            );

        return <ViewGroup
            orientation="vertical"
            spacing={5}
        >
            {
                isMobile && isDocx &&
                <ViewGroupItem>
                    <CardInset
                        top={false}
                    >
                        <PrimaryButton
                            label="DOCX downloaden"
                            onClick={downloadDocx}
                            fullWidth
                        />
                    </CardInset>
                </ViewGroupItem>
            }
            {
                isMobile &&
                    <ViewGroupItem>
                        <CardInset
                            top={false}
                        >
                            <PrimaryButton
                                label="PDF downloaden"
                                onClick={download}
                                fullWidth
                            />
                        </CardInset>
                    </ViewGroupItem>
            }
            <ViewGroupItem>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100%',
                        width: '100%',
                        // marginTop: '48px'
                    }}
                    onClick={(e: any) => e.stopPropagation()}
                >
                    <div>
                         <MobileStepper
                             variant={(pdfNumberOfPages > 6) ? 'progress' : 'dots'}
                             steps={pdfNumberOfPages}
                             position="static"
                             activeStep={pdfPageNr}
                             nextButton={
                                 <div>
                                     {
                                         !isMobile && isDocx &&
                                         <div
                                             style={{
                                                 width: 50,
                                                 display: 'inline-block',
                                                 textAlign: 'right'
                                             }}
                                         >
                                             <IconButton
                                                 icon="get_app"
                                                 tooltip="Download DOCX"
                                                 onClick={downloadDocx}
                                             />
                                         </div>
                                     }
                                     {
                                         !isMobile &&
                                             <div
                                                 style={{
                                                     width: 50,
                                                     display: 'inline-block',
                                                     textAlign: 'right'
                                                 }}
                                             >
                                                 <IconButton
                                                     icon="get_app"
                                                     tooltip="Download PDF"
                                                     onClick={download}
                                                 />
                                             </div>
                                     }
                                     <LabelButton
                                         label={
                                             <LocalizedText
                                                 code="GenericComponent.FileViewer.NextPage"
                                                 value="Volgende pagina"
                                             />
                                         }
                                         onClick={() => setPdfPageNr(pdfPageNr + 1)}
                                         disabled={pdfPageNr === pdfNumberOfPages - 1}
                                     />
                                 </div>}
                             backButton={
                                 <div
                                     style={{
                                         marginRight: isMobile ? 0 : 50
                                     }}
                                 >
                                     <LabelButton
                                         label={
                                             <LocalizedText
                                                code="GenericComponent.FileViewer.PreviousPage"
                                                value="Vorige pagina"
                                             />
                                         }
                                         onClick={() => setPdfPageNr(pdfPageNr - 1)}
                                         disabled={pdfPageNr === 0}
                                     />
                                 </div>}
                         />
                        <div
                            style={{
                                paddingTop: 10,
                                background: 'whitesmoke'
                            }}
                        >
                            <Typography
                                align="center"
                            >
                                {pdfNumberOfPages > 0 ? pdfPageNr + 1 : 0 } / {pdfNumberOfPages}
                            </Typography>
                        </div>
                    </div>
                    <div
                        id="pdfContainer"
                        style={{
                            display: 'flex',
                            flex: '1 1 auto',
                            justifyContent: 'center',
                            background: 'whitesmoke',
                            paddingBottom: 15
                        }}
                    >
                        <ReactPdf.Document
                            // key={store.uuid} // if this store is recreated with the same file url, the pdf becomes empty
                            // className={this.props.classes.document}
                            file={initializedUrl}
                            // pdf={pdf}
                            onLoadSuccess={onLoadSuccess}
                            loading={
                                <Loader
                                    inline
                                />
                            }
                        >
                            <ReactPdf.Page
                                renderTextLayer={false}
                                className={styles.page}
                                pageNumber={pdfPageNr + 1}
                                width={Math.min(800, pdfWidth)}
                            />
                        </ReactPdf.Document>
                    </div>
                </div>
            </ViewGroupItem>
        </ViewGroup>;
    };

export default observer(PdfViewer);
