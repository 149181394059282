import { EntityType } from '../../../../../@Api/Model/Implementation/EntityType';
import { EntityPath } from '../../Path/@Model/EntityPath';
import { EntitySelectionBuilder } from '../Builder/EntitySelectionBuilder';
import { DependencyList } from 'react';
import { useComputed } from 'mobx-react-lite';
import useAggregateResult from './useAggregateResult';
import { Aggregate } from '../../../DataObject/Model/Aggregate';
import useTypes from '../../Type/Api/useTypes';

export default function useCount(
    type: EntityType,
    callback: (builder: EntitySelectionBuilder, rootPath: EntityPath) => void,
    deps: DependencyList
): number
{
    const types = useTypes();

    const result =
        useAggregateResult(
            type,
            (builder, rootPath) =>
            {
                callback(builder, rootPath);

                return builder.aggregateOn(
                    rootPath.field(types.Entity.Field.Id),
                    undefined,
                    Aggregate.Count
                );
            },
            [
                ...deps,
                types,
            ]
        );

    return useComputed(
        () =>
            result
                ? result.aggregates[0].value
                : undefined,
        [
            result
        ]
    );
}
