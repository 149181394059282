import React, { useCallback, useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { Entity } from '../../../../../../../@Api/Model/Implementation/Entity';
import useTypes from '../../../../../Entity/Type/Api/useTypes';
import Switch from '../../../../../../../@Future/Component/Generic/Input/Switch/Switch';
import CurrentUserContext from '../../../../../User/CurrentUserContext';
import { CommitBuilder } from '../../../../../../../@Api/Entity/Commit/Context/Builder/CommitBuilder';
import useEntityValue from '../../../../../../../@Api/Entity/Hooks/useEntityValue';

export interface AutomationActivationSwitchProps
{
    automation: Entity;
    activation?: Entity;
    onActivationChanged?: () => void;
}

const AutomationActivationSwitch: React.FC<AutomationActivationSwitchProps> =
    props =>
    {
        const currentUserStore = useContext(CurrentUserContext);
        const types = useTypes();

        const isDeactivated =
            useEntityValue(
                props.activation,
                types.AutomationActivation.Field.IsDeactivated,
                false
            )

        const isDeactivatedByUser =
            useEntityValue(
                props.activation,
                types.AutomationActivation.Field.IsDeactivatedByUser,
                false
            )

        const toggle =
            useCallback(
                async (isChecked, event) =>
                {
                    event.stopPropagation();

                    const commitBuilder = new CommitBuilder();

                    if (isChecked && !props.activation)
                    {
                        commitBuilder
                            .createEntity(
                                types.AutomationActivation.Type,
                                builder =>
                                    builder
                                        .relateTo(
                                            true,
                                            types.Automation.RelationshipDefinition.Activations,
                                            props.automation
                                        )
                                        .relateTo(
                                            true,
                                            types.Relation.Organization.Environment.RelationshipDefinition.AutomationActivations,
                                            currentUserStore.environmentEntity
                                        )
                                        .ifValid(
                                            () =>
                                                props.automation.getObjectValueByField(types.Automation.Field.IsUser) === true,
                                            builder =>
                                                builder.relateTo(
                                                    true,
                                                    types.Relationship.Person.Contact.Employee.RelationshipDefinition.AutomationActivations,
                                                    currentUserStore.employeeEntity
                                                )
                                        )
                                        .setObjectValue(
                                            types.AutomationActivation.Field.IsDeactivated,
                                            false
                                        )
                            )
                    }
                    else if (props.activation)
                    {
                        commitBuilder
                            .setObjectValueInEntity(
                                props.activation,
                                types.AutomationActivation.Field.IsDeactivatedByUser,
                                !isChecked
                            )
                            .ifValid(
                                () => !isChecked,
                                builder =>
                                    builder
                                        .setObjectValueInEntity(
                                            props.activation,
                                            types.AutomationActivation.Field.DeactivationDate,
                                            new Date()
                                        )
                            )
                            .ifValid(
                                () => isChecked,
                                builder =>
                                    builder
                                        .setObjectValueInEntity(
                                            props.activation,
                                            types.AutomationActivation.Field.IsDeactivated,
                                            false
                                        )
                                        .setObjectValueInEntity(
                                            props.activation,
                                            types.AutomationActivation.Field.DeactivationDate,
                                            undefined
                                        )
                                        .setObjectValueInEntity(
                                            props.activation,
                                            types.AutomationActivation.Field.DeactivationReason,
                                            undefined
                                        )
                            )
                    }

                    return commitBuilder
                        .commit()
                        .then(
                            () =>
                                props.onActivationChanged
                                    ? props.onActivationChanged()
                                    : undefined
                        );
                },
                [
                    props,
                    currentUserStore,
                    types,
                ]);

        return <Switch
            checked={props.activation && !isDeactivated && !isDeactivatedByUser}
            onToggle={toggle}
        />;
    };

export default observer(AutomationActivationSwitch);
