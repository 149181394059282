import { Entity } from '../../../../@Api/Model/Implementation/Entity';
import { setValueByFieldInEntity } from '../../../../@Api/Entity/Commit/Context/Api/Compatibility/setValueByFieldInEntity';
import { EntityRelationshipDefinition } from '../../../../@Api/Model/Implementation/EntityRelationshipDefinition';
import { constructEntityOfType } from '../../../../@Api/Entity/Commit/Context/Api/Compatibility/constructEntityOfType';
import { updateRelationship } from '../../../../@Api/Entity/Commit/Context/Api/Compatibility/updateRelationship';
import getDatastoreByCode from '../../../../@Api/Entity/Bespoke/Datastore/getDatastoreByCode';
import { getOrCreateStateByNameAndCountry } from './Api/getOrCreateStateByNameAndCountry';
import { CommitContext } from '../../../../@Api/Entity/Commit/Context/CommitContext';
import { loadModuleDirectly } from '../../../../@Util/DependencyInjection/index';
import { EntityTypeStore } from '../Type/EntityTypeStore';

export default async function updateEntityFromCoC(result: any,
                                                  entity: Entity,
                                                  relationship?: Entity,
                                                  commitContext?: CommitContext): Promise<void>
{
    const entityTypeStore = loadModuleDirectly(EntityTypeStore);
    const types = entityTypeStore.bespoke.types;

    const companySummary = result?.companySummary;
    const contactInformation = result?.contactInformation;
    const companyIdentification = result?.companyIdentification;
    const companyActivity = `${companySummary?.mainActivity?.code} - ${companySummary?.mainActivity?.description}`;

    setValueByFieldInEntity(
        entity,
        types.Relation.Organization.Field.Name,
        companySummary?.businessName,
        commitContext
    );
    setValueByFieldInEntity(
        entity,
        types.Relation.Organization.Field.CompanyActivity,
        companyActivity,
        commitContext
    );

    if (relationship && result?.companySummary?.companyStatus?.status === 'NonActive')
    {
        setValueByFieldInEntity(
            relationship,
            types.Relationship.Field.IsFormer,
            true,
            commitContext
        );
    }

    const resolveAddress =
        async (addressDescriptor: any, countryCode: string, addressRelationshipDefinition: EntityRelationshipDefinition) =>
        {
            if (addressDescriptor)
            {
                let address: Entity;

                if (addressDescriptor.telephone)
                {
                    setValueByFieldInEntity(
                        entity,
                        types.Relation.Organization.Field.PhoneNumber,
                        addressDescriptor.telephone,
                        commitContext
                    );
                }

                if (entity.hasRelationshipsByDefinition(
                    false,
                    addressRelationshipDefinition,
                    commitContext))
                {
                    address =
                        entity.getRelatedEntityByDefinition(
                            false,
                            addressRelationshipDefinition);
                }
                else
                {
                    address =
                        constructEntityOfType(
                            types.Address.Type,
                            commitContext
                        );

                    updateRelationship(
                        entity,
                        false,
                        addressRelationshipDefinition,
                        address,
                        commitContext
                    );
                }

                const houseNumber = addressDescriptor.houseNumber?.replace(/(^\d+)(.*)/i,'$1');
                const houseNumberSuffix = addressDescriptor.houseNumber?.replace(houseNumber, '').trim();
                const stateName = addressDescriptor?.province;

                setValueByFieldInEntity(
                    address,
                    types.Address.Field.Street,
                    addressDescriptor.street,
                    commitContext
                );
                setValueByFieldInEntity(
                    address,
                    types.Address.Field.City,
                    addressDescriptor.city,
                    commitContext
                );
                setValueByFieldInEntity(
                    address,
                    types.Address.Field.PostalCode,
                    addressDescriptor.postalCode,
                    commitContext
                );

                if (!isNaN(houseNumber))
                {
                    setValueByFieldInEntity(
                        address,
                        types.Address.Field.HouseNr,
                        houseNumber,
                        commitContext
                    );
                }

                setValueByFieldInEntity(
                    address,
                    types.Address.Field.HouseNrSuffix,
                    houseNumberSuffix,
                    commitContext
                );

                if (countryCode)
                {
                    const country =
                        await getDatastoreByCode(
                            types.Datastore.Country.Type,
                            countryCode
                        );

                    if (country)
                    {
                        updateRelationship(
                            address,
                            false,
                            types.Address.RelationshipDefinition.Country,
                            country,
                            commitContext
                        );

                        if (stateName)
                        {
                            const state =
                                await getOrCreateStateByNameAndCountry(
                                    stateName,
                                    country,
                                    commitContext
                                );

                            if (state)
                            {
                                updateRelationship(
                                    address,
                                    false,
                                    types.Address.RelationshipDefinition.State,
                                    state,
                                    commitContext
                                );
                            }
                        }
                    }
                }
            }
        };

    await Promise.all([
        resolveAddress(
            contactInformation?.mainAddress,
            contactInformation?.mainAddress?.country,
            types.Relation.Organization.RelationshipDefinition.PostalAddress),
        resolveAddress(
            companyIdentification?.basicInformation?.contactAddress,
            companyIdentification?.basicInformation?.country,
            types.Relation.Organization.RelationshipDefinition.VisitingAddress)
    ]);

    if (contactInformation?.websites)
    {
        const website = result.contactInformation.websites.find(() => true);

        if (website)
        {
            setValueByFieldInEntity(
                entity,
                types.Relation.Organization.Field.Website,
                website,
                commitContext
            );
        }
    }

    if (contactInformation?.mainAddress?.telephone)
    {
        setValueByFieldInEntity(
            entity,
            types.Relation.Organization.Field.PhoneNumber,
            contactInformation?.mainAddress?.telephone,
            commitContext
        );
    }

    const numberOfEmployees =
        result?.otherInformation?.employeesInformation?.length > 0
            ? parseInt(result?.otherInformation?.employeesInformation[0]?.numberOfEmployees)
            : undefined;
    const companyRegistrationNumber = companySummary?.companyRegistrationNumber;
    const rsin = result?.additionalInformation?.misc?.rsinNumber;
    const branchNumber = result?.additionalInformation?.misc?.branchNumber;
    const dateIssued = companyIdentification?.basicInformation?.companyRegistrationDate;
    const incorporationDate = companyIdentification?.basicInformation?.operationsStartDate;
    const dateOfEstablishment = incorporationDate;
    const legalForm = companyIdentification?.basicInformation?.legalForm;

    if (legalForm)
    {
        const legalCode: string =
            legalForm?.providerCode;

        const legalName: string =
            legalForm?.description;

        if (legalCode && legalName)
        {
            let foundLegalForm =
                await getDatastoreByCode(
                    types.Datastore.LegalForm.Type,
                    legalCode
                );

            if (!foundLegalForm)
            {
                foundLegalForm =
                    constructEntityOfType(
                        types.Datastore.LegalForm.Type,
                        commitContext
                    );

                setValueByFieldInEntity(
                    foundLegalForm,
                    types.Datastore.Field.Code,
                    legalCode,
                    commitContext
                );

                foundLegalForm.setName(
                    legalName,
                    commitContext
                );
            }

            updateRelationship(
                entity,
                false,
                types.Relation.Organization.RelationshipDefinition.LegalForm,
                foundLegalForm,
                commitContext
            );
        }
    }

    if (numberOfEmployees !== undefined && !isNaN(numberOfEmployees))
    {
        setValueByFieldInEntity(
            entity,
            types.Relation.Organization.Field.NumberOfEmployees,
            numberOfEmployees,
            commitContext
        );
    }

    if (companyRegistrationNumber)
    {
        setValueByFieldInEntity(
            entity,
            types.Relation.Organization.Field.ChamberOfCommerceNumber,
            companyRegistrationNumber,
            commitContext
        );
    }

    if (rsin)
    {
        setValueByFieldInEntity(
            entity,
            types.Relation.Organization.Field.RSIN,
            rsin,
            commitContext
        );
    }

    if (dateIssued)
    {
        setValueByFieldInEntity(
            entity,
            types.Relation.Organization.Field.DateIssued,
            Date.parse(dateIssued),
            commitContext
        );
    }

    if (incorporationDate)
    {
        setValueByFieldInEntity(
            entity,
            types.Relation.Organization.Field.FoundingDate,
            Date.parse(incorporationDate),
            commitContext
        );
    }

    if (dateOfEstablishment)
    {
        setValueByFieldInEntity(
            entity,
            types.Relation.Organization.Field.EstablishmentDate,
            Date.parse(dateOfEstablishment),
            commitContext
        );
    }

    if (branchNumber)
    {
        setValueByFieldInEntity(
            entity,
            types.Relation.Organization.Field.EstablishmentNumber,
            parseInt(branchNumber),
            commitContext
        );
    }

    const directors: any[] = result?.directors;

    if (directors)
    {
        directors.forEach(
            director =>
            {
                const contact =
                    constructEntityOfType(
                        types.Relationship.Person.Contact.Standard.Type,
                        commitContext
                    );

                setValueByFieldInEntity(
                    contact,
                    types.Relationship.Person.Contact.Field.EmailAddress,
                    director.emailAddress,
                    commitContext
                );

                if (director.positions)
                {
                    const position = director.positions.reverse().find(() => true);

                    if (position
                        && position.positionName)
                    {
                        setValueByFieldInEntity(
                            contact,
                            types.Relationship.Person.Contact.Field.FunctionOnBusinessCard,
                            position.positionName,
                            commitContext
                        );
                    }
                }

                const person =
                    constructEntityOfType(
                        types.Relation.Person.Type,
                        commitContext
                    );

                if (director.firstName)
                {
                    const firstName = director.firstName.split(' ').find(() => true);

                    if (firstName)
                    {
                        setValueByFieldInEntity(
                            person,
                            types.Relation.Person.Field.FirstName,
                            firstName,
                            commitContext
                        );
                    }
                }

                setValueByFieldInEntity(
                    person,
                    types.Relation.Person.Field.MiddleName,
                    director.middleName,
                    commitContext
                );
                setValueByFieldInEntity(
                    person,
                    types.Relation.Person.Field.LastName,
                    director.lastName,
                    commitContext
                );

                if (director.dateOfBirth)
                {
                    setValueByFieldInEntity(
                        person,
                        types.Relation.Person.Field.BirthDate,
                        new Date(director.dateOfBirth),
                        commitContext
                    );
                }

                updateRelationship(
                    contact,
                    true,
                    types.Relation.RelationshipDefinition.Relationships,
                    entity,
                    commitContext
                );
                updateRelationship(
                    contact,
                    false,
                    types.Relationship.Person.RelationshipDefinition.Person,
                    person,
                    commitContext
                );
            });
    }
}