import { BaseStore } from '../../@Framework/Store/BaseStore';
import { action, computed, observable } from 'mobx';
import { RouterStore as MobxRouterStore, syncHistoryWithStore } from 'mobx-react-router';
import { createBrowserHistory, History, LocationListener } from 'history';
import { RoutingInterceptor } from './Model/RoutingInterceptor';
import { RoutingListener } from './Model/RoutingListener';
import { Route, RouteType } from './Model/Route';
import { PageStore } from '../Navigation/Page/PageStore';
import { RouteInstantiation } from './Model/RouteInstantiation';
import { injectWithQualifier } from '../../@Util/DependencyInjection/index';
import { AuthenticationManager } from '../Authentication/AuthenticationManager';
import { RoutingState } from './Model/RoutingState/RoutingState';
import { SettingSource, SettingStore } from '../../@Component/Domain/Setting/SettingStore';
import { Setting } from '../../@Api/Settings/Setting';

const urlSearchParams = new URLSearchParams(window.location.search);
const isRedirectInParent = urlSearchParams.has('redirect-in-parent');
const isRunningInV2 = window.location.pathname.startsWith('/v2');

export class RouterStore extends BaseStore
{
    // ------------------------ Dependencies ------------------------

    @injectWithQualifier('AuthenticationManager') authenticationManager: AuthenticationManager;
    @injectWithQualifier('SettingStore') settingStore: SettingStore;

    // ------------------------- Properties -------------------------

    @observable.ref router: MobxRouterStore;
    @observable.ref browserHistory: History;
    @observable.ref synchronizedHistory: History;

    @observable.shallow interceptors: RoutingInterceptor[];
    @observable.shallow listeners: RoutingListener[];
    @observable isRouteTypeEnabled: (routeType: RouteType) => boolean;
    @observable.shallow routes: Route[];
    @observable defaultPath: string;
    @observable.ref historyListener: LocationListener;

    @observable lastRoutedPath: string;
    @observable instantiationMap = observable.map<string, RouteInstantiation>(undefined, { deep: false }); // observable.array<RouteInstantiation>();
    @observable stack = observable.array<string>();
    @observable pointer: string;
    @observable currentIdx: number;
    @observable entrancePath: string;
    @observable routingStatesMap = observable.map<number, RoutingState>(undefined, { deep: false });

    // ------------------------ Constructor -------------------------

    constructor(interceptors: RoutingInterceptor[] = [],
                listeners: RoutingListener[] = [],
                isRouteTypeEnabled: (routeType: RouteType) => boolean,
                routes: Route[],
                defaultPath?: string)
    {
        super();

        this.router = new MobxRouterStore();
        this.browserHistory = createBrowserHistory({ basename: process.env.PUBLIC_URL });
        this.synchronizedHistory = syncHistoryWithStore(this.browserHistory, this.router);
        this.interceptors = observable.array(interceptors, { deep: false });
        this.listeners = observable.array(listeners, { deep: false });
        this.isRouteTypeEnabled = isRouteTypeEnabled;
        this.routes = observable.array(routes, { deep: false });
        this.defaultPath = defaultPath;
        this.entrancePath = this.router.location.pathname;

        this.historyListener =
            (location, act) =>
            {
                // The action is "POP" when back/forward is called
                if (act === 'POP')
                {
                    const locationStateIdx = (location.state as any).idx;

                    // Determine if backward or forward was pressed
                    let isBackward = false;

                    if (locationStateIdx > this.currentIdx)
                    {
                        isBackward = false;
                    }
                    else
                    {
                        isBackward = true;
                    }

                    // Get current route instantiation if it exists
                    const routeInstantiation = this.instantiationMap.get(location.key);

                    if (routeInstantiation)
                    {
                        // A route instantiation was found: serve the page from history
                        this.setPointer(location.key);
                        this.setLastRoutedPath(location.pathname);
                        this.setCurrentIdx(locationStateIdx);
                    }
                    else
                    {
                        // No RouteInstance was found, which means the navigation took place before
                        // this store was initiated, which could have been caused by a webpage refresh.
                        // In this case, construct the page from the path.
                        const route = this.findRouteByPath(location.pathname);

                        if (route)
                        {
                            const parameters = route.parameters(location.pathname);

                            return route.instantiateStore(parameters)
                                .then(
                                    pageStore =>
                                    {
                                        if (pageStore)
                                        {
                                            const routeInstantiation =
                                                new RouteInstantiation(
                                                    location.pathname,
                                                    route,
                                                    parameters,
                                                    pageStore,
                                                    location.key);

                                            this.pushRouteInstantiation(routeInstantiation);
                                            this.setPointer(routeInstantiation.browserKey);
                                            this.setLastRoutedPath(location.pathname);
                                            this.setCurrentIdx(locationStateIdx);

                                            if (isBackward)
                                            {
                                                this.prependToStackOrder(routeInstantiation.browserKey);
                                            }
                                            else
                                            {
                                                this.appendToStackOrder(routeInstantiation.browserKey);
                                            }
                                        }
                                    });
                        }
                    }

                    this.debugStack();
                }
                else
                {
                    if (this.lastRoutedPath !== location.pathname && !(location.state as any).isVirtual)
                    {
                        this.route(location.pathname, location.state);
                    }
                }
            };

        // Register listeners
        this.synchronizedHistory.listen(this.historyListener);
    }

    // ----------------------- Initialization -----------------------

    initialize()
    {
        if (this.instantiationMap.size === 0)
        {
            return this.route(
                this.path,
                this.queryStringToObject(this.router.location.search),
                undefined,
                undefined,
                undefined,
                false);
        }
        else
        {
            return Promise.resolve();
        }
    }

    // -------------------------- Computed --------------------------

    @computed
    get hasBackward(): boolean
    {
        return this.currentStackIndex !== undefined && this.currentStackIndex > 0;
    }

    @computed
    get hasForward(): boolean
    {
        return this.currentStackIndex !== undefined && this.currentStackIndex < this.stack.length - 1;
    }

    @computed
    get path(): string
    {
        return this.router.location.pathname;
    }

    @computed
    get currentRouteInstantiation(): RouteInstantiation
    {
        return this.instantiationMap.get(this.pointer);
    }

    @computed
    get currentPageStore(): PageStore
    {
        return this.currentRouteInstantiation
            ?
                this.currentRouteInstantiation.store
            :
                undefined;
    }

    @computed
    get currentStackIndex(): number
    {
        if (this.pointer !== null)
        {
            const index = this.stack.indexOf(this.pointer);

            return index === -1 ? undefined : index;
        }

        return undefined;
    }

    @computed
    get routingState(): RoutingState
    {
        return {
            ...this.routingStatesMap.get(this.currentIdx),
            idx: this.currentIdx
        };
    }

    @computed
    get isRunningInV2(): boolean
    {
        return isRunningInV2;
    }

    @computed
    get shouldRunInV2(): boolean
    {
        return this.settingStore?.getValue(SettingSource.User, Setting.RedirectToV2);
    }

    setRunInV2()
    {
        this.settingStore?.updateWithValue(SettingSource.User, Setting.RedirectToV2, true);
    }

    redirectToV2(path?: string)
    {
        this.redirectToUrl(`${window.location.origin}/v2${path || window.location.pathname}${window.location.search}`);
    }

    redirectToV1(path?: string)
    {
        this.redirectToUrl(`${window.location.origin}${(path || window.location.pathname).replace('/v2','')}${window.location.search}`);
    }

    redirectToUrl(url: string)
    {
        if (isRedirectInParent && window.parent)
        {
            const message =
                {
                    type: 'redirect',
                    url: url
                };

            window.parent.postMessage(message, '*');
        }
        else
        {
            window.location.replace(url);
        }
    }

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    @action
    routeVirtually(path: string)
    {
        this.synchronizedHistory.push(path, { isVirtual: true });
    }

    @action
    async route(
        path: string,
        parameters?: any,
        openInNewTab: boolean = false,
        pageStore?: PageStore,
        doForce: boolean = false,
        doUpdateBrowserLocation: boolean = true
    ): Promise<PageStore>
    {
        if (!this.isRunningInV2 && this.shouldRunInV2)
        {
            this.redirectToV2(path);
        }

        if (this.isRunningInV2 && !this.shouldRunInV2)
        {
            this.setRunInV2();
        }

        const route = this.findRouteByPath(path);

        if (!route && this.defaultPath)
        {
            return this.route(this.defaultPath);
        }

        for (const interceptor of this.interceptors)
        {
            if (!(await interceptor.shouldRoute(path, parameters)))
            {
                return this.currentPageStore;
            }
        }

        if (route && (this.lastRoutedPath !== path || doForce))
        {
            parameters = {
                ...parameters,
                ...route.parameters(path),
            };

            // Set last routed path to make sure routes are not invoked double after changing the browser URL
            this.lastRoutedPath = path;

            // The idx is used to determine the order of the history and if a hash change
            // was invoked by a "back" or "forward" action.
            const idx: number =
                this.pointer === undefined && this.router.location.state
                    ?
                        (this.router.location.state as any).idx
                    :
                        new Date().getTime();

            // Update the browser location
            let location = path;
            // doUpdateBrowserLocation
            //     ?
            //         path
            //     :
            //         `${this.browserHistory.location.pathname}${this.browserHistory.location.search}`;

            if (this.pointer === undefined)
            {
                if (doUpdateBrowserLocation)
                {
                    try
                    {
                        this.synchronizedHistory.replace(location, { idx: idx });
                    }
                    catch (e)
                    {
                        console.error(e);
                    }
                }
            }
            else
            {
                if (doUpdateBrowserLocation)
                {
                    try
                    {
                        this.synchronizedHistory.push(location, { idx: idx });
                    }
                    catch (e)
                    {
                        console.error(e);
                    }
                }

                // Find and clear all route instances that are no longer in the browser history.
                // This happens when a user goes back and visits a new page instead of going forward.
                const currentIndex = this.currentStackIndex;

                for (let i = currentIndex + 1; i < this.stack.length; ++i)
                {
                    this.instantiationMap.delete(this.stack[i]);
                }

                this.stack.splice(currentIndex + 1, this.stack.length - 1);
            }

            this.setCurrentIdx(idx);

            const existingRouteInstantiation = this.findRouteInstantiationByPath(path);
            const existingPageStore =
                existingRouteInstantiation
                    ?
                        existingRouteInstantiation.store
                    :
                        undefined;

            return this.resolvePageStore(
                route,
                parameters,
                pageStore
                    ?
                        pageStore
                    :
                        (existingPageStore
                            ?
                                existingPageStore
                            :
                                undefined))
                .then(
                    async pageStore =>
                    {
                        if (pageStore)
                        {
                            // It might be that while this promise was resolving,
                            // another route command was issued that beat this to it
                            // Then the other route command should win, because it was
                            // the last route command to be issued
                            if (this.lastRoutedPath === path)
                            {
                                const routeInstantiation =
                                    new RouteInstantiation(
                                        path,
                                        route,
                                        parameters,
                                        pageStore,
                                        this.router.location.key);

                                this.pushRouteInstantiation(routeInstantiation);
                                this.setPointer(routeInstantiation.browserKey);
                                this.appendToStackOrder(routeInstantiation.browserKey);

                                this.debugStack();

                                this.listeners.forEach(
                                    listener =>
                                        listener.onRoute(route, parameters));
                            }

                            return pageStore;
                        }
                        else
                        {
                            return this.currentPageStore;
                        }
                    });
        }
        else
        {
            return Promise.resolve(this.currentPageStore);
        }
    }

    @action
    private pushRouteInstantiation(instance: RouteInstantiation)
    {
        this.instantiationMap.replace(
            new Map([
                [
                    instance.browserKey,
                    instance
                ]
            ])
        );
    }

    @action
    private setLastRoutedPath(path: string)
    {
        this.lastRoutedPath = path;
    }

    @action
    private setCurrentIdx(idx: number)
    {
        this.currentIdx = idx;
    }

    @action
    setRoutingState(routingState: RoutingState)
    {
        this.routingStatesMap.set(routingState.idx, routingState);
    }

    @action
    goBack()
    {
        // Only go back if we do not leave the app
        if (this.stack.length > 1)
        {
            this.synchronizedHistory.goBack();
        }
        else
        {
            // Otherwise, route to the root of the app
            this.route('/');
        }
    }

    @action
    goForward()
    {
        this.synchronizedHistory.goForward();
    }

    @action
    setPointer(key: string)
    {
        this.pointer = key;
    }

    @action
    registerInterceptor(interceptor: RoutingInterceptor)
    {
        this.interceptors.push(interceptor);
    }

    @action
    unregisterInterceptor(interceptor: RoutingInterceptor)
    {
        let idx = this.interceptors.indexOf(interceptor);

        if (idx >= 0)
        {
            this.interceptors.splice(idx, 1);
        }
    }

    @action
    registerListener(listener: RoutingListener)
    {
        this.listeners.push(listener);
    }

    @action
    unregisterListener(listener: RoutingListener)
    {
        let idx = this.listeners.indexOf(listener);

        if (idx >= 0)
        {
            this.listeners.splice(idx, 1);
        }
    }

    @action.bound
    pushPage(page: PageStore)
    {
        if (page.routePath !== undefined)
        {
            const route = this.findRouteByPath(page.routePath);

            if (route)
            {
                this.route(
                    page.routePath,
                    undefined,
                    undefined,
                    page);

                return;
            }
        }

        console.error('No route found for page store', page);
    }

    @action
    appendToStackOrder(key: string)
    {
        if (this.stack.indexOf(key) === -1)
        {
            this.stack.push(key);
        }
    }

    @action
    prependToStackOrder(key: string)
    {
        if (this.stack.indexOf(key) === -1)
        {
            this.stack.unshift(key);
        }
    }

    @action
    reset()
    {
        this.stack.replace([]);
        this.currentIdx = 0;
        this.lastRoutedPath = undefined;
        this.pointer = undefined;
        this.instantiationMap.clear();
        this.routingStatesMap.clear();

        return this.route('/');
    }

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------

    private findRouteInstantiationByPath(path: string)
    {
        let iterator = this.instantiationMap.values();
        let result = iterator.next();

        while (!result.done)
        {
            if (result.value.path === path)
            {
                return result.value;
            }

            result = iterator.next();
        }

        return undefined;
    }

    findRouteByPath(path: string): Route
    {
        return this.routes
            .filter(
                route =>
                    this.isRouteTypeEnabled(route.type))
            .find(
                route =>
                    route.matches(path));
    }

    findRouteByPathAndRouteTypes(path: string,
                                 routeTypes: RouteType[]): Route
    {
        return this.routes
            .filter(
                route =>
                    this.isRouteTypeEnabled(route.type)
                        && routeTypes.some(routeType => route.type === routeType))
            .find(
                route =>
                    route.matches(path));
    }

    private debugStack()
    {

    }

    private resolvePageStore(route: Route,
                             parameters: any,
                             defaultPageStore: PageStore): Promise<PageStore | undefined>
    {
        if (defaultPageStore)
        {
            return Promise.resolve(defaultPageStore);
        }
        else
        {
            return route.instantiateStore(parameters);
        }
    }

    public queryStringToObject(querystring: string)
    {
        if (!querystring || querystring === "")
        {
            return undefined;
        }
        else
        {
            let pairs = querystring.slice(1).split('&');
            let result = {};

            pairs.forEach(
                function(pair: any)
                {
                    pair = pair.split('=');
                    result[pair[0]] = decodeURIComponent(pair[1] || '');
                });

            return JSON.parse(JSON.stringify(result));
        }
    }

    public objectToQueryString(parameters: any = {})
    {
        const searchParams = new URLSearchParams();

        Object.keys(parameters)
            .filter(
                key =>
                    parameters[key] !== undefined)
            .forEach(
                key =>
                    searchParams.set(key, parameters[key]));

        return searchParams.toString();
    }
}
