import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import ViewGroup from '../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import Card from '../../../../../../../../../@Future/Component/Generic/Card/Card';
import useTypes from '../../../../../../Type/Api/useTypes';
import { createTransactionalModel } from '../../../../../../../../../@Util/TransactionalModelV2/Model/TransactionalModel';
import { Entity } from '../../../../../../../../../@Api/Model/Implementation/Entity';
import { loadModuleDirectly } from '../../../../../../../../../@Util/DependencyInjection/Injection/DependencyInjection';
import { EntityTypeStore } from '../../../../../../Type/EntityTypeStore';
import getDatastoreByCode from '../../../../../../../../../@Api/Entity/Bespoke/Datastore/getDatastoreByCode';
import { appointmentType } from '../LinkExternalAppointment';
import useRelationshipByAddressee from '../../../../Email/ExternalEmail/Api/useRelationshipByAddressee';
import ExternalAppointment from '../../ExternalAppointment';
import Constructor from '../../../../../../Constructor/Constructor';
import { canRefreshComposeItem, refreshComposeItem, storeEntityIdInItem } from '../../../../../../../../../OfficeAddin';
import styles from './CreateAppointment.module.scss';
import IconButton from '../../../../../../../../../@Future/Component/Generic/Button/Variant/Icon/IconButton';
import LocalizerContext from '../../../../../../../../../@Service/Localization/LocalizerContext';
import { CurrentUserStore } from '../../../../../../../User/CurrentUserStore';
import SuccessButton from '../../../../../../../../../@Future/Component/Generic/Button/Variant/SuccessButton/SuccessButton';
import SectionLabel from '../../../../../../../../../@Future/Component/Generic/Label/Variant/SectionLabel/SectionLabel';
import SaveButton from '../../../../../../../../../@Future/Component/Generic/Button/Variant/SaveButton/SaveButton';
import CancelButton from '../../../../../../../../../@Future/Component/Generic/Button/Variant/CancelButton/CancelButton';
import deleteEntity from '../../../../../../../../../@Api/Entity/deleteEntity';

export interface CreateEmailProps
{
    externalAppointment: ExternalAppointment;
}

const CreateAppointment: React.FC<CreateEmailProps> =
    props =>
    {
        const types = useTypes();
        const [ appointment, setAppointment ] = useState<Entity | undefined>();

        const startTracking =
            useCallback(
                () =>
                {
                    const _appointment =
                        appointment
                            ||
                        createTransactionalModel(
                            new Entity(types.Activity.Appointment.Type)
                                .initialize());

                    getDatastoreByCode(
                        types.Datastore.Phase.ActivityAppointment.Type,
                        new Date() < props.externalAppointment.endDate ? 'Planned' : 'Finished')
                        .then(
                            sentPhase =>
                                _appointment.updateRelationship(
                                    false,
                                    types.Activity.Appointment.RelationshipDefinition.Phase,
                                    createTransactionalModel(sentPhase)));

                    if (props.externalAppointment.isOrganizedByMe)
                    {
                        _appointment.updateRelationship(
                            false,
                            types.Activity.Appointment.RelationshipDefinition.Owner,
                            createTransactionalModel(loadModuleDirectly(CurrentUserStore).employeeEntity));
                    }
                    else
                    {
                        const externalId =
                            createTransactionalModel(
                                new Entity(types.ExternalId.Type)
                                    .initialize(loadModuleDirectly(EntityTypeStore)));

                        externalId.setValueByField(
                            types.ExternalId.Field.Service,
                            props.externalAppointment.service);

                        externalId.setValueByField(
                            types.ExternalId.Field.Type,
                            appointmentType);

                        externalId.setValueByField(
                            types.ExternalId.Field.Id,
                            props.externalAppointment.id);

                        externalId.updateRelationship(
                            true,
                            types.Entity.RelationshipDefinition.ExternalIds,
                            _appointment);
                    }

                    setAppointment(_appointment);
                },
                [
                    types,
                    props.externalAppointment,
                    appointment,
                    setAppointment
                ]);

        const stopTracking =
            useCallback(
                () =>
                    deleteEntity(appointment)
                        .then(
                            () =>
                                setAppointment(undefined)),
                [
                    appointment,
                    setAppointment
                ]);

        const isTracked =
            useMemo(
                () =>
                    appointment !== undefined,
                [
                    appointment
                ]);

        const relationshipAddressee =
            useMemo(
                () =>
                    props.externalAppointment.participants
                        .find(
                            participant =>
                                !participant.isMe),
                [
                    props.externalAppointment
                ]);

        const [ relationship, isLoading ] = useRelationshipByAddressee(relationshipAddressee);

        useEffect(
            () =>
            {
                if (appointment
                    && !isLoading
                    && relationship)
                {
                    appointment.updateRelationship(
                        true,
                        types.Relationship.RelationshipDefinition.Activities,
                        createTransactionalModel(relationship));
                }
            },
            [
                isLoading,
                relationship,
                appointment,
                types
            ]);

        useEffect(
            () =>
            {
                if (appointment)
                {
                    appointment.setValueByField(
                        types.Activity.Field.Subject,
                        props.externalAppointment.subject);

                    appointment.setValueByField(
                        types.Activity.Appointment.Field.Location,
                        props.externalAppointment.location);

                    appointment.setValueByField(
                        types.Activity.Appointment.Field.Description,
                        props.externalAppointment.body);

                    appointment.setValueByField(
                        types.Activity.Appointment.Field.StartDate,
                        props.externalAppointment.startDate);

                    appointment.setValueByField(
                        types.Activity.Appointment.Field.EndDate,
                        props.externalAppointment.endDate);
                }
            },
            [
                appointment,
                props.externalAppointment
            ]);

        // const onConstruct =
        //     useCallback(
        //         (relationship: Entity) =>
        //             augmentRelationshipWithAddressee(
        //                 relationship,
        //                 props.ex.sender),
        //         [
        //             props.externalEmail
        //         ]);
        //
        // const isContactVisible =
        //     useComputed(
        //         () =>
        //             !email.entityType.bespoke.hideFieldPath(
        //                 email,
        //                 EntityPath.fromEntity(email)
        //                     .joinTo(
        //                         types.Relationship.Person.Contact.RelationshipDefinition.Activities,
        //                         true)
        //                     .field()),
        //         [
        //             email,
        //             types
        //         ]);

        const save =
            useCallback(
                () =>
                {
                    return appointment.checkAndDoCommit()
                        .then(
                            () =>
                            {
                                const calendarItem =
                                    appointment.getRelatedEntityByDefinition(
                                        false,
                                        types.Activity.Appointment.RelationshipDefinition.CalendarItem);

                                if (calendarItem)
                                {
                                    return storeEntityIdInItem(calendarItem.uuid)
                                        .then(
                                            () =>
                                                refreshComposeItem(true, true, true, true));
                                }
                                else
                                {
                                    return refreshComposeItem(true, true, true, true);
                                }
                            });
                },
                [
                    appointment,
                    types
                ]);

        const localizer = useContext(LocalizerContext);

        return <ViewGroup
            orientation="vertical"
            spacing={20}
        >
            <ViewGroupItem>
                <ViewGroup
                    orientation="horizontal"
                    spacing={5}
                    alignment="center"
                >
                    <ViewGroupItem
                        ratio={1}
                    >
                        <SectionLabel>
                            Afspraak vastleggen
                        </SectionLabel>
                    </ViewGroupItem>
                    {
                        isTracked && canRefreshComposeItem() &&
                            <ViewGroupItem
                                className={styles.syncLabel}
                            >
                                Laatste sync. om {localizer.formatTime(props.externalAppointment.syncDate, true)}
                            </ViewGroupItem>
                    }
                    {
                        isTracked && canRefreshComposeItem() &&
                            <ViewGroupItem>
                                <IconButton
                                    icon="refresh"
                                    onClick={() => refreshComposeItem(true, true, true, true)}
                                    tooltip="Inhoud synchronizeren met afspraak"
                                />
                            </ViewGroupItem>
                    }
                </ViewGroup>
            </ViewGroupItem>
            {
                isTracked &&
                    <ViewGroupItem>
                        <Card
                            inset
                        >
                            <ViewGroup
                                orientation="vertical"
                                spacing={15}
                            >
                                <ViewGroupItem>
                                    <Constructor
                                        entity={appointment}
                                        autoCommit={false}
                                        disableActions
                                    />
                                </ViewGroupItem>
                                <ViewGroupItem>
                                    <ViewGroup
                                        orientation="vertical"
                                        spacing={5}
                                    >
                                        <ViewGroupItem>
                                            <SaveButton
                                                fullWidth
                                                onClick={save}
                                            />
                                        </ViewGroupItem>
                                        <ViewGroupItem>
                                            <CancelButton
                                                onClick={stopTracking}
                                                fullWidth
                                            />
                                        </ViewGroupItem>
                                    </ViewGroup>
                                </ViewGroupItem>
                            </ViewGroup>
                        </Card>
                    </ViewGroupItem>
            }
            {
                !isTracked &&
                    <ViewGroupItem>
                        <SuccessButton
                            fullWidth
                            onClick={startTracking}
                            label="Afspraak vastleggen in Tribe"
                        />
                    </ViewGroupItem>
            }
        </ViewGroup>;
    };

export default observer(CreateAppointment);
