import React, { useContext, useEffect } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import getTypes from '../Entity/Type/Api/getTypes';
import CurrentUserContext from '../User/CurrentUserContext';
import useAsyncResult from '../../../@Util/Async/useAsyncResult';
import performAction from '../../../@Api/Entity/performAction';
import { useIntercom } from 'react-use-intercom';
import useEntityValue from '../../../@Api/Entity/Hooks/useEntityValue';
import { loadModuleDirectly } from '../../../@Util/DependencyInjection/index';
import { Localizer } from '../../../@Service/Localization/Localizer';
import { useNumberOfActiveUserLicenses } from '../License/Contract/Api/useNumberOfActiveUserLicenses';

export interface IntercomDetailsProps
{

}

export const IntercomDetails: React.FC<IntercomDetailsProps> = observer(
    () =>
    {
        const types = getTypes();
        const currentUserStore = useContext(CurrentUserContext);
        const userName = currentUserStore.employeeEntity.name;
        const email = currentUserStore.currentUser.account.username;
        const createdAt = currentUserStore.currentUser.account.creationDate / 1000;
        const isAdmin = useEntityValue(currentUserStore.employeeEntity, types.Relationship.Person.Contact.Employee.Field.IsAdministrator);
        const companyId = `TRIBE_${currentUserStore.environmentEntity.id}`;
        const companyName = currentUserStore.environmentEntity.name;
        const userId = `TRIBE_${companyName}_${currentUserStore.employeeEntity.id}`;

        const [ hmac, isLoading ] =
            useAsyncResult(
                () =>
                    performAction<string>(
                        undefined,
                        {
                            code: 'Intercom.HmacGenerator',
                            parameters: {
                                text: userId
                            }
                        })
                        .then(
                            result =>
                                result.result
                        ),
                [
                    userId
                ]);

        const localizer = loadModuleDirectly(Localizer);
        const country = localizer.countryCode;
        const lang = localizer.languageCode;

        const contract =
            useComputed(
                () =>
                    currentUserStore.contractEntity,
                [
                    currentUserStore
                ]);

        const isTrial =
            useComputed(
                () =>
                    !contract?.hasValueForField(types.LicenseContract.Field.ActivationDate),
                [
                    currentUserStore,
                    types
                ]);

        const numberOfLicenses = useNumberOfActiveUserLicenses(contract);

        const { update } = useIntercom();

        useEffect(() =>
        {
            update({
                name: userName,
                email: email,
                userId: userId,
                createdAt: createdAt,
                company: {
                    companyId: companyId,
                    name: companyName
                },
                userHash: hmac,
                customAttributes: {
                    'id_account': companyId,
                    'is_admin': isAdmin,
                    'product': 'TribeCRM',
                    'customer_country_code': country.toUpperCase(),
                    'customer_type': isTrial ? "trial" : "customer",
                    'number_of_licenses': numberOfLicenses,
                    'user_language': lang.toUpperCase(),
                }
            })
        },
        [
            update,
            userName,
            email,
            userId,
            createdAt,
            companyId,
            companyName,
            hmac,
            isAdmin,
            lang,
            country,
            isTrial,
            numberOfLicenses
        ]);

        return <></>;
    }
)