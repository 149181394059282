import * as React from 'react';
import PdfViewer from '../../../@Future/Component/Generic/PdfViewer/PdfViewer';
import { useFetchedApiFile } from '../../../@Service/ApiClient/Hooks/useFetchedApiFile';
import Centered from '../../../@Future/Component/Generic/Centered/Centered';
import { Loader } from '../Loader/Loader';

export interface FileViewerProps
{
    url: string;
    docxUrl?: string;
    type?: 'pdf' | 'image';
}

const FileViewer: React.FC<FileViewerProps> =
    ({
         url,
         docxUrl,
         type,
     }) =>
    {
        const [ initializedUrl, isLoading ] = useFetchedApiFile(url);

        if (isLoading)
        {
            return <Centered
                horizontal
            >
                <Loader />
            </Centered>;
        }
        else if (initializedUrl === undefined)
        {
            return null;
        }
        else
        {
            if (type === 'pdf')
            {
                return <PdfViewer
                    url={url}
                    initializedUrl={initializedUrl}
                    docxUrl={docxUrl}
                />;
            }
            else
            {
                return <div
                    style={{
                        display: 'flex',
                        flex: '1 1 auto',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <img
                        alt=""
                        src={initializedUrl}
                        width="100%"
                    />
                </div>;
            }
        }
    };

export default FileViewer;
