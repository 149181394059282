import { ApiClient } from '../../../@Service/ApiClient/ApiClient';
import { ApiRequest, Method } from '../../../@Service/ApiClient/Model/ApiRequest';
import { fromJson } from '../../../@Util/Serialization/Serialization';
import { GlobalEnvironment } from '../../../@Global/GlobalEnvironment';

export class MicrosoftExchangeConnectorController
{
    apiClient: ApiClient;

    constructor(apiClient: ApiClient)
    {
        this.apiClient = apiClient;
    }

    discoverExchangeServerUrl(username: string,
                              password: string)
    {
        return this.apiClient.request(
            new ApiRequest<string>(
                '/connector/microsoft-exchange/discover-exchange-server-url',
                Method.Post,
                {
                    username: username,
                    password: password
                }))
            .then(
                result =>
                    Promise.resolve(fromJson<string>(result)));
    }

    testCredentials(url: string,
                    username: string,
                    password: string)
    {
        return this.apiClient.request(
            new ApiRequest<string>(
                '/connector/microsoft-exchange/test-credentials',
                Method.Post,
                {
                    url: url,
                    username: username,
                    password: password
                }))
            .then(
                result =>
                    Promise.resolve(fromJson<boolean>(result)));
    }

    downloadAttachmentFromExchangeServer(ewsUrl: string,
                                         token: string,
                                         itemId: string,
                                         attachmentId: string)
    {
        return this.apiClient.request(
            new ApiRequest<any>(
                GlobalEnvironment.FUNCTION_MSEXCHANGE_DOWNLOAD_ATTACHMENT,
                // '/connector/microsoft-exchange/download-attachment',
                Method.Get,
                {
                    ews_url: ewsUrl,
                    token: token,
                    item_id: itemId,
                    attachment_id: attachmentId
                },
                undefined,
                undefined,
                undefined,
                undefined,
                true,
                undefined,
                'Blob'))
            .then(
                result =>
                    Promise.resolve(result));
    }
}
