import React, { useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { loadModuleDirectly } from '../../../@Util/DependencyInjection/index';
import { ApiClient } from '../../../@Service/ApiClient/ApiClient';
import Room from './Model/Room';
import Connection from './Model/Connection';
import { runInAction } from 'mobx';
import Pointer from './Model/Pointer';
import MultiplayerContext, { Multiplayer as MultiplayerModel } from './Context/MultiplayerContext';
import { fromJson } from '../../../@Util/Serialization/Serialization';
import { EntityEvent } from '../../../@Api/Model/Implementation/EntityEvent';
import EntityCacheContext from '../../Service/Entity/EntityCacheContext';
import EntityTypeContext from '../Entity/Type/EntityTypeContext';
import moment from 'moment';
import reloadWindow from '../../Generic/Window/reloadWindow';
import { EntityMetadataMutation } from '../../../@Api/Model/Implementation/EntityMetadataMutation';
import AuthenticationManagerContext from '../../../@Service/Authentication/AuthenticationManagerContext';

export interface MultiplayerProps
{

}

const Multiplayer: React.FC<MultiplayerProps> =
    props =>
    {
        const [ multiplayer, setMultiplayer ] = useState<MultiplayerModel | undefined>();
        const entityTypeStore = useContext(EntityTypeContext);
        const entityCacheService = useContext(EntityCacheContext);
        const authenticationManager = useContext(AuthenticationManagerContext);

        useEffect(
            () =>
            {
                // Try catch for outlook add-in
                try
                {
                    let room: Room;
                    let isRoomInitialized = false;
                    let openDate: moment.Moment;

                    const connectWebSocket =
                        () =>
                        {
                            isRoomInitialized = false;
                            openDate = undefined;

                            const connectionId = entityCacheService.connectionId;
                            const socket =
                                new WebSocket(
                                    loadModuleDirectly(ApiClient)
                                        .url('/multiplayer')
                                        .replace('https:', 'wss:')
                                        .replace('http:', 'ws:'));

                            socket.onopen =
                                () =>
                                {
                                    openDate = moment();

                                    authenticationManager.getAccessToken()
                                        .then(
                                            accessToken =>
                                                socket.send(
                                                    JSON.stringify({
                                                        type: 'Register',
                                                        accessToken,
                                                        connectionId,
                                                    })
                                                )
                                        );
                                };

                            socket.onmessage =
                                event =>
                                    runInAction(
                                        async () =>
                                        {
                                            const message = JSON.parse(event.data);
                                            let connection: Connection;

                                            if (message.connectionId && room)
                                            {
                                                connection = room.connectionById.get(message.connectionId);

                                                if (!connection)
                                                    return;
                                            }

                                            switch (message.type)
                                            {
                                                case 'Pong':
                                                    break;

                                                case 'Reload':
                                                    reloadWindow();
                                                    break;

                                                case 'Reconnect':
                                                    socket.close();
                                                    stopPinging();
                                                    connectAndStartPinging();

                                                    break;

                                                case 'Initiation':
                                                    room = Room.fromDescriptor(message.room);
                                                    setMultiplayer({
                                                        room: room,
                                                        connection: room.connectionById.get(message.connectionId),
                                                        webSocket: socket
                                                    });

                                                    isRoomInitialized = true;

                                                    break;

                                                case 'Connect':
                                                    const newConnection =
                                                        Connection.fromDescriptor(
                                                            message.connection);
                                                    room.connections.push(newConnection);

                                                    break;

                                                case 'Disconnect':
                                                    room.connections =
                                                        room.connections
                                                            .filter(
                                                                checkConnection =>
                                                                    checkConnection !== connection);

                                                    break;

                                                case 'Entity.Enter':
                                                    connection.viewingEntityId = message.entityId;
                                                    break;

                                                case 'Entity.Leave':
                                                    connection.viewingEntityId = undefined;
                                                    break;

                                                case 'Entity.Focus':
                                                    connection.focusPointer = Pointer.fromDescriptor(message.focusPointer);
                                                    break;

                                                case 'Entity.Update':
                                                    const focusPointer = Pointer.fromDescriptor(message);

                                                    if (connection.focusPointer
                                                        && focusPointer
                                                        && connection.focusPointer.id === focusPointer.id)
                                                    {
                                                        connection.focusPointer.value = message.value;
                                                    }

                                                    break;

                                                case 'Entity.Blur':
                                                    const blurPointer = Pointer.fromDescriptor(message.focusPointer);

                                                    if (connection.focusPointer
                                                        && blurPointer
                                                        && connection.focusPointer?.id() === blurPointer.id())
                                                    {
                                                        connection.focusPointer = undefined;
                                                    }

                                                    break;

                                                case 'Entity.Event':
                                                    const event = fromJson(message.event, EntityEvent);

                                                    // - If commit was not already registered as being started by
                                                    // this client, then start to process event from this 'foreign'
                                                    // commit.
                                                    // - Events that are started by this client are applied when saving
                                                    // is finished (not through this mechanism)
                                                    if (!entityCacheService.hasCommit(event.commitId))
                                                    {
                                                        entityCacheService.processForeignEvent(event);
                                                    }

                                                    break;

                                                case 'Metadata.Mutation':
                                                    const mutation = fromJson(message.mutation) as EntityMetadataMutation;

                                                    entityTypeStore.applyMetadataMutation(mutation);

                                                    break;

                                            }
                                        });

                            socket.onclose =
                                () =>
                                {
                                    setMultiplayer(undefined);
                                };

                            socket.onerror =
                                event =>
                                {
                                    console.log('Socket error', event);
                                };

                            return socket;
                        };

                    let backoffPeriodInMillis = 1000;
                    let socket;
                    let pingInterval;

                    const stopPinging =
                        () =>
                        {
                            clearInterval(pingInterval);
                        };

                    const connectAndStartPinging =
                        () =>
                        {
                            socket = connectWebSocket();

                            pingInterval =
                                setInterval(
                                    () =>
                                    {
                                        if (socket.readyState === WebSocket.CLOSING
                                            || socket.readyState === WebSocket.CLOSED
                                            || (!isRoomInitialized && openDate !== undefined && moment().diff(openDate, 'second') >= 10 && !isRoomInitialized))
                                        {
                                            try
                                            {
                                                socket.close();
                                            }
                                            catch (e)
                                            {

                                            }
                                            finally
                                            {
                                                stopPinging();

                                                backoffPeriodInMillis += 1000;

                                                setTimeout(
                                                    () =>
                                                    {
                                                        connectAndStartPinging();
                                                    },
                                                    // At least try once every 10 minutes
                                                    Math.min(backoffPeriodInMillis, 600000));
                                            }
                                        }
                                        else if (socket.readyState === WebSocket.OPEN)
                                        {
                                            // Reset backoff period
                                            backoffPeriodInMillis = 0;

                                            socket.send(
                                                JSON.stringify({
                                                    type: 'Ping'
                                                }));
                                        }
                                    },
                                    5000);
                        };

                    connectAndStartPinging();

                    return () =>
                    {
                        clearInterval(pingInterval);
                        // clearInterval(checkRefreshInterval);
                        socket.close();
                    };
                }
                catch (e)
                {
                    console.error(e);
                }
            },
            [
                setMultiplayer,
                entityTypeStore,
                entityCacheService,
                authenticationManager,
            ]);

        return <MultiplayerContext.Provider
            value={multiplayer}
        >
            {props.children}
        </MultiplayerContext.Provider>;
    };

export default observer(Multiplayer);
