import { ApiClient } from '../ApiClient/ApiClient';
import { Cookie } from '../Cookie/Cookie';
import { ApiRequest, Method } from '../ApiClient/Model/ApiRequest';
import { AccountUserController } from '../../@Api/Controller/Directory/AccountUserController';
import { action, observable } from 'mobx';
import { injectWithQualifier } from '../../@Util/DependencyInjection/index';
import { StatusController } from '../../@Api/Controller/Directory/StatusController';
import { User } from '../../@Api/Model/Implementation/User';
import { BaseStore } from '../../@Framework/Store/BaseStore';
import { StoreState } from '../../@Framework/Store/@Model/StoreState';
import { consoleLog } from '../../@Future/Util/Logging/consoleLog';
import { GlobalEnvironment } from '../../@Global/GlobalEnvironment';
import { RouterStore } from '../Router/RouterStore';
import { WelcomeStore } from '../Welcome/WelcomeStore';
import isInIframe from '../../@Future/Util/Iframe/IsInIframe';
import areCredentialsStored from '../../@Future/Util/Credentials/areCredentialsStored';
import { isInOffice } from '../../OfficeAddin';
import { Entity } from '../../@Api/Model/Implementation/Entity';
import hasAccessToken from './Api/hasAccessToken';
import deleteAccessToken from './Api/deleteAccessToken';
import getAccessToken from './Api/getAccessToken';
import setAccessToken from './Api/setAccessToken';
import getErrorCode from '../../@Api/Error/getErrorCode';
import getBrowserLanguageCode from '../../@Component/App/Root/Environment/Public/Registration/Api/getBrowserLanguageCode';
import { getAuthenticationEntryPoint } from '../../@Api/AuthenticationEntryPoint/Api/getAuthenticationEntryPoint';
import { setIdToken } from './Api/setIdToken';
import { getIdToken } from './Api/getIdToken';
import { EntranceState } from '../EntranceState/Model/EntranceState';
import uuid from '../../@Util/Id/uuid';
import { serializeEntranceState } from '../EntranceState/Api/serializeEntranceState';
import sendAnalyticsLogging, { EventTypes } from '../../@Util/Analytics/sendAnalyticsLogging';
import * as amplitude from '@amplitude/analytics-browser';

type Callback = () => Promise<any>;

export class AuthenticationManager extends BaseStore
{
    // ------------------------ Dependencies ------------------------

    @injectWithQualifier('StatusController') statusController: StatusController;
    @injectWithQualifier('RouterStore') routerStore: RouterStore;
    @injectWithQualifier('WelcomeStore') welcomeStore: WelcomeStore;

    // ------------------------- Properties -------------------------

    @observable apiClient: ApiClient;
    @observable accountUserController: AccountUserController;
    @observable isAuthenticated: boolean;
    @observable contextSwitchCallbacks = observable.array();

    // ------------------------ Constructor -------------------------

    constructor(
        authApiClient: ApiClient,
        accountUserController: AccountUserController
    )
    {
        super();

        this.apiClient = authApiClient;
        this.accountUserController = accountUserController;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    @action
    setAuthenticated(isAuthenticated: boolean)
    {
        this.isAuthenticated = isAuthenticated;
    }

    @action
    onContextSwitch(callback: Callback)
    {
        this.contextSwitchCallbacks.push(callback);
    }

    @action
    fireContextSwitchCallbacks()
    {
        return Promise.all(
            this.contextSwitchCallbacks.map(
                callback =>
                    callback()));
    }

    // ------------------------ Public logic ------------------------

    /**
     * Initializes the authentication manager.
     *
     * @returns {Promise<boolean>}
     */
    public initialize(): Promise<boolean>
    {
        return this.resolveAuthentication();
    }

    public fetchAccessTokenByCode(code: string, stateToken: string): Promise<any>
    {
        return Cookie.get('state_token', null)
            .then(
                cookie =>
                {
                    if (cookie && cookie.value === stateToken)
                    {
                        return Cookie.delete('state_token')
                            .then(
                                () =>
                                    getAuthenticationEntryPoint(this.apiClient)
                            )
                            .then(
                                authenticationEntryPoint =>
                                    this.apiClient.request(
                                        new ApiRequest(
                                            GlobalEnvironment.OAUTH_TOKEN_URL,
                                            Method.Post,
                                            {
                                                client_id: authenticationEntryPoint.clientId,
                                                grant_type: 'authorization_code',
                                                redirect_uri: authenticationEntryPoint.redirectUri,
                                                code: code
                                            },
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            true,
                                            undefined
                                        )
                                    )
                            );
                    }
                    else
                    {
                        consoleLog('Error while fetching access token: invalid state token.');
                        return Promise.resolve(false);
                    }
                });
    }

    /**
     * Determines whether we have an access token
     *
     * @returns {Promise<boolean>}
     */
    public hasAuthentication(): Promise<boolean>
    {
        return hasAccessToken();
    }

    /**
     * Gets the access token.
     */
    public getAccessToken(): Promise<string>
    {
        return getAccessToken();
    }

    /**
     * Resolves the authentication.
     *
     * @returns {Promise<boolean>}
     */
    public resolveAuthentication(): Promise<boolean>
    {
        const urlParams = new URLSearchParams(window.location.search);

        if (urlParams.has('access_token'))
        {
            this.initializeAccessToken(urlParams.get('access_token'));
            this.setAuthenticated(true);

            return Promise.resolve(true);
        }

        return this.hasAuthentication()
            .then(hasAuthentication =>
            {
                if (hasAuthentication)
                {
                    return this.getAccessToken()
                        .then(accessToken =>
                        {
                            this.initializeAccessToken(accessToken);

                            return this.statusController.getStatus()
                                .then(
                                    () =>
                                    {
                                        this.setAuthenticated(true);
                                        return Promise.resolve(true);
                                    })
                                .catch(
                                    () =>
                                        this.deleteAuthentication()
                                            .then(
                                                () =>
                                                {
                                                    this.setAuthenticated(false);

                                                    return Promise.resolve(false);
                                                }));
                        });
                }
                else
                {
                    this.setAuthenticated(false);
                    return Promise.resolve(false);
                }
            });
    }

    /**
     * Initializes access token.
     *
     * @param {string} accessToken
     */
    public initializeAccessToken(accessToken: string): void
    {
        this.apiClient.setDefaultHeader('Authorization', 'Bearer ' + accessToken);
    }

    public setAccessToken(
        accessToken: string,
        idToken: string,
        expiresInMilliseconds: number
    ): Promise<boolean>
    {
        if (expiresInMilliseconds !== undefined)
        {
            expiresInMilliseconds = expiresInMilliseconds + 3600 + 3600;
        }

        return Promise.all(
            [
                setAccessToken(
                    accessToken,
                    expiresInMilliseconds
                ),
                setIdToken(
                    idToken,
                    expiresInMilliseconds
                ),
            ])
            .then(
                () =>
                {
                    this.initializeAccessToken(accessToken);
                    this.setAuthenticated(true);

                    return Promise.resolve(true);
                }
            );
    }

    public getLoginUri(
        username?: string,
        language?: string,
        organizationId?: string,
        portalId?: string,
        singleShotLoginToken?: string,
        keepCurrentRoute: boolean = true,
        stateToken?: string,
        clientId = this.getClientId(),
        redirectUri = new URL(this.getRedirectUri()),
        closeWindow: boolean = false
    )
    {
        stateToken = stateToken || uuid();

        const credentialsStored = areCredentialsStored();
        const state =
            stateToken
            + `|${keepCurrentRoute ? (this.routerStore.entrancePath || '') : ''}`
            + (username ? `|${username}` : '|')
            + (singleShotLoginToken ? `|${singleShotLoginToken}` : '|')
            + (credentialsStored ? `|store-credentials` : '')
            + (closeWindow ? '|close_window' : '');

        const uri =
            GlobalEnvironment.OAUTH_AUTH_URL +
            '?client_id=' + encodeURIComponent(clientId) +
            '&redirect_uri=' + encodeURIComponent(redirectUri.toString()) +
            '&response_type=code' +
            '&scope=' + encodeURIComponent(GlobalEnvironment.OAUTH_SCOPE) +
            '&state=' + encodeURIComponent(state) +
            (organizationId ? `&organization_id=${encodeURIComponent(organizationId)}` : '') +
            (portalId ? `&portal_id=${encodeURIComponent(portalId)}` : '') +
            (language ? `&language_code=${encodeURIComponent(language)}` : '');

        return uri;
    }

    private probeOrganizationId()
    {
        return this.probeOrganizationIdFromUrl()
            || this.probeAndConsumeOrganizationIdFromSessionStorage();
    }

    public probeOrganizationIdFromUrl()
    {
        return new URLSearchParams(window.location.search).get('organizationId');
    }

    private probeAndConsumeOrganizationIdFromSessionStorage()
    {
        if (window.sessionStorage)
        {
            const organizationId = window.sessionStorage.getItem('organizationId');

            if (organizationId)
            {
                window.sessionStorage.removeItem('organizationId');
            }

            return organizationId;
        }
    }

    private setOrganizationIdInSessionStorage(organizationId?: string)
    {
        if (window.sessionStorage)
        {
            if (organizationId)
            {
                window.sessionStorage.setItem('organizationId', organizationId);
            }
            else
            {
                window.sessionStorage.removeItem('organizationId');
            }
        }
    }

    private probePortalId()
    {
        return this.probePortalIdFromUrl()
            || this.probeAndConsumePortalIdFromSessionStorage();
    }

    public probePortalIdFromUrl()
    {
        return new URLSearchParams(window.location.search).get('portalId');
    }

    private probeAndConsumePortalIdFromSessionStorage()
    {
        if (window.sessionStorage)
        {
            const portalId = window.sessionStorage.getItem('portalId');

            if (portalId)
            {
                window.sessionStorage.removeItem('portalId');
            }

            return portalId;
        }
    }

    private setPortalIdInSessionStorage(portalId?: string)
    {
        if (window.sessionStorage)
        {
            if (portalId)
            {
                window.sessionStorage.setItem('portalId', portalId);
            }
            else
            {
                window.sessionStorage.removeItem('portalId');
            }
        }
    }

    private probeUsername()
    {
        return this.probeUsernameFromUrl()
            || this.probeAndConsumeUsernameFromSessionStorage();
    }

    private probeUsernameFromUrl()
    {
        return new URLSearchParams(window.location.search).get('username');
    }

    private probeAndConsumeUsernameFromSessionStorage()
    {
        if (window.sessionStorage)
        {
            const username = window.sessionStorage.getItem('username');

            if (username)
            {
                window.sessionStorage.removeItem('username');
            }

            return username;
        }
    }

    private setUsernameInSessionStorage(username?: string)
    {
        if (window.sessionStorage)
        {
            if (username)
            {
                window.sessionStorage.setItem('username', username);
            }
            else
            {
                window.sessionStorage.deleteItem('username');
            }
        }
    }

    private setUserInStorage(user: User)
    {
        this.setOrganizationIdInSessionStorage(user.organization.uuid);
        this.setPortalIdInSessionStorage(user.portal?.uuid);
        this.setUsernameInSessionStorage(user.account.username);
    }

    public async navigateToLogin(
        username?: string,
        language?: string,
        singleShotLoginToken?: string,
        keepCurrentRoute: boolean = true,
        isAutomatedCall: boolean = false,
        organizationId?: string,
        portalId?: string
    ): Promise<boolean>
    {
        if (!username)
        {
            username = this.probeUsername();
        }

        if (!organizationId)
        {
            organizationId = this.probeOrganizationId();
        }

        if (!portalId)
        {
            portalId = this.probePortalId();
        }

        const stateToken = uuid();
        const languageCode = language ? language : getBrowserLanguageCode();
        const isInIframeValue = isInIframe();
        const authenticationEntryPoint = await getAuthenticationEntryPoint(this.apiClient);
        const uri =
            this.getLoginUri(
                username,
                languageCode,
                authenticationEntryPoint.organizationId ?? organizationId,
                authenticationEntryPoint.portalId ?? portalId,
                singleShotLoginToken,
                keepCurrentRoute,
                stateToken,
                authenticationEntryPoint.clientId,
                authenticationEntryPoint.redirectUri
                    ? new URL(authenticationEntryPoint.redirectUri)
                    : undefined,
                isInIframeValue
            );

        return Promise.all(
            [
                Cookie.set('state_token', stateToken, undefined)
            ])
            .then(results =>
            {
                if (isInIframeValue)
                {
                    if (isInOffice())
                    {
                        const Office = (window as any).Office;

                        // Office Add-in do not allow for the login popup to automatically open, the user must press the login button first
                        if (Office && !isAutomatedCall)
                        {
                            const self = this;

                            Office.onReady(
                                function()
                                {
                                    if (Office.context
                                        && Office.context.ui)
                                    {
                                        const url = new URL(GlobalEnvironment.APP_ENDPOINT);
                                        url.searchParams.append('store-credentials', 'true');

                                        if (organizationId)
                                            url.searchParams.append('organizationId', organizationId);

                                        if (portalId)
                                            url.searchParams.append('portalId', portalId);

                                        url.pathname = '/microsoft-outlook/authenticate';

                                        Office.context.ui.displayDialogAsync(
                                            url.toString(),
                                            {
                                                width: 50,
                                                height: 50
                                            },
                                            asyncResult =>
                                            {
                                                const dialog = asyncResult.value;
                                                let checkInterval;

                                                const completeAuthentication =
                                                    (doCloseDialog: boolean) =>
                                                    {
                                                        if (doCloseDialog)
                                                        {
                                                            dialog.close();
                                                        }

                                                        clearInterval(checkInterval);

                                                        self.resolveAuthentication()
                                                            .then(
                                                                () =>
                                                                {
                                                                    window.location.href = `/microsoft-outlook${isInIframeValue ? '?framed' : ''}`;
                                                                }
                                                            );
                                                    };

                                                dialog.addEventHandler(
                                                    Office.EventType.DialogMessageReceived,
                                                    arg =>
                                                    {
                                                        if (arg.message === 'authenticated')
                                                        {
                                                            completeAuthentication(true);
                                                        }
                                                    });

                                                // Fallback (dialog message received event does not arrive in web browsers - it does in Office app)
                                                checkInterval =
                                                    setInterval(
                                                        () =>
                                                        {
                                                            hasAccessToken()
                                                                .then(
                                                                    hasAccessToken =>
                                                                    {
                                                                        if (hasAccessToken)
                                                                        {
                                                                            // in web add-in in online Office,
                                                                            // closing the dialog results in an infinite loop
                                                                            completeAuthentication(false);
                                                                        }
                                                                    })
                                                        },
                                                        1000
                                                    );
                                            });
                                    }
                                });
                            }
                    }
                    else
                    {
                        const width = 850;
                        const height = 480;
                        const left = (window.outerWidth / 2 - width / 2) + window.screenX;
                        const top = (window.outerHeight / 2 - height / 2) + window.screenY;
                        const properties = 'left=' + left + ',top=' + top + ',width=' + width + ',height=' + height + ',resizable=no,scrollbars=yes,toolbar=no,menubar=no,location=no,directories=no,status=no';
                        const popup = window.open(uri.toString(), 'Tribe CRM', properties);

                        return new Promise<boolean>(
                            (resolve, reject) =>
                            {
                                const timer =
                                    setInterval(
                                        () =>
                                        {
                                            if (popup && popup.closed)
                                            {
                                                clearInterval(timer);

                                                this.resolveAuthentication()
                                                    .then(
                                                        isAuthenticated =>
                                                        {
                                                            if (isAuthenticated)
                                                            {
                                                                this.welcomeStore.reinitializeStore()
                                                                    .then(
                                                                        () =>
                                                                            resolve(true));
                                                            }
                                                            else
                                                            {
                                                                resolve(false);
                                                            }
                                                        }
                                                    );
                                            }
                                            else if (!popup)
                                            {
                                                clearInterval(timer);

                                                resolve(false);
                                            }
                                        },
                                        1000);
                            });
                    }
                }
                else
                {
                    window.location.href = uri;
                }

                return Promise.resolve(true);
            });
    }

    /**
     * Deletes the authentication.
     *
     * @returns {Promise<boolean>}
     */
    public deleteAuthentication(): Promise<boolean>
    {
        this.apiClient.setDefaultHeader('Authorization', null);

        return Promise.all([
            deleteAccessToken(),
        ])
        .then(() =>
        {
            this.setAuthenticated(false);

            return Promise.resolve(true);
        });
    }

    /**
     * Switches to a different user within the same account.
     *
     * @param user
     * @param doFireCallbacks
     */
    public switchToUser(user: User,
                        doFireCallbacks: boolean = true)
    {
        this.setState(StoreState.Loading);

        return this.accountUserController.switchUser(user.id)
            .then(() =>
            {
                if (doFireCallbacks)
                {
                    return this.fireContextSwitchCallbacks();
                }
            })
            .then(() =>
                this.setState(StoreState.Loaded))
            .catch(
                error =>
                {
                    const errorCode = getErrorCode(error);

                    if (errorCode === 'account-session-is-fixed-to-organization')
                    {
                        this.setUserInStorage(user);

                        return this.logout(
                            undefined,
                            user.organization.uuid
                        );
                    }
                    else if (errorCode === 'organization-has-login-strategy')
                    {
                        this.setUserInStorage(user);

                        return this.logout(
                            undefined,
                            user.organization.uuid
                        );
                    }
                });
    }

    /**
     * Switches to a different team within the same user.
     *
     * @param team
     */
    public switchToTeam(team?: Entity)
    {
        this.setState(StoreState.Loading);

        return this.accountUserController.switchTeam(team?.uuid)
            .then(() =>
                this.fireContextSwitchCallbacks())
            .then(() =>
                this.setState(StoreState.Loaded));
    }

    /**
     * Performs logout.
     *
     * @returns {Promise<boolean>}
     */
    public logout(
        forceRedirect: boolean = false,
        switchToOrganizationId: string | undefined = undefined,
        doRedirectToLoginAfterLogout: boolean = true
    ): Promise<boolean>
    {
        this.setState(StoreState.Loading);

        sendAnalyticsLogging(EventTypes.Logout);
        amplitude.reset();

        return this.accountUserController.logout()
            .then(isLoggedOut =>
            {
                if (isLoggedOut)
                {
                    return this.deleteAuthentication();
                }
                else
                {
                    return Promise.resolve(false);
                }
            })
            .finally(
                () =>
                {
                    if (isInOffice() && !forceRedirect)
                    {
                        const Office = (window as any).Office;
                        const self = this;

                        // Office Add-in do not allow for the login popup to automatically open, the user must press the login button first
                        Office.onReady(
                            function()
                            {
                                if (Office.context
                                    && Office.context.ui)
                                {
                                    const url = new URL(GlobalEnvironment.APP_ENDPOINT);
                                    url.pathname = '/logout';
                                    url.searchParams.set(
                                        'doRedirectToLoginAfterLogout',
                                        'false'
                                    );
                                    url.searchParams.set(
                                        'isInOfficeAddin',
                                        'true'
                                    );

                                    Office.context.ui.displayDialogAsync(
                                        url.toString(),
                                        {
                                            width: 50,
                                            height: 50
                                        },
                                        asyncResult =>
                                        {
                                            const dialog = asyncResult.value;
                                            const interval =
                                                setInterval(
                                                    () =>
                                                    {
                                                        self.hasAuthentication()
                                                            .then(
                                                                hasAuthentication =>
                                                                {
                                                                    if (!hasAuthentication)
                                                                    {
                                                                        clearInterval(interval);
                                                                        dialog.close();
                                                                        const flags = [
                                                                            areCredentialsStored() ? 'store-credentials' : undefined,
                                                                            isInIframe() ? 'framed' : undefined,
                                                                            switchToOrganizationId
                                                                                ? `organizationId=${encodeURIComponent(switchToOrganizationId)}`
                                                                                : undefined,
                                                                        ].filter(flag => flag !== undefined);

                                                                        window.location.href = `/microsoft-outlook${flags.length > 0 ? `?${flags.join('&')}` : ''}`;
                                                                    }
                                                                }
                                                            );
                                                    },
                                                    1000
                                                );
                                        });
                                }
                            });
                    }
                    else
                    {
                        return getIdToken()
                            .then(
                                idToken =>
                                {
                                    if (idToken)
                                    {
                                        const entranceState: EntranceState = {
                                            token: uuid(),
                                            doRedirectToLogin: doRedirectToLoginAfterLogout,
                                        };
                                        const logoutUrl = new URL(GlobalEnvironment.OAUTH_LOGOUT_URL);
                                        logoutUrl.searchParams.set(
                                            'id_token_hint',
                                            idToken
                                        );
                                        logoutUrl.searchParams.set(
                                            'state',
                                            serializeEntranceState(entranceState)
                                        );
                                        logoutUrl.searchParams.set(
                                            'post_logout_redirect_uri',
                                            GlobalEnvironment.APP_ENDPOINT
                                        );
                                        window.location.href = logoutUrl.toString();
                                    }
                                    else
                                    {
                                        window.location.href = GlobalEnvironment.OAUTH_LOGOUT_URL;
                                    }
                                }
                            );
                    }

                    return Promise.resolve(true);
                });
    }

    private getClientId(): string
    {
        return GlobalEnvironment.OAUTH_CLIENT_ID;
    }

    private getRedirectUri(): string
    {
        return `${GlobalEnvironment.APP_ENDPOINT}/auth/callback`;
    }

    // ----------------------- Private logic ------------------------
}
