import { BaseStore } from '../../../../../@Framework/Store/BaseStore';
import { Entity } from '../../../../../@Api/Model/Implementation/Entity';
import { action, computed, observable } from 'mobx';
import { injectWithQualifier } from '../../../../../@Util/DependencyInjection/index';
import { EntityTypeStore } from '../../Type/EntityTypeStore';
import { SidebarStore } from '../../../../Generic/Sidebar/SidebarStore';
import { DataObjectStore } from '../../../DataObject/DataObjectStore';
import { CurrentUserStore } from '../../../User/CurrentUserStore';
import { StoreState } from '../../../../../@Framework/Store/@Model/StoreState';
import { User } from '../../../../../@Api/Model/Implementation/User';
import { ViewComponent } from '../../../../Generic/ViewStack/Model/ViewComponent';
import { ButtonStore } from '../../../../Generic/Button/ButtonStore';
import { SidebarPanelStore } from '../../../../Generic/SidebarPanel/SidebarPanelStore';
import { RelatedEntityViewerStore } from './Related/Internal/RelatedEntityViewerStore';
import { TabBarStore } from '../../../../Generic/Tab/TabBarStore';
import { TextStore } from '../../../../Generic/Text/TextStore';
import { EntityRelationship } from '../../../../../@Api/Model/Implementation/EntityRelationship';
import { DrawerStore } from '../../../../Generic/Drawer/DrawerStore';
import { createTransactionalModel, TransactionalModel } from '../../../../../@Util/TransactionalModelV2/index';
import { RouterStore } from '../../../../../@Service/Router/RouterStore';
import { LocalizationStore } from '../../../../../@Service/Localization/LocalizationStore';
import { EntitySystemFieldsStore } from '../../SystemFields/EntitySystemFieldsStore';
import { EntityReadCountButtonStore } from '../../Button/EntityReadCountButtonStore';
import { openEntity } from '../../@Util/openEntity';
import EntitySystemFields from '../../SystemFields/EntitySystemFields';
import { SidebarPanel } from '../../../../Generic/SidebarPanel/SidebarPanel';
import { MoreTextStore } from '../../../../Generic/Text/More/MoreTextStore';
import initializeEntityForViewer from '../../../../../@Api/Entity/initializeEntityForViewer';
import { EntityViewerCommand } from '../EntityViewer';
import deleteEntity from '../../../../../@Api/Entity/deleteEntity';
import { saveReadEventForEntity } from '../Api/saveReadEventForEntity';
import localizeText from '../../../../../@Api/Localization/localizeText';
import sendAnalyticsLogging, { EventTypes } from '../../../../../@Util/Analytics/sendAnalyticsLogging';

export class EntityViewerStore extends BaseStore
{
    // ------------------------ Dependencies ------------------------

    @injectWithQualifier('EntityTypeStore') entityTypeStore: EntityTypeStore;
    @injectWithQualifier('DataObjectStore') dataObjectStore: DataObjectStore;
    @injectWithQualifier('SidebarStore') sidebarStore: SidebarStore;
    @injectWithQualifier('CurrentUserStore') currentUserStore: CurrentUserStore;
    @injectWithQualifier('LeftDrawerStore') drawerStore: DrawerStore;
    @injectWithQualifier('RouterStore') routerStore: RouterStore;
    @injectWithQualifier('LocalizationStore') localizationStore: LocalizationStore;

    // ------------------------- Properties -------------------------

    @observable entity: TransactionalModel<Entity>;
    @observable isCompact: boolean;
    @observable onClose: (entity: Entity) => void;
    @observable isInSidebar: boolean;
    @observable tab: number = 0;
    @observable bodyComponent: ViewComponent<any>;
    @observable doShowBodyComponentInCard: boolean;
    @observable isSubscribed: boolean = false;
    @observable allowEditingFavoriteFields: boolean = false;
    @observable relatedEntityViewerStore: RelatedEntityViewerStore;
    @observable isEntityBulkDialogOpen: boolean = false;
    @observable isInitializedOnViewer: boolean = false;
    @observable areFieldsExpanded: boolean;
    @observable command: EntityViewerCommand;

    // ------------------------ Constructor -------------------------

    constructor(entity: Entity,
                isCompact: boolean = false,
                onClose?: (entity: Entity) => void,
                isInSidebar: boolean = false,
                command?: EntityViewerCommand)
    {
        super();

        this.isCompact = isCompact;
        this.onClose = onClose;
        this.isInSidebar = isInSidebar;
        this.entity = entity;
        this.command = command;

        sendAnalyticsLogging(EventTypes.ConsultRecord, this.entity);
    }

    // ----------------------- Initialization -----------------------

    initialize(): Promise<any>
    {
        this.entity = createTransactionalModel(this.entity);

        if (this.isInitializedOnViewer)
        {
            return Promise.resolve();
        }
        else
        {
            return this.fetchEntity(this.entity)
                .then(entity => this.initializeWithEntity(entity))
                .then(entity =>
                {
                    this.setInitializedOnViewer();
                    return Promise.resolve();
                });
        }
    }

    // -------------------------- Computed --------------------------

    @computed
    get isAssignable(): boolean
    {
        return this.entity.entityType.bespoke.isAssignable(this.entity);
    }

    @computed
    get inactiveName(): string
    {
        if (this.entity.entityType.isA(this.entityTypeStore.bespoke.types.Relation.Organization.Type))
        {
            return this.localizationStore.translate('EntityViewer.Label.Liquidated'); // Liquidated
        }
        else if (this.entity.entityType.isA(this.entityTypeStore.bespoke.types.Relation.Person.Type))
        {
            return this.localizationStore.translate('EntityViewer.Label.Deceased'); // Deceased
        }
        else
        {
            return null;
        }
    }

    @computed
    get isConfigurationType(): boolean
    {
        return this.entity.entityType.bespoke.isConfigurationType();
    }

    @computed
    get extendedEntity(): Entity
    {
        return this.entity.getRelatedEntityByDefinition(
            true,
            this.entityTypeStore.bespoke.types.Entity.RelationshipDefinition.Overrides);
    }

    @computed
    get showNewTimeline(): boolean
    {
        return false; // !this.isConfigurationType;
    }

    // --------------------------- Stores ---------------------------

    @computed
    get entityBulkEditorButtonStore(): ButtonStore
    {
        if (this.entityTypeStore.getTypeByEntityId(this.entity.id) === this.entityTypeStore.bespoke.types.SalesTarget.Type)
        {
            return new ButtonStore({
                label: localizeText('SalesTargetGenerate', 'Verkoopdoelstellingen genereren'),
                color: 'primary',
                isRaised: true,
                onClick:
                    () => this.showBulkEditor()
            });
        }
        else
        {
            return undefined;
        }
    }

    @computed
    get readCountButtonStore(): EntityReadCountButtonStore
    {
        if (!this.isCompact)
        {
            const buttonStore = new EntityReadCountButtonStore({
                entity: this.entity
            });

            buttonStore.initialize();

            return buttonStore;
        }
        else
        {
            return undefined;
        }
    }

    @computed
    get backButtonStore(): ButtonStore
    {
        if (this.onClose)
        {
            return new ButtonStore(
            {
                icon: 'close',
                onClick: () => this.onClose(this.entity)
            });
        }
        else
        {
            return undefined;
        }
    }

    @computed
    get sidebarExpandButtonStore(): ButtonStore
    {
        if (this.isInSidebar)
        {
            return new ButtonStore({
                icon: 'arrow_back',
                onClick:
                    () => openEntity(this.entity)
            });
        }

        return undefined;
    }

    @computed
    get entitySystemFieldsStore(): EntitySystemFieldsStore
    {
        return new EntitySystemFieldsStore({
            entity: this.entity
        });
    }

    @computed
    get detailsTitleTextStore(): TextStore
    {
        const isActive =
            this.entity.getObjectValueByField(this.entityTypeStore.bespoke.types.Entity.Field.IsActive) === true
                ||
            this.entity.getObjectValueByField(this.entityTypeStore.bespoke.types.Entity.Field.IsActive) === undefined;

        if (!isActive)
        {
            return new TextStore(
                {
                    variant: 'underline',
                    color: 'secondary',
                    weight: 'semibold',
                    label:
                        () =>
                            this.inactiveName
                });
        }
        else
        {
            return undefined;
        }
    }

    @computed
    get detailsTextStore(): TextStore
    {
        return new TextStore({
            variant: 'overline',
            label: localizeText('Generic.Details', 'Details')
        });
    }

    @computed
    get dataTextStore(): TextStore
    {
        return new TextStore(
        {
            variant: 'body2',
            color: 'secondary',
            weight: 'semibold',
            label:
                () => localizeText('Data', 'Data')
        });
    }

    @computed
    get systemFieldsButtonStore(): ButtonStore
    {
        if (this.entitySystemFieldsStore)
        {
            return new ButtonStore({
                tooltip:
                    () => localizeText('System', 'Systeem'),
                onClick:
                    () => this.drawerStore.pushView(
                        new ViewComponent(
                            SidebarPanel,
                            new SidebarPanelStore({
                                title: localizeText('SystemFields', 'Systeem velden'),
                                onClose:
                                    () => this.drawerStore.popView(),
                                viewComponent:
                                    new ViewComponent(
                                        EntitySystemFields,
                                        this.entitySystemFieldsStore)
                            })
                        )
                    ),
            });
        }
        else
        {
            return undefined;
        }
    }

    @computed
    get tabBarStore()
    {
        return undefined;
    }

    @computed
    get compactViewTabBarStore()
    {
        return new TabBarStore(
        {
            tabs:
            [
                {
                    id: 'details',
                    label: this.localizationStore.translate('Generic.Details'), // details
                    onSelect: () =>
                    {
                        this.tab = 0;
                    }
                },
                {
                    id: 'timeline',
                    label: this.localizationStore.translate('Generic.Timeline'), // Timeline
                    onSelect: () =>
                    {
                        this.tab = 1;
                    }
                }
            ]
        });
    }

    @computed
    get moreFieldsTextStore(): MoreTextStore
    {
        return new MoreTextStore({
            isExpanded: () => this.areFieldsExpanded,
            toggleExpansion: () => this.toggleFields()
        });
    }

    @computed
    get firstParentRelationship(): Entity
    {
        if (this.entity.entityType.isA(this.entityTypeStore.bespoke.types.Relation.Person.Type))
        {
            return this.entity.getRelatedEntityByDefinition(true, this.entityTypeStore.bespoke.types.Relationship.Person.RelationshipDefinition.Person);
        }
        else if (this.entity.entityType.isA(this.entityTypeStore.bespoke.types.Relation.Organization.Type))
        {
            return this.entity.getRelatedEntityByDefinition(true, this.entityTypeStore.bespoke.types.Relationship.Organization.RelationshipDefinition.Organization);
        }
        else
        {
            return undefined;
        }
    }

    @computed
    get firstParentRelationshipRelation(): Entity
    {
        if (this.firstParentRelationship)
        {
            return this.firstParentRelationship.getRelatedEntityByDefinition(true, this.entityTypeStore.bespoke.types.Relation.RelationshipDefinition.Relationships);
        }
        else
        {
            return undefined;
        }
    }
    // @computed
    // get parentEntityTypeSwitcherButtonStore(): EntityTypeSwitcherButtonStore
    // {
    //     return new EntityTypeSwitcherButtonStore({
    //         entity: this.firstParentRelationship,
    //         renderAsChip: true,
    //         label: this.firstParentRelationship.entityType.nameSingular,
    //         color: 'primary',
    //         size: 'small',
    //         iconColor: 'primary'
    //     });
    // }


    // -------------------------- Actions ---------------------------

    @action
    setInitializedOnViewer()
    {
        this.isInitializedOnViewer = true;
    }

    @action.bound
    showBulkEditor()
    {
       this.isEntityBulkDialogOpen = true;
    }

    @action.bound
    hideBulkEditor()
    {
        this.isEntityBulkDialogOpen = false;
    }

    @action.bound
    toggleDetailInEditMode()
    {
        this.allowEditingFavoriteFields = !this.allowEditingFavoriteFields;
    }

    @action.bound
    setIsCompact(isCompact: boolean)
    {
        this.isCompact = isCompact;
    }

    @action.bound
    initializeWithEntity(entity: TransactionalModel<Entity>): Promise<Entity>
    {
        entity.setManaged(true);
        entity.setDoAutoCommit(true);

        this.entity = entity;

        this.isSubscribed = true;

        this.relatedEntityViewerStore =
            new RelatedEntityViewerStore(
                this,
                () => Promise.resolve());

        // if (this.firstParentRelationship)
        // {
        //     this.entitySummaryStore =
        //         new EntitySummaryStore({
        //             entity: this.firstParentRelationship
        //         });
        // }

        // Save read event
        if (!entity.isNew() && !this.extendedEntity)
        {
            // Do not return this in the promise, because we do not wait for it to finish
            saveReadEventForEntity(
                entity,
                this.currentUserStore.employeeEntity);
        }

        // In case of a non-compact entity viewer, it is possible we can open an entity in the sidebar
        if (!this.isCompact)
        {
            // const entityToOpenInSidebar = entity.entityType.bespoke.getEntityToOpenInSidebar(entity);
            //
            // if (entityToOpenInSidebar)
            // {
            //     this.routerStore.currentPageStore.pushRightSidebarPanel(
            //         new SidebarPanelStore({
            //             isLeft: false,
            //             backgroundColor: 'whitesmoke',
            //             bodyPadding: 10,
            //             width: 500,
            //             viewComponent:
            //                 new ViewComponent(
            //                     EntityViewer,
            //                     new EntityViewerStore(
            //                         entityToOpenInSidebar,
            //                         true,
            //                         () =>
            //                             this.routerStore.currentPageStore.clearRightSidebar(true),
            //                         true))
            //         }));
            // }
        }

        return Promise.resolve(entity);
    }

    @action.bound
    setTab(event: any, tab: number)
    {
        this.tab = tab;
    }

    @action.bound
    toggleActive()
    {
        this.setState(StoreState.Loading);

        this.entity.setValueByField(
            this.entityTypeStore.bespoke.types.Entity.Field.IsActive,
            !this.entity.getObjectValueByField(this.entityTypeStore.bespoke.types.Entity.Field.IsActive));

        // this.entity.isActive = !this.entity.isActive;

        this.entity.checkAndDoCommit()
            .then(entity =>
            {
                this.setState(StoreState.Loaded);
            });
    }

    @action.bound
    deleteEntity(entity: Entity = this.entity)
    {
        this.setState(StoreState.Loading);

        return deleteEntity(entity)
            .then(() =>
            {
                if (this.onClose)
                {
                    this.onClose(entity);
                }
                else
                {
                    this.routerStore.goBack();
                }
            });
    }

    @action.bound
    setUser(user: User)
    {
        this.entity.user = user;
    }

    @action.bound
    openBodyComponent(component: ViewComponent<any>,
                      showInCard: boolean = false)
    {
        this.bodyComponent = component;
        this.doShowBodyComponentInCard = showInCard;
    }

    @action.bound
    closeBodyComponent()
    {
        this.bodyComponent = null;
    }

    @action.bound
    openEntityViewerRight(entity: Entity,
                          fromEntity: Entity = this.entity): Promise<any>
    {
        return openEntity(
            entity,
            undefined,
            fromEntity);
    }

    @action.bound
    toggleEntityActive()
    {
        this.entity.setValueByField(
            this.entityTypeStore.bespoke.types.Entity.Field.IsActive,
            !this.entity.getObjectValueByField(this.entityTypeStore.bespoke.types.Entity.Field.IsActive));
    }

    @action.bound
    toggleFields()
    {
        this.areFieldsExpanded = !this.areFieldsExpanded;
    }

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------

    fetchEntity(entity: TransactionalModel<Entity>,
                viaRelationship?: EntityRelationship): Promise<TransactionalModel<Entity>>
    {
        return initializeEntityForViewer(entity);
    }
}
