import React, { useCallback, useContext, useMemo } from 'react';
import { observer } from 'mobx-react';
import { default as ColumnModel } from '../../Model/Column';
import { DroppableProvided } from 'react-beautiful-dnd';
import ViewGroup from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import styles from './List.module.scss';
import Item from './Item/Item';
import Tooltip from '../../../../../../@Future/Component/Generic/Tooltip/Tooltip';
import Card from '../../../../../../@Future/Component/Generic/Card/Card';
import Centered from '../../../../../../@Future/Component/Generic/Centered/Centered';
import Icon from '../../../../../../@Future/Component/Generic/Icon/Icon';
import { primaryColor } from '../../../../../../@Resource/Theme/Theme';
import { runInAction } from 'mobx';
import useIsDragging from '../../../../DragAndDrop/Api/useIsDragging';
import { classNames } from '../../../../../../@Future/Util/Class/classNames';
import WidgetBrowser from '../../WidgetBrowser/WidgetBrowser';
import useIsMobile from '../../../../../../@Util/Responsiveness/useIsMobile';
import useSwitch from '../../../../../../@Util/Switch/useSwitch';
import Drawer from '../../../../../../@Future/Component/Generic/Drawer/Drawer';
import Widget from '../../Model/Widget';
import DashboardContext from '../../Context/DashboardContext';
import Section from '../../Model/Section';
import uuid from '../../../../../../@Util/Id/uuid';
import LocalizedText from '../../../../Localization/LocalizedText/LocalizedText';

export interface ListProps
{
    column: ColumnModel;
    provided: DroppableProvided;
    firstColumn?: boolean;
    lastColumn?: boolean;
    lastSection?: boolean;
    disabled?: boolean;
}

const List: React.FC<ListProps> =
    props =>
    {
        const dashboard = useContext(DashboardContext);

        const addCardStyles =
            useMemo(
                () =>
                    ({
                        root: styles.addCard
                    }),
                []);

        const isDragging = useIsDragging();
        const isMobile = useIsMobile();
        const [ isOpen, open, close ] = useSwitch(false);

        const onAdd =
            useCallback(
                (widget: Widget) =>
                {
                    runInAction(
                        () =>
                        {
                            if (widget.isFullWidth())
                            {
                                dashboard.sections.unshift(
                                    new Section(
                                        uuid(),
                                        [
                                            new ColumnModel(
                                                uuid(),
                                                [
                                                    widget
                                                ])
                                        ]));
                            }
                            else
                            {
                                props.column.widgets.push(widget);
                            }
                        });

                    close();
                },
                [
                    props.column,
                    dashboard,
                    close
                ]);

        const onDelete =
            useCallback(() =>
            {
                runInAction(
                    () =>
                    {
                        // remove empty sections
                        dashboard.sections = dashboard.sections
                            .filter(section => section.columns.reduce((count, column) => count + column.widgets.length, 0) !== 0);

                        // If no sections left, or only full width sections left then add a new empty section
                        const areAllSectionsFullWidth =
                            dashboard.sections
                                .every(
                                    section => 
                                        section.isFullWidth
                                );
                        
                        if (areAllSectionsFullWidth || dashboard.sections.length === 0)
                        {
                            // Everything removed, create initial section with 3 columns
                            dashboard.sections.push(
                                new Section(
                                    uuid(),
                                    [
                                        new ColumnModel(uuid(),[]),
                                        new ColumnModel(uuid(),[]),
                                        new ColumnModel(uuid(),[])
                                    ]
                                )
                            );
                        }
                    });

            },
                [
                    dashboard
                ]
            )

        return <ViewGroup
            orientation="vertical"
            spacing={0}
            className={styles.root}
        >
            <ViewGroupItem>
                <ViewGroup
                    orientation="vertical"
                    spacing={0}
                    className={styles.list}
                >
                    {
                        props.column.widgets.map(
                            (widget, idx) =>
                                <ViewGroupItem
                                    key={widget.id}
                                >
                                    <Item
                                        column={props.column}
                                        widget={widget}
                                        idx={idx}
                                        disabled={props.disabled}
                                        onDelete={onDelete}
                                        firstColumn={props.firstColumn}
                                        lastColumn={props.lastColumn}
                                    />
                                </ViewGroupItem>)
                    }
                </ViewGroup>
            </ViewGroupItem>
            {
                props.lastSection && (!isMobile || props.lastColumn) &&
                    <ViewGroupItem>
                        <div
                            className={
                                classNames(
                                    styles.add,
                                    isMobile && styles.mobile,
                                    isDragging && styles.dragging)
                            }
                            onClick={open}
                        >
                            <Tooltip
                                title={
                                    <LocalizedText
                                        code="Dashboard.AddWidget"
                                        value="Widget toevoegen"
                                    />
                                }
                                block
                            >
                                <Card
                                    inset
                                    classes={addCardStyles}
                                >
                                    <Centered
                                        horizontal
                                    >
                                        <Icon
                                            icon="add"
                                            color={primaryColor}
                                        />
                                    </Centered>
                                </Card>
                            </Tooltip>
                        </div>
                        {
                            isOpen &&
                                <Drawer
                                    title={
                                        <LocalizedText
                                            code="Dashboard.AddWidgetToDashboard"
                                            value="Toevoegen aan dashboard..."
                                        />
                                    }
                                    open={isOpen}
                                    onClose={close}
                                    anchor="left"
                                    width={750}
                                >
                                    <WidgetBrowser
                                        onAdd={onAdd}
                                        onClose={close}
                                    />
                                </Drawer>
                        }
                    </ViewGroupItem>
            }
            <ViewGroupItem
                ratio={1}
            >
                {props.provided.placeholder}
            </ViewGroupItem>
        </ViewGroup>;
    };

export default observer(List);
