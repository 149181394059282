import React, { useContext } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import CurrentUserContext from '../User/CurrentUserContext';
import useTypes from '../Entity/Type/Api/useTypes';
import ModuleManagerContent from './Content/Content';

export interface ModuleManagerProps
{
    open?: boolean;
    onClose?: () => void;
    showPrices?: boolean;
}

const ModuleManager: React.FC<ModuleManagerProps> =
    props =>
    {
        const currentUserStore = useContext(CurrentUserContext);
        const types = useTypes();
        const isInSetupMode =
            useComputed(
                () =>
                    props.open ||
                    currentUserStore.environmentEntity.getObjectValueByField(types.Relation.Organization.Environment.Field.IsInSetup) === true,
                [
                    props.open,
                    currentUserStore,
                    types
                ]);

        if (isInSetupMode)
        {
            return <ModuleManagerContent
                {...props}
            />;
        }
        else
        {
            return null;
        }
    };

export default observer(ModuleManager);
