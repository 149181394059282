import React, { useMemo, useState } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import { Entity } from '../../../../../../../@Api/Model/Implementation/Entity';
import CardInset from '../../../../../../../@Future/Component/Generic/Card/CardInset';
import useTypes from '../../../../Type/Api/useTypes';
import { DataObjectViewerStore } from '../../../../../DataObject/Viewer/DataObjectViewerStore';
import { DataObjectViewer } from '../../../../../DataObject/Viewer/DataObjectViewer';
import FileViewer from '../../../../../../Generic/FileViewer/FileViewer';
import useEntityValue from '../../../../../../../@Api/Entity/Hooks/useEntityValue';
import { FileValue } from '../../../../../DataObject/Type/File/FileValue';
import useApiClient from '../../../../../../../@Service/ApiClient/Hooks/useApiClient';
import PrimaryButton from '../../../../../../../@Future/Component/Generic/Button/Variant/PrimaryButton/PrimaryButton';
import LocalizedText from '../../../../../Localization/LocalizedText/LocalizedText';

export interface AttachmentProps
{
    entity: Entity;
}

const Attachment: React.FC<AttachmentProps> =
    props =>
    {
        const types = useTypes();
        const apiClient = useApiClient();
        const fileValue =
            useEntityValue<FileValue>(
                props.entity,
                types.Attachment.Field.File
            );
        const dataObjectViewerStore =
            useComputed(
                () =>
                    new DataObjectViewerStore(props.entity.getDataObjectValueByField(types.Attachment.Field.File)),
                [
                    props.entity,
                    types
                ]);

        const supportedPreviewOfficeMimeTypes = new Set<string>([
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            'application/msword'
        ]);

        const previewDocumentUrl =
            useMemo(
                () =>
                {
                    if (props.entity && apiClient)
                    {
                        return apiClient.url(`/entity/bespoke/attachment/${props.entity.id}/pdf`);
                    }
                    else
                    {
                        return undefined;
                    }
                },
                [
                    props.entity,
                    apiClient
                ]);

        const [showPreview, setShowPreview] = useState(false);

        const previewButton = useMemo(
            () =>
            {
                if (supportedPreviewOfficeMimeTypes.has(fileValue?.mime.toLowerCase()))
                {
                    return <FileViewer
                        url={previewDocumentUrl}
                        docxUrl={props.entity.entityTypeStore.getFileUrl(props.entity.getValueByField(types.Attachment.Field.File))}
                        type="pdf"
                    />
                }
            },
            [

            ]
        )

        if (fileValue?.mime === 'application/pdf')
        {
            return <FileViewer
                url={
                    props.entity.entityTypeStore.getFileUrl(
                        props.entity.getValueByField(types.Attachment.Field.File)
                    )
                }
                type="pdf"
            />;
        }
        else
        {
            return <CardInset>
                <DataObjectViewer
                    store={dataObjectViewerStore}
                />
                {
                    supportedPreviewOfficeMimeTypes.has(fileValue?.mime.toLowerCase()) &&
                    <PrimaryButton
                        onClick={() => setShowPreview(!showPreview)}
                        label={
                            <LocalizedText
                                code="Attachment.ShowPreview"
                                value="Voorbeeld tonen"
                            />
                    }
                    />
                }
                {
                    showPreview && previewButton
                }
            </CardInset>;
        }
    };

export default observer(Attachment);
